import React from "react";
import Modal from "react-modal";

const BarcodeModal = ({setIsOpen, modalIsOpen}) => {
  var subtitle;

  function afterOpenModal() {
    subtitle.style.backgroundColor = "#fff";
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Barcode Modal"
      >
        <img
        ref={(_subtitle) => (subtitle = _subtitle)}
        src={require("@assets/barcode.png")}
        style={customStyles.imageContent}
        alt="Barcode Helper"
      />
      </Modal>
    </div>
  );
};
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  },
  imageContent: {width: 250, height: 600, resize: 'contain'}
};
Modal.setAppElement("#root");
export default BarcodeModal;
