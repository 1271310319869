import { API } from 'aws-amplify';
import {getRegisterProductSubscriptionFreightOptions } from '../../../graphql/queries';

// todo: strongly type the input props

export const FetchSubscriptionFreightOptions= async (productCode: any, country: any, partner: any, kitTypeId: any, timeZone: any)=>{

    // return await API.graphql(graphqlOperation(queries.getRegisterProductSubscriptionFreightOptions, {
    //   productCode : productCode,
    //   country: countryCode,
    //   partner: partner,
    //   kitTypeId: kitTypeId
    // }));

    let data:any = {country: country};
    if(productCode){
      data.productCode = productCode;
    }
    if(kitTypeId){
      data.kitTypeId = kitTypeId;
    }
    if(timeZone){
      data.timezone = timeZone;
    }
    data.partner =  partner? partner : 'myDNA';
  
    return await API.graphql({ query: getRegisterProductSubscriptionFreightOptions, variables: {...data}});
  }