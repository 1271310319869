import {notify} from "@modules/Shared/Notifier";
import {NotificationSeverity} from "@modules/Shared/Constants";

export const RegisterActions = {
    SIGN_UP: 'signUp',
    SIGNED_UP: 'signedUp',
    SIGN_UP_FAILURE: 'signUp_failure',
    CONFIRM_SIGN_UP: 'confirmSignUp',
    SIGNED_IN: 'signedIn',
    CONFIRM_SIGN_UP_FAILURE: 'confirmSignUp_failure',
    SIGN_OUT: 'signOut',
    SIGNED_OUT: 'signedOut',
    REQUIRE_NEW_PASSWORD:"requireNewPassword"
};

export const signUp = () => dispatch => {
    dispatch({
        type: RegisterActions.SIGN_UP,
        error: null,
        payload: {
            auth: {
                user: {},
                isAuthenticated: false
            }
        }
    })
};

export function signUpFailure(error) {
    return dispatch => {
        dispatch({
                type: RegisterActions.SIGN_UP_FAILURE,
                error: error,
                payload: {
                    auth: {
                        user: {},
                        isAuthenticated: false
                    }
                }
            }
        );
    }
}

export function confirmSignUp(message) {
    return dispatch => {
        dispatch({
                type: RegisterActions.CONFIRM_SIGN_UP,
                error: null,
                message: message,
                payload: {
                    auth: {
                        user: {},
                        isAuthenticated: false
                    }
                }
            }
        );
        notify(message, NotificationSeverity.SUCCESS);
    }
}

export function signedIn(user) {
    return dispatch => {
        dispatch({
                type: RegisterActions.SIGNED_IN,
                error: null,
                payload: {
                    auth: {
                        user: user,
                        isAuthenticated: true,
                        marketingOptIn: user.marketingOptIn
                    }
                }
            }
        )
    }
}

export function confirmSignUpFailure(error) {
    return dispatch => {
        dispatch({
                type: RegisterActions.CONFIRM_SIGN_UP_FAILURE,
                error: error,
                payload: {
                    auth: {
                        user: {},
                        isAuthenticated: false
                    }
                }
            }
        );
    }
}

export function signOut() {
    return dispatch => {
        dispatch({
                type: RegisterActions.SIGN_OUT,
                error: null,
                payload: {
                    auth: {
                        user: {},
                        isAuthenticated: false
                    }
                }
            }
        )
    }
}
