import { ButtonType, MyDnaButtonTheme } from "./MyDnaButton";
import Button from "@material-ui/core/Button";
import styled, { css } from 'styled-components';

// Default theme to be used for buttons
// Another theme can be defined per project and passed to the buttons to override
const DefaultTheme: { [id in ButtonType]: MyDnaButtonTheme } = {
    'cta': {
        Color: "#FFFFFF",
        FontSize: "18px",
        BorderRadius: "5px",
        BackgroundColours: {
            DefaultColour: "#30B5E8",
            HoverColour: "#A6D3F0",
            ActiveColour: "#30B5E8",
            FocusColour: "#30B5E8",
            DisabledColour: "#C5C5C5"
        },
        BorderColours: {
            DefaultColour: "#30B5E8",
            HoverColour: "#A6D3F0",
            ActiveColour: "#30B5E8",
            FocusColour: "#30B5E8",
            DisabledColour: "#C5C5C5"
        },
        TextColours: {
            DefaultColour: "#FFFFFF",
            HoverColour: "#FFFFFF",
            ActiveColour: "#FFFFFF",
            FocusColour: "#FFFFFF",
            DisabledColour: "#FFFFFF"
        },
        FontFamily: "'NowBlack', sans-serif"
    },
    'primary': {
        Color: "#FFFFFF",
        FontSize: "16px",
        BorderRadius: "5px",
        BackgroundColours: {
            DefaultColour: "#30B5E8",
            HoverColour: "#A6D3F0",
            ActiveColour: "#30B5E8",
            FocusColour: "#30B5E8",
            DisabledColour: "#C5C5C5"
        },
        BorderColours: {
            DefaultColour: "#30B5E8",
            HoverColour: "#A6D3F0",
            ActiveColour: "#30B5E8",
            FocusColour: "#30B5E8",
            DisabledColour: "#C5C5C5"
        },
        TextColours: {
            DefaultColour: "#FFFFFF",
            HoverColour: "#FFFFFF",
            ActiveColour: "#FFFFFF",
            FocusColour: "#FFFFFF",
            DisabledColour: "#FFFFFF"
        },
        FontFamily: "'NowBlack', sans-serif"
    },
    'ghost': {
        Color: "##FFFFFF",
        FontSize: "16px",
        BorderRadius: "5px",
        BackgroundColours: {
            DefaultColour: "#FFFFFF",
            HoverColour: "#FFFFFF",
            ActiveColour: "#FFFFFF",
            FocusColour: "#FFFFFF",
            DisabledColour: "#FFFFFF"
        },
        BorderColours: {
            DefaultColour: "#30B5E8",
            HoverColour: "#A6D3F0",
            ActiveColour: "#30B5E8",
            FocusColour: "#30B5E8",
            DisabledColour: "#C5C5C5"
        },
        TextColours: {
            DefaultColour: "#30B5E8",
            HoverColour: "#A6D3F0",
            ActiveColour: "#30B5E8",
            FocusColour: "#30B5E8",
            DisabledColour: "#C5C5C5"
        },
        FontFamily: "'NowBlack', sans-serif"
    },
    'secondary': {
        Color: "##FFFFFF",
        FontSize: "16px",
        BorderRadius: "5px",
        BackgroundColours: {
            DefaultColour: "#FFFFFF",
            HoverColour: "#FFFFFF",
            ActiveColour: "#FFFFFF",
            FocusColour: "#FFFFFF",
            DisabledColour: "#FFFFFF"
        },
        BorderColours: {
            DefaultColour: "#777777",
            HoverColour: "#A6D3F0",
            ActiveColour: "#30B5E8",
            FocusColour: "#30B5E8",
            DisabledColour: "#C5C5C5"
        },
        TextColours: {
            DefaultColour: "#777777",
            HoverColour: "#A6D3F0",
            ActiveColour: "#30B5E8",
            FocusColour: "#30B5E8",
            DisabledColour: "#C5C5C5"
        },
        FontFamily: "'NowBlack', sans-serif"
    }
}

export default DefaultTheme;

type ButtonStyleProps = {
    isdisabled?: boolean,
    buttontheme: MyDnaButtonTheme,
    buttonheight?: string;
} 

export const StyledButton = styled(Button)<{ props: ButtonStyleProps }>`
    background-color: ${({ props }) => !props.isdisabled ? props.buttontheme.BackgroundColours.DefaultColour : props.buttontheme.BackgroundColours.DisabledColour};
    height: 100%;
    width: 100%;
`;

export const StyledButtonWrapper = styled.div<{ props: ButtonStyleProps }>`
    background-color: ${({ props }) => props.buttontheme.BackgroundColours.DefaultColour};
    border-radius: ${({ props }) => props.buttontheme.BorderRadius};
    color: ${({ props }) => props.buttontheme.TextColours.DefaultColour};

    height: ${({ props }) => props.buttonheight};
    max-height: 4.313rem;
    transition: background-color 0.2s ease;

    ${({ props}) => !props.isdisabled ? css`
        &:hover {
            color: ${props.buttontheme.TextColours.HoverColour};
            background-color: ${props.buttontheme.BackgroundColours.HoverColour};
            border-color: ${props.buttontheme.BorderColours.HoverColour};
        }

        &:active {
            color: ${props.buttontheme.TextColours.ActiveColour};
            background-color: ${props.buttontheme.BackgroundColours.ActiveColour};
            border-color: ${props.buttontheme.BorderColours.ActiveColour};
        }

    ` : css`
        color: ${props.buttontheme.TextColours.DisabledColour};
        background-color: ${props.buttontheme.BackgroundColours.DisabledColour};
        border-color: ${props.buttontheme.BorderColours.DisabledColour};
    `};
`;

export const StyledTextWrapper = styled.span<{ props: ButtonStyleProps }>`
    font-size: ${({ props }) => props.buttontheme.FontSize};
    text-transform: ${({ props }) => props.buttontheme.TextTransform};
    font-family: ${({ props }) => props.buttontheme.FontFamily};
`;
