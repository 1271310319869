import React from "react";
import Checkbox from "@material-ui/core/Checkbox";

export type CheckboxInputProps = {
  setFieldValue: (field: string, value: any) => void;
  name: string;
  onChange: (event: any) => void;
  value: boolean;
}

const CheckboxInput = (props: CheckboxInputProps) => {

  const setValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setFieldValue && props.setFieldValue(props.name, event.target.checked);
    props.onChange(event)
  }

  return (
    <Checkbox
      onChange={event => {setValue(event)}}
      name={props.name}
      checked={props.value}
    />
  );
}

export default CheckboxInput;
