import styled from 'styled-components';
import MyDnaButton from '@modules/MyDnaButton';
import { MyDnaTheme } from '@themes/Styles/Default';

export const ComponentButtonsWrapper = styled.div`
  display: inline-block;
  margin: auto auto 1.5rem;

  width: 100%;
  height: 100%;

  max-width: 21rem;
`;

export const StyledButton = styled(MyDnaButton)`
  min-width: 10rem;
  width: 21rem;
  margin: 1rem;
`;

export const StyledSubmitButton = styled(StyledButton)`
  border-radius: 6px;
`;

export const StyledBackButton = styled(StyledButton)``;

export const HorizontallyAlignedWrapper = styled.div`
  display: flex;

  justify-content: center;
  align-items: center; 
`;

export const SubmitButtonWrapper = styled.div`
  padding-bottom: 1rem;
`;

export const ButtonStyledText = styled.span`
  font-size: 18px;
  text-transform: uppercase;
  font-family: 'NowRegular', sans-serif;
  letter-spacing: 1.8px;
  font-weight: medium;
`;

export const BlueColour = styled.div`
  color: ${MyDnaTheme.Colours.Primary };
`;

export const IconCentreWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;