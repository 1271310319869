import LocalizedStrings from 'react-localization';

export const messages = new LocalizedStrings({
    en: {
        passwordDoesNotMatch: "Password doesn't match.",
        pleaseCheckYourEmail: "Please check your email.",
        confirmationSuccessful: "Confirmation was successful, You will be redirected soon.",
        codeResent: "Code resent. Please check your email.",
        invalidCardNumber:"Card Number Invalid, Please check and try again.",
        userExistsErrorMessage: "An account with this email already exists. Please try logging in. Alternatively, reset your password.",
        invalidPassword: "Password should contain a minimum of 8 characters, including upper case and lower case letters.",
        userNotFound:"Username not found.",
        CodeMismatchException:"Invalid verification code provided, please try again.",
        signUpFailed:"Sign up failed, please try again.",
        acceptTermsandConditions:"Please accept the Terms and Conditions.",
        organisationSelectionErrorMessage:"Please select a store where you purchase your kit.",
        privacyPolicy: [
            "You have read and agree to myDNA's",
            "Privacy Policy"
        ],
        privacyPolicyError: "Please accept the Privacy Policy.",
        marketingOptIn: "You'd like to receive personalized updates, offers and news from myDNA. You can manage these preferences at any time.",
        underAgeUser:'myDNA is only available to people 18 and over.',
        InvalidParameterException:'Cannot reset password for the user as there is no registered/verified email or cell number.',
        InactiveBarcode: "Unfortunately this activation code has already been subscribed. If this does not seem right, please contact us",
        barcodeSuccessMessage:`Congratulations! You have the <Product Name>.`,
        barcodeCollectionKit: "No worries. We’ll add a DNA Collection kit to your order",
        validCouponCode: [
            "Yay! ",
            " applied to your order"
        ],
        invalidCouponCode: "Invalid coupon code",
        confirmationPageText: {
            YourTotal: "Your total cost",
            FreeTrial: "Your free trial will begin once your DNA Insights are ready",
            FirstPayment: "First membership payment of",
            ConfirmationEmail: "Confirmation also sent to",
            Goals: "We can't wait to help you {} and {}!",
            AppCta: "While we wait for your DNA sample there's already a tonne of content waiting for you in the myDNA Unlocked App.",
            Slogan: "The longer you're a member, the more you'll learn about yourself."
        },
        enterCouponCode: "Enter coupon code",
        insights: {
            title: "What’s included",
            freeMembershipText: "-DAY FREE PREMIUM MEMBERSHIP",
            includes: "INCLUDES ",
            vipTrailPeriod: "INCLUDES FREE PREMIUM MEMBERSHIP"
        }
    }
});
