import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CircularProgress from '@material-ui/core/CircularProgress';
import {Auth} from "aws-amplify";
import {RootPage, APPCONSTANTS} from "@modules/Shared/Constants";
import { 
  ComponentButtonsWrapper, 
  StyledSubmitButton, 
  StyledBackButton, 
  HorizontallyAlignedWrapper, 
  SubmitButtonWrapper, 
  ButtonStyledText, 
  BlueColour, 
  IconCentreWrapper 
} from "./styles";
import {myDNARouter} from '@modules/utils/myDNARouter';
import { useSelector } from "react-redux";

export type ComponentButtonsProps = {
  isSubmitting?: boolean,
  disableNext?: boolean,
  submitText?: string,
  singleButton: boolean,
  disableArrows?: boolean,
  dispatch: (props?: any) => any,
  backOnClick?: (props?: any) => any,
  previousPage: any,
  backText?: string,
  currentPage: string,
  barcode?: any
}

const ComponentButtons = (props: ComponentButtonsProps) => {
  
  const handleBackClick = () => {
    if(props.previousPage === RootPage){
      Auth.signOut()
        .then(() => {
          myDNARouter(props, APPCONSTANTS.PREVIOUS_PAGE, props.currentPage);
        })
    }else {
      props.backOnClick? props.backOnClick() :  myDNARouter(props, APPCONSTANTS.PREVIOUS_PAGE, props.currentPage);
    }
  }

  return (
    <React.Fragment>
        <ComponentButtonsWrapper>
          <SubmitButtonWrapper>
            <StyledSubmitButton
              isdisabled={props.isSubmitting || props.disableNext}
              type="submit"
              buttonType={'cta'}
              buttonVariant={'thick'}
            >
              {props.isSubmitting === true ? <CircularProgress size={25} color="secondary" /> : 
              <ButtonStyledText>
                {props.submitText ?? 'NEXT'}
              </ButtonStyledText>
              }
            </StyledSubmitButton>
          </SubmitButtonWrapper>
          {!props.singleButton && 
            <StyledBackButton
              isdisabled={props.isSubmitting}
              disableElevation
              onClick={() => handleBackClick()}
              buttonType={'secondary'}
              buttonVariant={'thick'}
            >
              <HorizontallyAlignedWrapper>
                {(props.disableArrows) ? '':
                <BlueColour>
                  <IconCentreWrapper>
                    <ArrowBackIosIcon fontSize={"small"} />
                  </IconCentreWrapper>
                </BlueColour>
                }
                  <BlueColour>
                    <ButtonStyledText>
                        { props.backText ?? 'BACK'}
                    </ButtonStyledText>
                  </BlueColour>
                </HorizontallyAlignedWrapper>
            </StyledBackButton>
          }
      </ComponentButtonsWrapper>
    </React.Fragment>
  );
};

ComponentButtons.propTypes = {
  isSubmitting: PropTypes.bool,
};

export default ComponentButtons;
