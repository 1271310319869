import styled from 'styled-components';
import { MyDnaTheme } from '@themes/Styles/Default';

export const OrderTotalBlockWrapper = styled.div`
  margin: auto;
  width: 20rem;
  padding-bottom: 1rem;
`;

export const OrderTotalContainerWrapper = styled.div`
  border-top: 1px solid ${MyDnaTheme.Colours.Primary};
  border-bottom: 1px solid ${MyDnaTheme.Colours.Primary};

  width: 20rem;
  margin-top: 20px;
  padding-top: 10px;
`;

export const OrderTotalWrapper = styled.div`
  width: 20rem;
  margin: auto;
  height: 2rem;
`;

export const OrderTotalLeftFloatWrapper = styled.span`
  float: left;
`;

export const OrderTotalRightFloatWrapper = styled.span`
  float: right;
  padding-right: 5px;
  font-size: 17;
  font-weight: bolder;
`;

export const FormContentWrapper = styled.div`
  padding-bottom: 1rem;
`; 

export const ShippingContentWrapper = styled.div`
  margin: auto;
  width: 19rem;
`;

export const PrimaryText = styled.span`
  font-size: 14px;
`;

export const GrayText = styled.span`
  color: ${ MyDnaTheme.Colours.Input.Heavy };
`;

export const BoldPrimaryText = styled(PrimaryText)`
  font-weight: 900;
`;

export const ShippingCostEntry = styled.div`
  border: 1px solid ${MyDnaTheme.Colours.Base.LightGrey};
  border-radius: 5px;

  height: 3rem;
  justify-content: center;
  align-items: center;

  display: flex;
  flex-direction: row;
`;

export const ShippingCostTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 80%;
`;

export const ShippingCostValue = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 3rem;
`;

export const ShippingTitleWrapper = styled.div`
  padding-bottom: 0.5rem;
  text-align: left;
`;

export const TitleWrapper = styled.div`
  margin: auto;
  max-width: 800px;
`;