import React, { useEffect } from 'react';
import ComponentContainerHeader from "@modules/Header";
import { PrimaryTitles, SecondaryTitles } from "@modules/Shared/TitleTexts";
import RadioButtonsGroup from "@modules/Shared/RadioButtonGroup";
import { Formik, Form } from "formik";
import ComponentButtons from "@modules/ComponentButtons"
import MedicationsTable from "./MedicationsTable"
import MedicationAutoCompleteApp from "./MedicationAutoCompleteApp.js";
import { deleteItem, addItem } from "./MedicationServices";
import { push } from "connected-react-router";
import { useDispatch, useStore } from 'react-redux';
import { medicationDetailsCaptured } from "@base/Actions/CustomerDetailsActions";
import { SmokingOptions } from '@modules/Models/SmokingOptions';

import {
    HorizontallyPaddedDiv, 
    MedicationDetailsPageWrapper, 
    MedicationDetailsContentWrapper, 
    MedicationDetailsBlock, 
    ColouredHorizontalLine, 
    WidthMedsWrapper, 
    MedicationDetailsWrapper, 
    BottomPaddedDiv
} from './styles';

const MedicationDetails = (props) => {
    const { previousPage } = props;

    const dispatch = useDispatch();
    const store = useStore().getState();
    const medicationDetails = store?.customerDetails?.medicationDetails

    useEffect(() => {
        if (!store?.customerDetails?.goals) {
            dispatch(push('/'));
        }
    }, [])
    //const classes = useStyles();

    let initialValue = {
        isSmoker: medicationDetails?.isSmoker ?? 'nonsmoker',
    }

    const [selectedMedications, setSelectedMedications] = React.useState(medicationDetails?.medications ?? []);
    const [ isSmoker, setIsSmoker ] = React.useState(initialValue.isSmoker);

    

    return (
        <MedicationDetailsPageWrapper>
            <Formik
                validateOnChange={true}
                initialValues={initialValue}
                onSubmit={(data, { setSubmitting, setFieldError }) => {
                    dispatch(medicationDetailsCaptured({ isSmoker: isSmoker, medications: selectedMedications, props:props }))

                }}>
                {({ values, errors, isSubmitting, setFieldValue, setErrors, setFieldError, validateField }) => {
                
                const onIsSmokerChange = (event) => {
                    const value = event.target.value;
                    setIsSmoker(value);
                    setFieldValue('isSmoker', isSmoker);
                }
                
                return (
                    <Form id="medication_details">
                        <ComponentContainerHeader primaryTitle={PrimaryTitles.MedicationDetails} secondaryTitle={SecondaryTitles.MedicationDetails} />
                        <form>
                            <MedicationDetailsBlock>
                                <ColouredHorizontalLine />
                                <WidthMedsWrapper>
                                    <MedicationDetailsWrapper>Please tell us your current medications</MedicationDetailsWrapper>
                                    <MedicationAutoCompleteApp addSelectedMedications={(medication) => { setSelectedMedications([...addItem(medication, selectedMedications)]) }
                                    } />
                                </WidthMedsWrapper>
                                <HorizontallyPaddedDiv>
                                    {selectedMedications && selectedMedications?.length > 0 && 
                                    <MedicationsTable rows={selectedMedications} deleteItem={(row) => setSelectedMedications([...deleteItem(row, selectedMedications)])} />}
                                </HorizontallyPaddedDiv>
                                <ColouredHorizontalLine/>
                                <WidthMedsWrapper>
                                    <BottomPaddedDiv>
                                        <MedicationDetailsWrapper>
                                            If you need further clarification, please call our friendly team on +61 3 8582 0301.
                                        </MedicationDetailsWrapper>
                                    </BottomPaddedDiv>
                                    <MedicationDetailsContentWrapper>
                                        <RadioButtonsGroup
                                            label="Select your smoking status"
                                            name="isSmoker"
                                            options={SmokingOptions}
                                            value={isSmoker}
                                            onChange = {onIsSmokerChange}
                                        />
                                    </MedicationDetailsContentWrapper>
                                    <MedicationDetailsWrapper>
                                        Cigarette smoking can affect the way you metabolise certain medications.
                                    </MedicationDetailsWrapper>
                                </WidthMedsWrapper>
                                <ComponentButtons dispatch={dispatch} previousPage={previousPage} singleButton={true} currentPage="medicationDetails" />
                            </MedicationDetailsBlock>
                        </form>
                    </Form>
                )}}
            </Formik>
        </MedicationDetailsPageWrapper>
    )
}

export default MedicationDetails
