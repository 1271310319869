import styled from 'styled-components';
import { MyDnaTheme } from '@themes/Styles/Default';

export const SmallerText = styled.span`
  font-size: 14px;
`;

export const StyledHelpTextWrapper = styled(SmallerText)`
    margin: auto;
    width: 25em;
    color: grey;
`;

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;

    margin: auto;
    justify-content: center;
    align-items: center;

    padding-bottom: 1rem;
`;

export const ConfirmSignUpWrapper = styled.div`
  padding: 10px;
`;

export const CheckboxBlock = styled.div`
    display: flex;
    flex-direction: row;

    width: ${MyDnaTheme.ContentWidth};
`;

export const CheckboxCheckWrapper = styled.div`
    display: flex;
`;

export const CheckboxTextWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    font-size: 12px;
`;