export const AppLinkURLS=[
    { 
       Country: "AUSTRALIA" , 
       Partner: "MYDNA",
       Android_URL:"https://play.google.com/store/apps/details?id=life.mydna.mobile",
       IOS_URL:"https://apps.apple.com/au/app/mydna-unlocked/id1537350005"
    },
    { 
        Country: "UNITED STATES OF AMERICA" , 
        Partner: "ViaCord",
        Android_URL:"#", // ::TODO:: Link has TBD 
        IOS_URL:"https://apps.apple.com/us/app/mydna-pregnancy/id1600769438"
     },
    { 
        Country: "DEFAULT" , 
        Partner: "DEFAULT",
        Android_URL:"https://play.google.com/store/apps/details?id=life.mydna.mobile",
        IOS_URL:"https://apps.apple.com/au/app/mydna-unlocked/id1537350005"
    },
    { 
        Country: "AUSTRALIA" , 
        Partner: "DemoMegatronPartner",
        Android_URL:"https://play.google.com/store/apps/details?id=life.mydna.mobile",
        IOS_URL:"https://apps.apple.com/au/app/mydna-unlocked/id1537350005"
    }
]