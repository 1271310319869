import React from "react";
import TextField from "@material-ui/core/TextField";
import { useField } from "formik";
import { ThemeProvider } from '@material-ui/core/styles';
import {  useTheme } from '@material-ui/core/styles';


export default function PhoneNumberInput(props) {
  const [field, meta] = useField(props.name);
  const errorText = meta.error && meta.touched ? meta.error : "";

  var theme = useTheme();

  function handleChange(e, setFieldValue) {
    var phoneNumber=e.target.value ? e.target.value.trim() : e.target.value.trim()
    phoneNumber= phoneNumber.trim()
    setFieldValue(
      props.name,
      phoneNumber
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <TextField
        {...field}
        helperText={errorText}
        error={!!errorText}
        label={props.label}
        onChange={(ev) => handleChange(ev, props.setFieldValue)}
        inputProps={{ maxLength: props.maxLength || "14" }}
        className="input-control phone-number-input"
        variant="outlined"
        InputProps={{
          placeholder: props.placeholder,
          name: props.name,
        }}
      />
      </ThemeProvider>
  );
}
