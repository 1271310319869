
// todo: 
// - Split each function to a separate file 
// - Write a separate test file for each

export const verifyTermsAndConditions = (data) => {
  return data.termsAndConditions ? true : false;
}

export const verifySelectedOrganisation = (data) => {
  var orgName = data.selectedOrganisation?.name ?? "";
  return orgName !== "";
}
