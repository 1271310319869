import "date-fns";
import React, { ChangeEvent } from "react";
import { FieldHookConfig, useField } from "formik";
import { FormControl, InputLabel, MenuItem, FormHelperText } from "@material-ui/core";
import { MonthArray } from '@modules/Models/MonthArray';
import { GetDaysInMonth } from '@utils/GetDaysInMonth';
import { MyDnaDatePickerWrapper, LabelWrapper, SelectComponentWrapper, SelectWrapper, MonthInputLabelComponent, InputLabelText, MonthSelectComponent, ErrorHelperText, DaySelectComponent, YearSelectComponent } from "./styles";

export type MyDnaDatePickerProps = { 
  name: string | FieldHookConfig<any>; 
  defaultValue?: string; 
  onChange: (date: Date) => void;
  label?: string; 
}

export default function MyDnaDatePicker(props: MyDnaDatePickerProps) {
  
  const [field, meta] = useField(props.name);
  const metaError = meta.error != undefined && meta.touched;

  const [ day, setDay ] = React.useState<number | undefined>(undefined);
  const [ month, setMonth ] = React.useState<string | undefined>(undefined);
  const [ year, setYear ] = React.useState<number | undefined>(undefined);

  if (props.defaultValue && !day && !month && !year) {
    const defaultDate = new Date(props.defaultValue);
    setDay(defaultDate.getDate());
    setMonth(MonthArray[defaultDate.getMonth()]);
    setYear(defaultDate.getFullYear());
  }

  const onChange = (dayValue?: number, monthValue?: string, yearValue?: number) => {
    // If there's enough data to construct a date object, do so and run the callback
    if (dayValue && monthValue && yearValue) {
      const date = new Date(`${dayValue} ${monthValue} ${yearValue}`);
      props.onChange(date);
    }
  };

  const handleDayChange = (event: ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
    const dayValue = Number(event.target.value);
    setDay(dayValue);
    onChange(dayValue, month, year);
  };

  const handleMonthChange = (event: ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
    const monthValue = String(event.target.value);
    setMonth(monthValue);
    onChange(day, monthValue, year);
  };

  const handleYearChange = (event: ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
    const yearValue = Number(event.target.value);
    setYear(yearValue);
    onChange(day, month, yearValue);
  };

  const isTouched = () => {
    return day !== undefined || month !== undefined || year !== undefined;
  }

  const showError = (value?: string | number) => {
    return (isTouched() && value == undefined) || metaError;
  }

  const GenerateNumberSelectItems = (keyPrefix: string, start: number, end: number, reverse?: boolean) => {

    var returnArray = [];
    var keyString = "";

    for(var i = start; i <= end; i++) {
      keyString = keyPrefix + "-" + i;
      returnArray.push(
        <MenuItem key={keyString} value={i}>
          {i}
        </MenuItem>
      )
    }

    if (reverse) returnArray = returnArray.reverse();

    return returnArray;
  }

  type FormControlProps = {
    variant?: 'standard' | 'outlined' | 'filled';
  }

  const formControlProps: FormControlProps = {
    variant: 'outlined'
  }
  
  return (
    <MyDnaDatePickerWrapper>
      <LabelWrapper>{props.label}</LabelWrapper>
      <SelectComponentWrapper>
        <SelectWrapper>
          <FormControl {...formControlProps} error={showError(month)} >
            <MonthInputLabelComponent>
              <InputLabelText>
                  Month
              </InputLabelText>
            </MonthInputLabelComponent>
            <MonthSelectComponent 
              id="date-picker-month"
              label="Month"
              value={month !== undefined ? month : ''}
              onChange={handleMonthChange}
            >
              {MonthArray.map((month) => (
                <MenuItem key={month} value={month}>
                  {month}
                </MenuItem>)
              )}
            </MonthSelectComponent> 
            {showError(month) &&
            <ErrorHelperText>
              Required
            </ErrorHelperText>}
          </FormControl>
        </SelectWrapper>
        <SelectWrapper>
          <FormControl {...formControlProps} error={showError(day)} >
            <InputLabel>
              <InputLabelText>
                Day
              </InputLabelText>
            </InputLabel>
            <DaySelectComponent 
              id="date-picker-day"
              value={day !== undefined ? day : ''}
              label="Day"
              onChange={handleDayChange}
            >
              {GenerateNumberSelectItems("day", 1, GetDaysInMonth(month ?? "January", year ?? 2020))}
            </DaySelectComponent>
            {showError(day) &&
            <ErrorHelperText>
              Required
            </ErrorHelperText>}
          </FormControl>
        </SelectWrapper>
        <SelectWrapper>
          <FormControl {...formControlProps} error={showError(year)}>
            <InputLabel>
              <InputLabelText>
                Year
              </InputLabelText>
            </InputLabel>
            <YearSelectComponent 
              id="date-picker-year"
              label="Year"
              value={year !== undefined ? year : ''}
              onChange={handleYearChange}
            >
              {GenerateNumberSelectItems("year", 1900, new Date().getFullYear(), true)}
            </YearSelectComponent> 
            {showError(year) &&
            <ErrorHelperText>
              Required
            </ErrorHelperText>}
          </FormControl>
        </SelectWrapper>
      </SelectComponentWrapper>
    </MyDnaDatePickerWrapper>
    
  );
}