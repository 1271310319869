import React from 'react'
import ExpansionPanelComponent from "./ExpansionPanelComponent";
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Paper } from "@material-ui/core";
import { connect } from 'react-redux';
import { PromoCodeDetails } from '@modules/Models/PromoCodeDetails';
import { SubscriptionOption } from '@modules/Models/SubscriptionOption';
import { PlanDisplayText } from "../Shared/PlanDisplayText";

import { StrikethroughText, SubscriptionCostTextWrapper, RowWrapper, DaysFreeText, MonthsFreeText } from "./styles"

type ExpansionPanelPaymentProps = {
    promoCodeDetails: PromoCodeDetails; 
    subscriptionOption: SubscriptionOption; 
    partner?: any; 
    deliveryOption: { 
        initialChargeAmount: string | number | boolean | {} | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactNodeArray | React.ReactPortal | null | undefined; 
    };
    disableSubscriptionDetails: boolean;
    displayName: string;
}

const ExpansionPanelPayment = (props: ExpansionPanelPaymentProps) => {
    
    return (
        <ExpansionPanelPaymentComponent 
            promoCodeDetails={props.promoCodeDetails} 
            subscriptionOption={props.subscriptionOption} 
            partner={props.partner} 
            deliveryOption={props.deliveryOption}
            disableSubscriptionDetails={props.disableSubscriptionDetails} 
            displayName={props.displayName}
        />);
}


export const ExpansionPanelPaymentComponent = (props: ExpansionPanelPaymentProps) => {

    const promoCodeDetails = props.promoCodeDetails;
    const subscriptionPromoCode = promoCodeDetails?.products?.find(x => x.productCode == props.subscriptionOption?.id);
    const labAnalysisPromoCode = promoCodeDetails?.products?.find(x => x.productCode == props.subscriptionOption?.collectionKitProductCode);
    const currency = props.subscriptionOption.currency;
    // Promo codes will never reduce the price of shipping

    const renderSubscriptionCost = () => {
        
        if (subscriptionPromoCode !== undefined && (subscriptionPromoCode?.amountOff !== null || subscriptionPromoCode?.percentOff !== null) && subscriptionPromoCode?.amount != 0) {
            return (
                <>
                    <StrikethroughText>
                        {currency + " " + `${subscriptionPromoCode?.originalAmount}`}
                    </StrikethroughText>
                    <strong>
                        {`$${subscriptionPromoCode?.amount}`}
                    </strong>
                </>
            );
        }

        return `$${props.subscriptionOption?.subscriptionAmountPerPeriod ?? 0}`
        
    };
    const renderDaysFree = () => {
        if (subscriptionPromoCode?.couponDurationInMonths != undefined && subscriptionPromoCode?.couponDurationInMonths > 0) {
            return (
                <>
                    <strong>
                        {`${props.subscriptionOption?.trialPeriodDays} Day Free Trial`}
                    </strong>
                </>
            );
            
        } else {
            return `${props.subscriptionOption?.trialPeriodDays} Day Free Trial`;
        }
    }

    // To differentiate whether it is a monthly subscription or more than monthly
    const isMonthlySubscription = (subscriptionData:SubscriptionOption) => (
        subscriptionData.subscriptionInterval === 1
    )

    const renderLabAnalysisCost = () => {
        
        const costReduced = labAnalysisPromoCode !== undefined && (labAnalysisPromoCode?.amountOff !== null || labAnalysisPromoCode?.percentOff !== null);

        if (costReduced) {
            return (
                <SubscriptionCostTextWrapper isVisible={costReduced}>
                    <RowWrapper>
                        <StrikethroughText>
                            {`$${labAnalysisPromoCode?.originalAmount}`}
                        </StrikethroughText>
                        <strong>
                            {`$${labAnalysisPromoCode?.amount}`}
                        </strong>
                    </RowWrapper>
                </SubscriptionCostTextWrapper>
            );
        }

        return (
            <SubscriptionCostTextWrapper isVisible={!costReduced}>
                <strong>
                    ${props.subscriptionOption?.dnaCollectionCharges}
                </strong>
            </SubscriptionCostTextWrapper>
        );
        
    };
    
    return (
        <ExpansionPanelComponent
            summary = {
                <React.Fragment>
                    <div className="MuiFormControl-root">
                        <div className="mydna-expansion-panel-summary">
                            <div className="mydna-expansion-panel-summary-title">
                            <label className="address-search-label body-content">
                                Order Summary
                            </label>
                            </div>
                            <div className="mydna-expansion-panel-summary-title-icon">
                            <ExpandMoreIcon />
                            </div>
                        </div>
                        </div>
                </React.Fragment>
            }
            content={
                <React.Fragment>


                
                    {!props.disableSubscriptionDetails &&
                     <ExpansionPanelDetails>
                        <div className={"payment-expansion-panel"}>
                            <Paper elevation={1} className={'payment-expansion-panel-paper'}>
                                <div className="MuiFormControl-root payment-expansion-panel-content-left body-content" >
                                    {(isMonthlySubscription(props.subscriptionOption) ? PlanDisplayText.MonthlyPlan : ( props.subscriptionOption.subscriptionInterval + " "+ PlanDisplayText.MonthMembership))}
                                </div>
                                <div className={'MuiFormControl-root payment-expansion-panel-content-right body-content'}>
                                    <strong>
                                        <DaysFreeText>
                                            {renderDaysFree()}
                                        </DaysFreeText>
                                    </strong>
                                </div>
                                <div>
                                    <p className={'payment-notice body-content'}>{ renderSubscriptionCost() }</p>
                                    <MonthsFreeText>
                                    {
                                        (subscriptionPromoCode?.couponDurationInMonths != undefined && subscriptionPromoCode?.couponDurationInMonths > 0) ? 
                                                `+ ${subscriptionPromoCode?.couponDurationInMonths} free months` : '' 
                                    } 
                                    </MonthsFreeText>
                                </div>
                                    
                            </Paper>
                        </div>
                    </ExpansionPanelDetails> 
                    }
                    <ExpansionPanelDetails>
                        {(props.subscriptionOption?.dnaCollectionCharges ?? 0) > 0 &&
                            <div className={"payment-expansion-panel"}>
                                <Paper elevation={1} className={'payment-expansion-panel-paper'}>
                                    <div className="MuiFormControl-root payment-expansion-panel-content-left">
                                    {props.displayName}
                                    </div>
                                    <div className={'MuiFormControl-root payment-expansion-panel-content-right'}>
                                        { renderLabAnalysisCost() }
                                    </div>
                                </Paper>
                            </div>
                        }
                    </ExpansionPanelDetails>
                </React.Fragment>
            }
        />
    )
    
}


const mapStateToProps = (state: { customerDetails: { promoCodeDetails: any; }; }) => {
    return { promoCodeDetails: state.customerDetails.promoCodeDetails };
};

export default connect(mapStateToProps)(ExpansionPanelPayment);
