import styled from 'styled-components';
import { MyDnaTheme } from '@themes/Styles/Default';

export const BottomMarginWrapper = styled.div`
    margin-bottom: 200px;
`;

export const StyledLabel = styled.label`
    font-weight: bold;
    width: 20rem;
`;

export const ExpansionSummaryTitleWrapper = styled.div`
    float: left;
    padding-right: 100px;
`;

export const ExpansionSummaryTitleIconWrapper = styled.div`
    float: right;
    padding-left: 35px;
`;

export const ExpansionSummaryWrapper = styled.div`
    align-self: baseline;
    width: 20rem;
`;

export const FormContentBlockWrapper = styled.div`
    margin: auto;
    width: ${MyDnaTheme.ContentWidth};
`;

export const ExpansionPanelItemWrapper = styled.div`
    margin-top: -10px;
    margin-left: -15px;
`;

export const TextBoxTitleWrapper = styled.div`
    padding: 10px;
    text-align: left;
    margin-left: -10px;
`;

export const ErrorMessageBlockWrapper = styled.div`
    margin-bottom: 1rem;
`;

export const AddressCaptureContentWrapper = styled.div`
font-family: 'AvenirRoman', sans-serif;
`;