export const TermsAndConditionsURLs=[
    { 
       Country: "AUSTRALIA" , 
       Partner: "MYDNA",
       URL:"https://www.mydna.life/terms-conditions/"
    },
    { 
        Country: "DEFAULT" , 
        Partner: "DEFAULT",
        URL:"https://www.mydna.life/terms-conditions/"
    },
    {
        Country: "AUSTRALIA" ,
        Partner: "DemoMegatronPartner",
        URL:"https://www.mydna.life/terms-conditions/"
    }
]