
export const CustomerServiceEndpoints = {
    UpdateRegistrationDetails: '/RegisterMicroService/Register/UpdateRegistrationDetails',
    CommitRegistrationDetails: '/RegisterMicroService/Register/CommitRegistrationDetails',
    RedeemBarcode: '/RegisterMicroService/Register/RedeemBarcode',
    VerifyPromoCode: '/RegistrationOptions/v2/VerifyPromoCode'
};

export const StatusCode = {
    STATUS_200: 200,
    STATUS_400: 400,
    STATUS_500: 500
}