import React from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import {
  AccordionWrapper,
  Title,
  ExpansionPanelContent,
  Header,
} from "./styles";

type FaqDataObjectType = {
  title: string;
  content: string;
}

type FAQComponentProps = {
  data: FaqDataObjectType[];
  defaultExpandedItem: number;
};

const FAQComponent = (props: FAQComponentProps) => {
  return (
    <AccordionWrapper>
      <Header>Questions?</Header>
      {props.data.map((each: FaqDataObjectType, index: number) => (
        <ExpansionPanel
          key={index}
          elevation={0}
          defaultExpanded={props.defaultExpandedItem === index}
        >
          <ExpansionPanelSummary className="faq-title">
            <Title>
              <ExpandMore className="expand-icon" />
              {each.title}
            </Title>
          </ExpansionPanelSummary>
          <ExpansionPanelContent
            dangerouslySetInnerHTML={{ __html: each.content }}
          ></ExpansionPanelContent>
        </ExpansionPanel>
      ))}
    </AccordionWrapper>
  );
};

FAQComponent.propTypes = {};

export default FAQComponent;
