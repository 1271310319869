const pageRoutings = {
    KITBASED: [
      {
        kitTypeIds: ["000000"],
        routings: [
          "dna-collection",
          "create-account",
          "personalDetails",
          "address",
          "goals",
          "orderDetails",
          "payment",
          "confirmation",
        ],
      },
      {
        kitTypeIds: ["20025"],
        routings: [
          "dna-collection",
          "create-account",
          "personalDetails",
          "address",
          "orderDetails",
          "payment",
          "confirmation",
        ],
      },
      {
        kitTypeIds: ["20004"],
        routings: [
          "dna-collection",
          "create-account",
          "personalDetails",
          "address",
          "goals",
          "medicationDetails",
          "orderDetails",
          "payment",
          "confirmation",
        ],
      },
    ],
    CONDITIONBASED: [],
  };

  export default pageRoutings;