import MyDnaSignUp from "./MyDnaSignUp";
import MyDnaConfirmSignUp from "./MyDnaConfirmSignUp";
import Authenticator from "aws-amplify-react/lib/Auth/Authenticator";
import React, {Component} from "react";
import { connect } from "react-redux";
import {RegisterActions} from "@base/Actions/RegisterActions";
import {APPCONSTANTS} from "@modules/Shared/Constants";
import {myDNARouter} from '@modules/utils/myDNARouter';
class Authentication extends Component {
    constructor(props) {
        super();
    }
    componentDidMount(){
        if(!this.props.customerDetails.initialValue.partner){
            myDNARouter(this.props, APPCONSTANTS.PREVIOUS_PAGE, "create-account")
        }
    }
    
    render() {
        return (
            <div>
                <Authenticator
                    authState={RegisterActions.SIGN_UP}
                    usernameAttributes='email'
                    hideDefault={true}>
                    <MyDnaSignUp override={RegisterActions.SIGN_UP} auth={this.props.auth}
                                 dispatch={this.props.dispatch} previousPage={this.props.previousPage}/>
                    <MyDnaConfirmSignUp override={RegisterActions.CONFIRM_SIGN_UP} auth={this.props.auth}
                                        dispatch={this.props.dispatch}/>
                </Authenticator>
            </div>
        )
    }
}

const mapStateToProps = (store) => {
    return store;
  };

export default connect(mapStateToProps)(Authentication);
