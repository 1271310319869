import React from 'react';
import styled from 'styled-components';

const StyledErrorLabel = styled.p`
    color: red;
    font-family: "AvenirRoman", sans-serif;
    font-size: 16px;
`;

const StyledErrorLabelSplitLines = styled.p`
    color: red;
    font-family: "AvenirRoman", sans-serif;
    font-size: 16px;
    white-space: pre-wrap;
    width: 21rem;
    margin-left: auto;
    margin-right: auto;
`;

type ErrorLabelProps = {
    children: React.ReactNode;
}

export const ErrorLabel = ({children} : ErrorLabelProps) => {
    return (
        <StyledErrorLabel>
            {children}
        </StyledErrorLabel>
    );
}

export const ErrorLabelSplitLines = ({children} : ErrorLabelProps) => {
    return (
        <StyledErrorLabelSplitLines>
            {children}
        </StyledErrorLabelSplitLines>
    );
}