import { PromoCodeDetails } from "@modules/Models/PromoCodeDetails";
import { SubscriptionFreightOptions } from "@modules/Models/SubscriptionFreightOptions";

export default function CalculateTotal(
    subscriptionOption: SubscriptionFreightOptions,
    promoCodeDetails?: PromoCodeDetails) {

    // Upfront charge = (dna collection charges - promo code reductions) + freight charge
    //var total = subscriptionFreightOptions[0].deliveryOptions?[0].initialChargeAmount;
    var total = 0;

    const deliveryOption = subscriptionOption?.deliveryOptions !== undefined ? subscriptionOption.deliveryOptions[0] : null;
    const deliveryCharge = deliveryOption?.initialChargeAmount ?? 0;

    total += deliveryCharge;

    if (promoCodeDetails && promoCodeDetails.isValid) {
        const dnaCollectionPromoCode = promoCodeDetails.products.find((x) => x.productCode == subscriptionOption.collectionKitProductCode);
        if (dnaCollectionPromoCode != null) total += dnaCollectionPromoCode.amount;
    } else {
        total += subscriptionOption?.dnaCollectionCharges ?? 0;
    }

    return total;
}