import styled from 'styled-components';

export const ComponentButtonWrapper = styled.div`
  display: flex;
  margin: auto;
`;

export const GoalSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding-bottom: 30px;
`;