import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import isEmpty from 'lodash/isEmpty';
import {
    Elements,
    useStripe,
    useElements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
} from '@stripe/react-stripe-js';
import { TextField, makeStyles, CircularProgress, Button } from '@material-ui/core';
import StripeInput from './StripeInput';
import PaymentIcons from "@modules/Shared/PaymentIcons";
import { commitCustomerDetails } from "@appSyncGateway/GraphqlClientAPIHelper";
import ErrorMsgHandler from "@utils/ErrorMsgHandler";
import { paymentDetails, 
    paymentDetailsCaptured, 
    paymentDetailsCaptureFailed, 
    sendOrderDetailsToGTM,
    verifyAndSetPromoCode,
    resetState } from "@base/Actions/CustomerDetailsActions";
import OrderSummary from "@modules/Shared/OrderSummary";
import { CardTypes } from "@modules/Shared/CardTypes";
import { PrimaryTitles } from "@modules/Shared/TitleTexts";
import ExpansionPanelPayment from "@modules/ExpansionPanel/ExpansionPanelPayment";
import ComponentContainerHeader from "@modules/Header";
import { push } from "connected-react-router";
import { useStore } from 'react-redux';
import PromoCodeEntry from "./PromoCode/PromoCodeEntry";
import { CalculateTotal } from "@utils/CalculateTotal";
import _ from 'lodash';
import {myDNARouter} from '@modules/utils/myDNARouter';
import { APPCONSTANTS } from "@modules/Shared/Constants";

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: "center",
        display: "inline-block",
        alignItems: 'center',
        justifyContent: 'center',
        margin: theme.spacing(1)
    }
}));

const CheckoutForm = (props) => {
    const { auth, dispatch, userDetails, previousPage, nextPage, place, goals, orderDetails, barcode, subscriptionFreightOptions } = props;
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [errorToDisplay, setErrorToDisplay] = useState("");
    const [subscriptionOption, setSubscriptionOption] = useState({});
    const [deliveryOption, setDeliveryOption] = useState({});
    const classes = useStyles();
    const store = useStore().getState();
    const detailsCaptured = { auth, place, userDetails, goals, orderDetails };

    useEffect(() => {
        if (!userDetails || _.isEmpty(userDetails)) {
            dispatch(push("/"));
        }

        dispatch(paymentDetails(detailsCaptured));
        fetchData();
    }, [])

    const fetchData = async () => {

        const freightOptions = !_.isEmpty(subscriptionFreightOptions) ? subscriptionFreightOptions : 
        [{
            dnaCollectionCharges: 0
        }];

        const selectedSubscriptionOption = freightOptions?.filter(element => element.id === orderDetails.subscriptionOptionId)[0];

        setSubscriptionOption(selectedSubscriptionOption);

        var deliveryOptions = selectedSubscriptionOption?.deliveryOptions ?? [];
        if (!barcode?.partner) {
            const selectedDeliveryOption = deliveryOptions.filter(element => element.id === orderDetails.deliveryOptionId)[0]
            setDeliveryOption(selectedDeliveryOption);
        }
    }

    async function createSubscription({ paymentMethodId }) {

        const paymentData = {
            paymentMethodId: paymentMethodId
        }

        const customerDetails = {
            emailAddress: auth.user.attributes.email,
            marketingOptIn: localStorage.getItem('marketingOptIn')
        }

        const promoCodeDetails = store?.customerDetails?.promoCodeDetails;
        const validPromoCode = promoCodeDetails?.isValid ? promoCodeDetails?.promoCode : undefined;

        await commitCustomerDetails(
            customerDetails,
            userDetails,
            place,
            goals,
            orderDetails,
            paymentData,
            barcode,
            store?.customerDetails?.medicationDetails,
            validPromoCode
            )
            .then(response => {
                const commitRegistrationDetailsResponse = response.data.commitRegistrationDetails;
                const barcodeDisplayName = store.customerDetails.barcode.displayName;
                setLoading(false);
                dispatch(paymentDetailsCaptured(detailsCaptured, commitRegistrationDetailsResponse));
                dispatch(sendOrderDetailsToGTM(subscriptionOption, promoCodeDetails, barcodeDisplayName, commitRegistrationDetailsResponse));
                myDNARouter(props, APPCONSTANTS.NEXT_PAGE, "payment");
                localStorage.removeItem("urlDataJwt");
            })
            .catch((err) => {
                let errMsg = ErrorMsgHandler(err);
                dispatch(paymentDetailsCaptureFailed(detailsCaptured, errMsg));
                setLoading(false);

                if (errMsg === "Your card was declined.") {
                    let errorData = { message: errMsg }
                    setErrors(prev => ({ ...prev, ["cardNumber"]: errorData }));
                }
                else if (errMsg === "Payment error. Please check your card details or contact your bank if the problem persists.") {
                    let errorData = { message: errMsg }
                    setErrors(prev => ({ ...prev, ["cardNumber"]: errorData }));
                }
                else if (errMsg === "Your card's security code is incorrect.") {
                    let errorData = { message: errMsg }
                    setErrors(prev => ({ ...prev, ["cvc"]: errorData }));
                }
                else
                    setErrorToDisplay("There has been an error processing your payment. Please try again.");

            });
    }

    const handleSubmit = async (event) => {
        setErrorToDisplay("");
        // Block native form submission.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet.
            return;
        }

        setLoading(true);

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardNumberElement = elements.getElement(CardNumberElement);

        if (!cardNumberElement) {
            setLoading(false);
        }

        // Use your card Element with other Stripe.js APIs
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardNumberElement,
        });

        if (error) {
            setLoading(false);
            return;
        }
        const paymentMethodId = paymentMethod.id;

        // Create the subscription
        createSubscription({
            paymentMethodId: paymentMethodId,
        });
    };

    const onStripeElementChange = (name, event) => {
        setErrors(prev => ({ ...prev, [name]: event.error }));
    }

    const verifyPromoCode = (promoCodeText) => {
        const promoCodeDetails = {
            partnerName: store.customerDetails.barcode.partner ?? "myDna",
            promoCode: promoCodeText,
            subscriptionCode: store.customerDetails.orderDetails.subscriptionOptionId,
            kitType: store.customerDetails.barcode.kitTypeId ?? ""
        }
        
        dispatch( verifyAndSetPromoCode(promoCodeDetails) );
    }

    // Calculate total payable amount
    const determineTotalPayableAmount = () => {
        var totalPayableAmount = 0;

        if (Object.keys(subscriptionOption).length > 0) {
            totalPayableAmount = CalculateTotal(subscriptionOption, store.customerDetails.promoCodeDetails)
        }

        return totalPayableAmount.toFixed(2);
    }

    return (
        <div id="payment-form" className={classes.root}>
            <div>
                <ComponentContainerHeader primaryTitle={PrimaryTitles.PaymentDetails} secondaryTitle={""} />
                <ExpansionPanelPayment 
                    partner={barcode?.partner} 
                    deliveryOption={deliveryOption} 
                    subscriptionOption={subscriptionOption}
                    disableSubscriptionDetails={true}
                    displayName={barcode.displayName}
                />
                <br />
                <PromoCodeEntry 
                  promoCodeCallback={verifyPromoCode}
                />
                <OrderSummary totalPayableAmount={determineTotalPayableAmount()} />
                <CardTypes />
                <form id="payment-form" onSubmit={handleSubmit}>
                    <div>
                        <TextField
                            label="Credit Card Number"
                            name="ccnumber"
                            variant="outlined"
                            required
                            fullWidth
                            className="Roman"
                            onChange={(e) => onStripeElementChange("cardNumber", e)}
                            error={!isEmpty(errors.cardNumber)}
                            helperText={!isEmpty(errors.cardNumber) ? errors.cardNumber.message : null}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                inputComponent: StripeInput,
                                inputProps: {
                                    component: CardNumberElement
                                },
                            }}
                        />
                        <br />
                        <TextField
                            label="Expiration Date (MM/YY)"
                            name="ccexpiry"
                            variant="outlined"
                            required
                            className="Roman"
                            fullWidth
                            onChange={(e) => onStripeElementChange("expiry", e)}
                            error={!isEmpty(errors.expiry)}
                            helperText={!isEmpty(errors.expiry) ? errors.expiry.message : null}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                inputComponent: StripeInput,
                                inputProps: {
                                    component: CardExpiryElement
                                },
                            }}
                        />
                        <br />
                        <TextField
                            label="CVC"
                            name="cvc"
                            variant="outlined"
                            className="Roman"
                            required
                            fullWidth
                            onChange={(e) => onStripeElementChange("cvc", e)}
                            error={!isEmpty(errors.cvc)}
                            helperText={!isEmpty(errors.cvc) ? errors.cvc.message : null}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                inputComponent: StripeInput,
                                inputProps: {
                                    component: CardCvcElement
                                },
                            }}
                        />
                        <br />
                        <PaymentIcons />
                        <div className="error">
                            {errorToDisplay}
                        </div>
                        <div className="inline-buttons">
                            <Button
                                disabled={loading}
                                className={'SubmitButton Roman'}
                                type="submit"
                                value="Submit"
                                variant="contained"
                                color={loading ? "secondary" : "primary"}
                            >{loading ? <CircularProgress size={20} color="secondary" /> : "PROCESS PAYMENT"}
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </div >
    );
};

const PaymentForm = (props) => (
    <Elements stripe={loadStripe(props.orderDetails.stripePublishableKey)}>
        <CheckoutForm {...props} />
    </Elements>
);

export default PaymentForm;