import React, {useState, useEffect} from 'react'
import DefaultTheme, { StyledButton, StyledButtonWrapper, StyledTextWrapper } from './styles';

// Strongly typed theme object
// This component is designed to have a default theme
// You can also pass a new theme object to it to override the style
export type MyDnaButtonTheme = {
    Color: string,
    BackgroundColours: MyDnaButtonColourPalette,
    BorderColours: MyDnaButtonColourPalette,
    TextColours: MyDnaButtonColourPalette,
    FontFamily: string,
    FontSize?: string,
    BorderRadius?: string
    TextTransform?: string
}

export type MyDnaButtonColourPalette = {
    DefaultColour: string,
    HoverColour: string,
    ActiveColour: string,
    DisabledColour: string,
    FocusColour: string
}

// Button variant enums
export type ButtonType = 'primary' | 'ghost' | 'secondary' | 'cta';
export type ButtonVariant = 'slim' | 'thick';

// Strongly typed props
export type MyDnaButtonProps = {
    onClick?: () => void,
    children?: React.ReactNode,
    type?: 'button' | 'submit' | 'reset',
    buttonType?: ButtonType,
    buttonVariant?: ButtonVariant,
    themes?: { [id in ButtonType]: MyDnaButtonTheme };
    isdisabled?: boolean; 
    disableElevation?: boolean;
    fullwidth?: boolean;
    disableTimer?: number;
}

const MyDnaButton = ({
    onClick, 
    children, 
    type, 
    buttonType,
    themes,
    isdisabled,
    disableElevation,
    fullwidth,
    buttonVariant,
    disableTimer
}: MyDnaButtonProps) => {

    const [state, setState] = useState({
        disabled: isdisabled || false,
    });
    
    const disableButton = (bool: boolean)=>{
        setState({...state, disabled: bool});
    }

    useEffect(()=>{
        disableButton(isdisabled || false);
    }, [isdisabled])
    
    const onClickDisable = ()=>{
        if(isdisabled)
            return;

        disableButton(true);

        if(onClick !== undefined)
            onClick();

        if(disableTimer != 0){
            setTimeout(()=>{
                disableButton(false);
            }, disableTimer)
        }
    }

    const ButtonProperties = { 
        onClick: disableTimer == undefined ? onClick : onClickDisable,
        type: type,
        disableElevation: disableElevation,
        fullWidth: fullwidth
    };

    var buttonThemes: { [id in ButtonType]: MyDnaButtonTheme } = themes ?? DefaultTheme;
    var buttonTheme = buttonType ? buttonThemes[buttonType] : buttonThemes['primary'];

    var buttonHeight = "100%";
    if (buttonVariant === 'thick') buttonHeight = "4.313rem";

    const buttonProps = {
        isdisabled: state.disabled,
        buttontheme: buttonTheme,
        buttonheight: buttonHeight
    }

    return (
        <StyledButtonWrapper props={buttonProps}>
            <StyledButton 
            {...ButtonProperties} 
            variant={'outlined'}
            color={'inherit'} 
            disabled={state.disabled}
            props={buttonProps}
            >
                <StyledTextWrapper props={buttonProps}>
                    {children}
                </StyledTextWrapper>
            </StyledButton>
        </StyledButtonWrapper>
    )
}

export default React.memo(MyDnaButton);