import React from "react";
import TextField from "@material-ui/core/TextField";
import { FieldHookConfig, useField } from "formik";

type TextInputProps = {
  name: string | FieldHookConfig<any>; 
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void; 
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  value?: string; 
  label?: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null; 
  id?: string; 
  type?: string; 
  className?: string; 
  maxLength?: string;
  error?: boolean;
  touched?: boolean;
  defaultValue?: string;
  capitalize?: boolean;
  disabled?: boolean;
}

const capitalize = (text: string) => {
  return text ? text.toUpperCase() : text
}

export function TextInput(props: TextInputProps) {
  const [field, meta] = useField(props.name);

  const errorText = meta.error && (meta.touched || props.touched) ? meta.error : "";

  var optionalProps = {};

  if (props.onChange !== undefined) {
    optionalProps = {
      ...optionalProps,
      onChange: props.onChange
    }
  }

  return (
    <TextField
      label={props.label}
      {...field}
      id={props.id}
      type={props.type?props.type:"text"}
      helperText={errorText}
      error={!!errorText}
      variant="outlined"
      inputProps={{ maxLength: props.maxLength || "40" }}
      value = {props.capitalize ? capitalize(field.value) : field.value}
      {...optionalProps}
      disabled={props.disabled}
    />
  );
}
