import unlockAppLogo from "@assets/mydna-square-logo.png";
import PregnancyAppLogo from "@assets/pregnancyAppLogo.png";

export const systemUI = [
  {
    name: "FTDNA",
    kitTypeIds: ["20018", "20020"],
    barCode: {
      title: "VERIFY YOUR FAMILYTREEDNA KIT",
      description:
        "Your DNA powers the myDNA personalization engine, but we first need to link your FamilyTreeDNA genetic data with your myDNA account.",
      info: "Is this the correct FamilyTreeDNA Kit number?",
      verificationMessage: "Please verify your activation code to proceed.",
      requiredMessage: "Please enter your activation code.",
      displayAnimation: true,
      displayWhereIsMyKitCTA: false,
      populateBarCode: true,
      disabledBarCodeTexField: true,
      showFAQ: true,
      iHaveAKitButton: {
        caption: "",
        visible: false,
      },
    },
    confirmation: {
      hidePaymentDetails: false,
      AppIntro:
        "While we wait for your DNA sample there's already a tonne of content waiting for you in the myDNA Unlocked App.",
    },
    appDetails: {
      appName: "myDNA Unlocked App",
      appInfo: "DOWNLOAD myDNA UNLOCKED TODAY TO START EXPLORING",
      appLogo: unlockAppLogo,
    },
    addressCapture: {},
    orderDetail: {
      hideTrialPeriodInfo: false,
    },
    insights: {
      listItem: [
        "Access to the myDNA Unlocked smartphone app to examine your DNA Insights from anywhere - a lifetime blueprint for managing your health and wellness",
        "Scientifically backed information to help you understand your unique body",
      ],
    },
  },
  {
    name: "default",
    kitTypeIds: ["000000"],
    barCode: {
      title: "IT ALL STARTS WITH A SIMPLE CHEEK SWAB",
      description:
        "Your DNA powers our personalization engine, but we need a sample to get started.",
      activateMessage: "You can activate an existing kit below, or ",
      purchaseMessage: "purchase a new kit from our online store.",
      verificationMessage: `Please verify your activation code to proceed.`,
      requiredMessage: `Please enter your activation code.`,
      displayAnimation: false,
      displayWhereIsMyKitCTA: true,
      populateBarCode: false,
      disabledBarCodeTexField: false,
      showFAQ: false,
      iHaveAKitButton: {
        caption: "",
        visible: false,
      },
    },
    confirmation: {
      hidePaymentDetails: false,
      AppIntro:
        "While we wait for your DNA sample there's already a tonne of content waiting for you in the myDNA Unlocked App.",
    },
    appDetails: {
      appName: "myDNA Unlocked",
      appInfo: "DOWNLOAD myDNA UNLOCKED TODAY TO START EXPLORING",
      appLogo: unlockAppLogo,
    },
    addressCapture: {},
    orderDetail: {
      hideTrialPeriodInfo: false,
    },
    insights: {
      listItem: [
        "Access to the myDNA Unlocked smartphone app to examine your DNA Insights from anywhere - a lifetime blueprint for managing your health and wellness",
        "Scientifically backed information to help you understand your unique body",
      ],
    },
  },
  {
    name: "ViaCord",
    kitTypeIds: ["20025"],
    barCode: {
      title: "IT ALL STARTS WITH A SIMPLE CHEEK SWAB",
      description:
        "Your DNA powers our personalization engine, but we need a sample to get started.",
      activateMessage: "You can activate an existing kit below, or ",
      purchaseMessage: "purchase a new kit from our online store.",
      verificationMessage: `Please verify your activation code to proceed.`,
      requiredMessage: `Please enter your activation code.`,
      displayAnimation: false,
      displayWhereIsMyKitCTA: true,
      populateBarCode: false,
      disabledBarCodeTexField: false,
      showFAQ: false,
      iHaveAKitButton: {
        caption: "",
        visible: false,
      },
    },
    confirmation: {
      hidePaymentDetails: true,
      AppIntro:
        "While we wait for your DNA sample there's already a tonne of content waiting for you in the myDNA Pregnancy App.",
    },
    appDetails: {
      appName: "myDNA PREGNANCY",
      appInfo: "DOWNLOAD myDNA PREGNANCY TODAY TO START EXPLORING",
      appLogo: PregnancyAppLogo,
    },
    addressCapture: {
      alternativeNextPage: "/orderDetails",
    },
    orderDetail: {
      hideTrialPeriodInfo: true,
      alternativePreviousPage: "/address",
    },
    insights: {
      listItem: [
        "Meal and exercise plans for each antenatal trimester and postnatal recovery, as well as guided meditations to aide in relaxation and connection",
        "Lifetime access to the myDNA personalized Wellness for Pregnancy App to examine your DNA Insights, meals, exercises and health advice from anywhere and for future pregnancies",
      ],
    },
  },
];
