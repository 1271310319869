import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import {useField} from "formik";
import CircularIndeterminate from "./CircularIndeterminate";
import { ErrorLabel } from '@modules/ErrorLabel';

// Todo: Convert to styled-components

const useStyles = makeStyles(theme => ({
  formControl: {
    "text-align": "left",
    "font-family": "'AvenirRoman', sans-serif !important",
    width:'18rem',
    marginLeft: -25
  },
  radioGroupHorizontal: {
    "flex-direction" : "row"
  },
  formLabel: {
    "font-family": "'AvenirRoman', sans-serif",
    "font-style": "normal",
    "font-weight": "normal",
    "line-height": "3rem",
    "text-align": "left",
    color: "#A5CE49",
    width:'18rem'
  },
  radioButtonLabel: {
    "text-align": "left",
    font: "normal normal normal 16px/24px 'AvenirRoman', sans-serif !important",
    letterSpacing: 0,
    color: '#000000',
    opacity: 1
  },
  LegendGreen:{
    color:"#FFFFF"
  },
  PrimaryLabel:{
    color:"rgba(0, 0, 0, 0.54)",
    float:'left', 
    width:'14rem'
  },
  formControlOther: {
    "text-align": "left",
    width:'21rem',
    "font-family": "'AvenirRoman', sans-serif",
  },
  ToggleButton:{
    width:'18rem'
  }
}));

function StyledRadio(props) {
  return (
    <Radio
      {...props}
    />
  );
}

const FormikRadio = props => {
  const [field] = useField(props);
  const classes = useStyles();
  return (
    <FormControlLabel 
      {...field} 
      control={ <StyledRadio /> } 
      label={props.label} 
      classes={{label: classes.radioButtonLabel}}  
      style={props.style} 
      onClick={props.onClick}
      checked = {props.checked}
    />
  );
};

export default function RadioButtonsGroup(props) {

  const classes = useStyles();
  const [field, meta] = useField(props.name);

  if (!props.options || props.options.length === 0) {
      return <CircularIndeterminate/>;
  }

  const onChange = (event) => {
    if (props.onChange) props.onChange(event);
  }
  
  return (
      <FormControl component="fieldset" className={classes.formControlOther}>
        <FormLabel component="legend" className={'radio-label'}>{props.label}</FormLabel>
        <RadioGroup name={props.label} className={classes.radioGroupHorizontal}>
          {props.options.map(option => (
            <FormikRadio
              type="radio"
              key={option.id}
              value={option.id}
              label={option.displayName}
              name={props.name}
              options={option}
              checked={props.value == option.id}
              onClick={onChange}
            />
          ))}
        </RadioGroup>
      </FormControl>
  );
}
