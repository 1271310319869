import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';

// todo: convert these styles to styled-components for consistency

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#081F2D",
        color: theme.palette.common.white,
    },
    body: {
        textAlign: 'left',
        font: 'normal normal normal 16px/22px "AvenirRoman", sans-serif',
        letterSpacing: 0,
        color: '#000',
        opacity: 1
    },
    
}))(TableCell);

const styles = {
    root:{
        borderRadius: '5px 5px 0px 0px',
    }
}

const MedicationsTable = ({ rows, deleteItem }) => {
    return (
        <TableContainer component={Paper} style={styles.root}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell className="meds-table-header">MEDICATION</StyledTableCell>
                        <StyledTableCell align="left" className="meds-table-header" >COMMON USES</StyledTableCell>
                        <StyledTableCell align="left" className="meds-table-header"></StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows?.map((row) => (
                        <TableRow key={row.name}>
                            <TableCell className={'table-content'} align="left" component="td" >
                                <span className={'table-content'}>{row.name}</span>
                            </TableCell >
                            <TableCell align="left" className={'table-content small'} component="td" ><span className={'table-content small'}>{row.commonUses}</span></TableCell >
                            <TableCell size="small" align="left" component="td" ><DeleteIcon style={{ color: "#44BCEA" }} onClick={() => { deleteItem(row) }} /></TableCell >
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default MedicationsTable
