import styled from 'styled-components';
import { MyDnaTheme } from '@themes/Styles/Default';

export const PersonalDetailsWrapper = styled.div`
    text-align: center;
    display: inline-block;
`;

export const FieldBlock = styled.div`
    padding-bottom: 1rem;
`;

export const FieldWrapper = styled.div`
    padding-bottom: 0.5rem;
`;

export const UnderAgeWarningTestBlock = styled.div`
    width: 21rem;
    margin: auto;
`;

export const UnderAgeWarningHeader = styled.p`
    text-align: left; 
    font-weight: 900;
    font-size: 12px;
    color: ${MyDnaTheme.Colours.Input.Heavy};
`;

export const UnderAgeWarningContent = styled.p`
    margin-top: -10px;
    text-align: left;
    font-size: 12px;
    color: ${MyDnaTheme.Colours.Input.Heavy};
`; 