import { createGlobalStyle } from 'styled-components'
import MyDnaTheme from './theme';

const GlobalStyle = createGlobalStyle`

    p {
        font-family: "AvenirRoman", sans-serif;
    }

    div {
        font-family: "AvenirRoman", sans-serif;
    }

    span {
        font-family: "AvenirRoman", sans-serif;
    }

    a {
        color: ${MyDnaTheme.Colours.Links};
    }

    h1 {
        text-align: center;

        font-style: normal;
        font-variant: normal;
        font-weight: 600;
        font-family: 'NowRegular', sans-serif;
        letter-spacing: 1.8px;
        color: ${MyDnaTheme.Colours.Headings.Light};
        text-transform: uppercase;
        opacity: 1;
        font-size: 18px;
        line-height: 25px;
    }

    .MuiLinearProgress-barColorPrimary {
        background-color: ${MyDnaTheme.Colours.Stepper};
    }

    .MuiToggleButton-root: {
        height: 117px,
        width: 94px,
    }
  
    .MuiToggleButton-root {
        background: #FFFFFF,
        box-sizing: border-box,
        border-radius: 4px,
    }

    .MuiToggleButtonGroup-grouped: {
        margin: 5px,
        border-radius: 4px,
        font-size: 12px,
        line-height: 16px,
        color: #000000,
        text-align: center,
        text-transform: capitalize,
  
        img: {
          height: 48px,
          width: 48px,
        },
    }

    .MuiFormControl-root.MuiTextField-root {
        margin: 1rem;
        width: $control-width;
    }    
    .MuiFormControl-root {
        border: 0;
        margin: 0;
        display: inline-flex;
        padding: 0;
        z-index: 0;
        position: relative;
        min-width: 0;
        flex-direction: column;
        vertical-align: top;
    }
    .MuiFormControl-marginNormal {
        margin-top: 16px;
        margin-bottom: 8px;
    }
    .MuiFormControl-marginDense {
        margin-top: 8px;
        margin-bottom: 4px;
    }
    .MuiFormControl-fullWidth {
        width: 100%;
    }
    .payment-expansion-panel {
        width: 21rem;
        margin-bottom: 10px;
    }
  
    .payment-expansion-panel-paper {
        text-align: left;
        height: 3rem;
        padding: 10px;
    }
    .payment-notice {
        float: left;
        margin-top: -5px;
        font-size: 13px;
        font-weight: 500;
        width: 100%;
    }
    .greyed {
        background: #dedede;
    }
    
    .payment-expansion-panel-content-left {
        height: 1.5rem;
        float: left;
        font-family: "AvenirRoman", sans-serif;
    }
    
    .payment-expansion-panel-content-right {
        height: 1.5rem;
        float: right;
        text-align: right;
        font-family: "AvenirRoman", sans-serif;
    }

    .mydna-expansion-panel-item {
        margin-top: -10px;
        margin-left: -15px;
    }
    .mydna-expansion-panel-item-barcode {
        margin-top: -10px;
    }
    .mydna-expansion-panel-summary {
        align-self: baseline;
        width: 20rem;
    }
    
    .mydna-expansion-panel-summary-title {
        float: left;
        padding-right: 100px;
    }
    
    .mydna-expansion-panel-summary-title-icon {
        float: right;
        padding-left: 35px;
    }  
`;

export default GlobalStyle;
