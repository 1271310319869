import styled from "styled-components";
import { MyDnaTheme } from "@themes/Styles/Default";

export const ListItem = styled.li`
  font-size: 12.6px;
  line-height: 17.6px;
  
  @media (max-width: ${MyDnaTheme.MediaSizes.Small}) {
    font-size: 11.8px;
  }
`;

export const InsightsList = styled.ul`
  font-family: "AvenirMedium", sans-serif;
  color: ${MyDnaTheme.Colours.Input.Heavy};
  padding: 0;
  margin: 0 0 10px 18px;
`;
