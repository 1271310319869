import React, { Component } from 'react';
import ComponentContainerHeader from "@modules/Header";
import {PrimaryTitles} from "@modules/Shared/TitleTexts";
import {batch, connect} from "react-redux";
import { selectApplinkURL } from "@base/Reducers/CustomerDetailsReducer";
import { clearCustomerDetails } from '@base/Actions/CustomerDetailsActions';
import { Auth } from "aws-amplify";
import { push } from "connected-react-router";
import { ConfirmationLinks } from "@modules/Shared/Constants";
import { messages } from '@modules/Shared/Messages';
import { CalculateTotal } from '@utils/CalculateTotal';
import { StringReplace } from '@utils/StringReplace';
import _ from 'lodash';
import { signOut } from "@base/Actions/RegisterActions";
import { Redirect } from "react-router";

import GooglePlayDownloadButton from '@assets/google-play-download-button.png';
import IosAppStoreDownloadButton from '@assets/ios-app-store-download-button.png';

import { 
    PaymentContainerWrapper, 
    StyledConfirmationImage, 
    NoDecorationTextWrapper,
    ConfirmationFooterWrapper,
    LogoWrapper, 
    CtaWrapper, 
    CtaContentsWrapper, 
    CtaTextWrapper, 
    CtaButtonsWrapper, 
    CtaButtonWrapper, 
    StyledImg,
    ConfirmationBodyWrapper,
    ConfirmationTextBlock,
    StyledSpan,
    BoldText,
    SubscriptionPlanTextWrapper,
    ImageWrapper,
    StyledHorizontalBreak
} from './styles';

export const ConfirmationLayout = ({
    fitnessGoal,
    nutritionalGoal,
    iosUrl,
    androidUrl,
    isStaffKitType,
    isVipKitType,
    myDnaConfirmationLink,
    subscriptionPlanName,
    totalPaid,
    confirmationEmailAddress,
    currency,
    subscriptionType,
    systemUI
}) => {
    const confirmationPageUI = systemUI.confirmation; 
    const appDetails = systemUI.appDetails;
    //VIP customers don't display:
    // - total payment
    // - free trial/first payment
    // - confirmation email

    return (
        <div>
            <ComponentContainerHeader primaryTitle={PrimaryTitles.Confirmation} secondaryTitle={""}/>
            <form id="order_complete">
            </form>
            <PaymentContainerWrapper>
                <ConfirmationBodyWrapper>
                    <ImageWrapper>
                        <StyledConfirmationImage src={require('@assets/confirmation-clip-board.png')} alt="confirmation clip board" />
                    </ImageWrapper>
                    <ConfirmationTextBlock>
                        <SubscriptionPlanTextWrapper>
                            {subscriptionPlanName}
                        </SubscriptionPlanTextWrapper>
                    </ConfirmationTextBlock>
                    {!confirmationPageUI.hidePaymentDetails  && 
                    !isVipKitType &&
                    <>
                        <ConfirmationTextBlock>
                            <div>
                                {`${messages.confirmationPageText.YourTotal} `}
                                <BoldText>{`${currency} ${totalPaid}`}</BoldText>
                            </div>
                        </ConfirmationTextBlock>
                        <ConfirmationTextBlock>                                
                            {messages.confirmationPageText.FreeTrial}
                        </ConfirmationTextBlock>                        
                    </>}
                    {subscriptionType !== "Insights only" && <>
                    <ConfirmationTextBlock>
                        <StyledSpan>
                            {StringReplace(messages.confirmationPageText.Goals, [fitnessGoal, nutritionalGoal])}
                        </StyledSpan>
                    </ConfirmationTextBlock>
                    {!isVipKitType &&
                    <>
                        <ConfirmationTextBlock>
                            {messages.confirmationPageText.Slogan}
                        </ConfirmationTextBlock>
                    </>} </>}
                    <ConfirmationTextBlock>
                        <StyledSpan>
                            {confirmationPageUI.AppIntro}
                        </StyledSpan>
                    </ConfirmationTextBlock>
                    <ConfirmationTextBlock>
                        <BoldText>
                            <StyledSpan>
                                {`${messages.confirmationPageText.ConfirmationEmail}  ${confirmationEmailAddress}`}
                            </StyledSpan>
                        </BoldText>
                        </ConfirmationTextBlock>
                </ConfirmationBodyWrapper>
                <StyledHorizontalBreak />
                <ConfirmationFooterWrapper>
                    <LogoWrapper>
                        <img src={appDetails.appLogo} alt={'MyDna square logo'} />
                    </LogoWrapper>
                    <CtaWrapper>
                        <CtaContentsWrapper>
                            <CtaTextWrapper>
                                {appDetails.appInfo}
                            </CtaTextWrapper>
                            <CtaButtonsWrapper>
                                <CtaButtonWrapper>
                                    <a href={iosUrl} target="_blank" rel="noopener noreferrer">
                                        <StyledImg src={IosAppStoreDownloadButton} />
                                    </a>
                                </CtaButtonWrapper>
                                <CtaButtonWrapper>
                                    <a href={androidUrl} target="_blank" rel="noopener noreferrer">
                                        <StyledImg src={GooglePlayDownloadButton} />
                                    </a>
                                </CtaButtonWrapper>
                            </CtaButtonsWrapper>
                        </CtaContentsWrapper>
                    </CtaWrapper>
                </ConfirmationFooterWrapper>
                { (!isStaffKitType) && (!isVipKitType) &&
                <p className={'confirmation-text body-content'}>
                    <NoDecorationTextWrapper href = {myDnaConfirmationLink} target="_blank" rel="noopener noreferrer" >
                        Return to myDNA
                    </NoDecorationTextWrapper>
                </p>}
            </PaymentContainerWrapper>
        </div>
    );
}

class Confirmation extends Component {

    constructor(props){
        super(props)
        this.state={
            AppLinkURL: selectApplinkURL(props.barcode?.partner),
        }
        this.AnimationContainer = React.createRef();
    }

    componentDidMount(){
        Auth.signOut();
        batch(() => {
            this.props.dispatch(signOut());
            this.props.dispatch(clearCustomerDetails());
        })
    }

    shouldComponentUpdate(nextProps, nextState) {
        // After this page has loaded, prevent it from ever reloading
        // This allows us to wipe the redux state after the page has loaded
        return false;
    }

    render() {

        if (this.props.auth.user == null) return <Redirect to="/" />;
        if (_.isEmpty(this.props.subscriptionFreightOptions) || this.props.subscriptionFreightOptions == undefined) return this.props.dispatch(push("/"));

        const fitnessGoal= this.props.goals?.fitnessGoal;
        const nutritionalGoal= this.props.goals?.nutritionalGoal;
        const iosUrl = this.state.AppLinkURL.IOS_URL;
        const androidUrl = this.state.AppLinkURL.Android_URL;
        const isStaffKitType = this.props.barcode?.isStaffKitType;
        const isVipKitType = this.props.barcode?.isVipKitType;
        const myDnaConfirmationLink = ConfirmationLinks.myDNA;

        const subscriptionOption = this.props.subscriptionFreightOptions.find(x => x.id == this.props.orderDetails.subscriptionOptionId);
        const subscriptionPlanName = this.props.customerDetails.barcode.displayName;
        const confirmationEmailAddress = this.props.userDetails.emailAddress;

        const totalPaid = CalculateTotal(subscriptionOption, this.props.promoCodeDetails).toFixed(2);
        const currency = this.props.orderDetails.currency;
        const subscriptionType = this.props.orderDetails.subscriptionType;
        const systemUI = this.props.customerDetails.systemUI;
        return ConfirmationLayout({
            fitnessGoal,
            nutritionalGoal,
            iosUrl,
            androidUrl,
            isStaffKitType,
            isVipKitType,
            myDnaConfirmationLink,
            subscriptionPlanName,
            totalPaid,
            confirmationEmailAddress,
            currency,
            subscriptionType,
            systemUI
        });
    }
}

const mapStateToProps = (state) => {
    return state;
  };

export default connect(mapStateToProps)(Confirmation);
