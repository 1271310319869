const ContentWidth = "21rem";

const MediaSizes = {
    Small: "769px",
    Smaller: "480px",
    Smallest: "360px"
}

const Colours = {
    Headings: {
        Light: "#959595",
        Heavy: "#081F2D"
    },
    Field: {
        White: "#FFFFFF",
        Light: "#FBFBFB"
    },
    Spacer: {
        Light: "#ECECEC"
    },
    Primary: "#30B5E8",
    Input: {
        Light: "#BFBFBF",
        Heavy: "#707070",
        Muted: "#C5C5C5",
        Disabled: "#F1F1F1"
    },
    Base: {
        Black: "#000000",
        White: "#FFFFFF",
        LightGrey: "#D3D3D3",
        Grey: "#B8B8B8"
    },
    Buttons: {
        Default: "#30B5E8",
        Hover: "#A6D3F0",
        Click: "#3E83A0",
        Disabled: "#C5C5C5"
    },
    Links: "#30B5E8",
    Stepper: "#E72076"
};

const Header = {
    PrimaryTitle: {
        FontSize: "24px",
        LineHeight: "32px",
        LetterSpacing: "2.4px",
        FontFamily: "'NowBlack', sans-serif",
    },
    SecondaryTitle: {
        FontSize: "16px",
        LetterSpacing: "0px",
        FontFamily: '"AvenirRoman", sans-serif',
    }
};

const ComponentWrapper = {
    Color: Colours.Headings.Light,
    FontFamily: "'NowHeavy', sans-serif",
    FontStyle: "normal",
    FontSize: "normal",
    LineHeight: "22px",
    Width: "21rem",
    LetterSpacing: "1.8px",
}

type MyDnaButtonTheme = {
    Color: string,
    BackgroundColour: string,
    FontFamily: string,
    FontSize?: string,
    BorderRadius?: string
    TextTransform?: string
}

type Button = {
    [id in 'primary' | 'secondary']: MyDnaButtonTheme;
};

const Buttons: Button =  {
    'primary': {
        Color: Colours.Base.White,
        FontSize: "18px",
        BorderRadius: "5px",
        BackgroundColour: Colours.Primary,
        FontFamily: "'NowRegular', sans-serif"
    },
    'secondary': {
        Color: Colours.Primary,
        FontSize: "16px",
        BorderRadius: "5px",
        TextTransform: "none",
        BackgroundColour: Colours.Base.White,
        FontFamily: "'NowRegular', sans-serif"
    }
}

const MyDnaTheme = {
    ContentWidth: ContentWidth,
    Colours: Colours,
    Header: Header,
    Buttons: Buttons,
    ComponentWrapper: ComponentWrapper,
    MediaSizes: MediaSizes
}

export default MyDnaTheme;