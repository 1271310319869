import React from 'react';

const OrderSummary = (props) => {
    const totalPayableAmount = props.totalPayableAmount;

    return (
        <div className={'TotalContainer-WithoutBottom'}>
            <div className={'order-total'}>
                <span className={'order-total-left body-content'}>Your total today </span>
                <span className={'order-total-right body-content'}><span>$</span><span id={'total-amount'}>{totalPayableAmount ?? '0'}</span></span>
            </div>
        </div>
    )
}

export default OrderSummary;
