import styled from 'styled-components';
import { MyDnaTheme } from '@themes/Styles/Default';

export const HorizontallyPaddedDiv = styled.div`
    padding-right: 20px;
    padding-left: 20px;
`;

export const HorizontallyPaddedP = styled.p`
    padding-right: 20px;
    padding-left: 20px;
`;

export const MedicationDetailsPageWrapper = styled.div`
    text-align: center;
    display: inline-block;
    align-items: center;
    justify-content: center;
    margin: auto;
`;

export const MedicationDetailsContentWrapper = styled(HorizontallyPaddedDiv)`
    text-align: left;
    color: ${MyDnaTheme.Colours.Input.Heavy};
`;

export const MedicationDetailsBlock = styled.div`
    margin: auto;
  
    @media (max-width: ${MyDnaTheme.MediaSizes.Small}) {
      width: 23rem;
  }
`;

export const ColouredHorizontalLine = styled.hr`
    height: 1px;
    border: none;
    color: ${MyDnaTheme.Colours.Primary};
    background-color: ${MyDnaTheme.Colours.Primary};
    opacity: 1;
    margin-top: 40px;
    width: 80%;
`;

export const WidthMedsWrapper = styled.div`
    width: 23rem;
    margin: auto;
`;

export const MedicationDetailsWrapper = styled(HorizontallyPaddedP)`
    text-align: left;
    letter-spacing: 0px;
    color: ${MyDnaTheme.Colours.Input.Heavy};
    opacity: 1;

    margin: auto;
`;

export const BottomPaddedDiv = styled.div`
    padding-bottom: 20px;
`;