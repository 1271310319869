import { CustomerServiceEndpoints } from "./HttpConstants";
import { updateRegistrationDetails, commitRegistrationDetails, redeemBarcode } from '../graphql/mutations';
import { API } from 'aws-amplify';
import { VerifyPromoCode } from '../graphql/queries';

import { GetFormattedDate } from "@utils/DateFormatter";
function addPersonalDetails(customerDetails, personalDetails) {

    customerDetails.emailAddress = personalDetails.emailAddress;
    customerDetails.firstName = personalDetails.firstName;
    customerDetails.lastName = personalDetails.lastName;
    customerDetails.dob = personalDetails.dateOfBirth;
    customerDetails.gender = personalDetails.gender;

    return customerDetails;
}

function addAddressDetails(customerDetails, addressDetails) {
    customerDetails.addressLine1 = addressDetails.streetAddress;
    customerDetails.addressLine2 = addressDetails.optionalAddress;
    customerDetails.suburbCity = addressDetails.locality;
    customerDetails.stateProvince = addressDetails.administrative_area_level_1;
    customerDetails.country = addressDetails.country;
    customerDetails.postalCode = addressDetails.postal_code;
    customerDetails.googlePlaceId = addressDetails.placeId;
    customerDetails.mobile = addressDetails.phoneNumber;
    customerDetails.timeZone = addressDetails.timeZone;

    return customerDetails;
}

function addGoalsDetails(customerDetails, goalsDetails) {

    customerDetails.nutritionalGoal = goalsDetails.nutritionalGoal;
    customerDetails.fitnessGoal = goalsDetails.fitnessGoal

    return customerDetails;
}

function addOrderDetails(customerDetails, orderDetails) {

    customerDetails.productCode = "";
    customerDetails.subscriptionCode = orderDetails.subscriptionOptionId;
    customerDetails.shippingOption = orderDetails.deliveryOptionId;
    customerDetails.recurringPaymentPriceId = orderDetails.recurringPaymentPriceId;
    customerDetails.oneTimePaymentPriceIds = orderDetails.oneTimePaymentPriceIds;
    customerDetails.selectedOrganisation = orderDetails.selectedOrganisation;

    return customerDetails;
}

function addPaymentDetails(customerDetails, paymentDetails) {
    customerDetails.paymentMethodId = paymentDetails.paymentMethodId;
    return customerDetails;
}

function addBarcodeDetails(customerDetails, barcode) {
    customerDetails.partnerName = barcode.partner;
    customerDetails.barcode = barcode.barcode;
    customerDetails.kitTypeName = barcode.kitTypeName;
    customerDetails.kitTypeId = barcode.kitTypeId;
    return customerDetails;
}

function addMedicationDetails(customerDetails, medicationDetails) {
    if (medicationDetails?.medications) {
        let medications = medicationDetails?.medications?.map(med => med?.data)
        var medicationDetail = { medications, isSmoker: medicationDetails?.isSmoker === "smoker" ? true : false }
        return customerDetails = {
            ...customerDetails,
            ...medicationDetail
        }
    }
}

function addFieldsToCustomerDetails(customerDetails, personalDetails, addressDetails, goalsDetails, orderDetails, paymentDetails, barcode, medicationDetails, promoCode) {
    if (personalDetails)
        customerDetails = addPersonalDetails(customerDetails, personalDetails);

    if (addressDetails)
        customerDetails = addAddressDetails(customerDetails, addressDetails);

    if (goalsDetails)
        customerDetails = addGoalsDetails(customerDetails, goalsDetails);

    if (orderDetails)
        customerDetails = addOrderDetails(customerDetails, orderDetails);

    if (paymentDetails) {
        customerDetails = addPaymentDetails(customerDetails, paymentDetails);
    }
    if (barcode) {
        customerDetails = addBarcodeDetails(customerDetails, barcode);
    }
    if (medicationDetails) {
        customerDetails = addMedicationDetails(customerDetails, medicationDetails);
    }
    if (promoCode) {
        customerDetails = { ...customerDetails, promoCode }
    }

    return customerDetails;
}

function appendSearchParametersToUrl(url, searchParams) {
    let returnObject = new URL(url);

    for (const [key, value] of Object.entries(searchParams)) {
        returnObject.searchParams.append(key, value);
    }

    return returnObject;
}

export async function updateCustomerDetails(customerDetails, personalDetails, barcode, addressDetails, goalsDetails, orderDetails, paymentDetails, medicationDetails) {
    customerDetails = addFieldsToCustomerDetails(customerDetails, personalDetails, addressDetails, goalsDetails, orderDetails, paymentDetails, barcode, medicationDetails);
    return callUpdateRegistrationDetails(customerDetails);
}

async function callUpdateRegistrationDetails(customerDetails) {
    overrideDateFormatForJsonStringify(customerDetails);
    return await API.graphql({ query: updateRegistrationDetails, variables: {request: customerDetails}});
}

export async function verifyPromoCode(queryParams) {
    return await API.graphql({ query: VerifyPromoCode, variables: {...queryParams}});
}

export async function commitCustomerDetails(customerDetails, personalDetails, addressDetails, goalsDetails, orderDetails, paymentDetails, barcode, medicationDetails, promoCode) {
    customerDetails = addFieldsToCustomerDetails(customerDetails, personalDetails, addressDetails, goalsDetails, orderDetails, paymentDetails, barcode, medicationDetails, promoCode);

    return callCommitRegistrationDetails(customerDetails);
}

async function callCommitRegistrationDetails(customerDetails) {
    overrideDateFormatForJsonStringify(customerDetails);
    return await API.graphql({ query: commitRegistrationDetails, variables: {request: customerDetails}});
}

function overrideDateFormatForJsonStringify(customerDetails) {
    // As JSON.stringify automatically changes the date to UTC time, which makes the date mismatch by 1 day.
    // Thus, we override it to make it return the date only when it is used in the payload of any fetch methods.
    customerDetails.dob.toJSON = function () {
        return GetFormattedDate(customerDetails.dob);
    }
}