import React from "react";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { GoalComponent } from "@modules/GoalComponent/GoalComponent";
import { GoalOption } from "@modules/Models/GoalOption";
import { StyledHeading, GoalGroupComponentWrapper } from './styles';

type GoalGroupComponentProps = {
  GoalOptions: GoalOption[],
  heading: string,
  handleChange: (value: string) => void,
  selectedValue: string
}

const GoalGroupComponent = (props: GoalGroupComponentProps) => {
  return (
    <GoalGroupComponentWrapper>
      <StyledHeading>
        {props.heading}
      </StyledHeading>
      <ToggleButtonGroup exclusive >
        {props.GoalOptions.map((goal: any) => {

        const onGoalClick = (goal: string) => {
          props.handleChange(goal);
        }

          return (
            <GoalComponent 
              goal ={goal.goal}
              key = {goal.key}
              imgSelected = {goal.imgSelected}
              imgNotSelected = {goal.imgNotSelected}
              imgAlt = {goal.imgAlt}
              selected = {props.selectedValue == goal.goal}
              onClick={onGoalClick}
            />
          );
        })}
      </ToggleButtonGroup>
    </GoalGroupComponentWrapper>
  );
};

export default GoalGroupComponent;
