/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const registerDevice = /* GraphQL */ `
  mutation RegisterDevice($device: DeviceInput!) {
    registerDevice(device: $device) {
      deviceId
      user {
        id
        userName
        firstName
        lastName
        email
        locale
        devices {
          deviceId
          user {
            id
            userName
            firstName
            lastName
            email
            locale
            devices {
              deviceId
              operatingSystem
              brand
              model
              deviceToken
            }
            feed {
              id
              cardType
              name
              title
              description
              articleUrl
              videoUrl
              imageUrl
              callToActionCaption
              callToActionUrl
              callToActionDeepLinkUrl
            }
            mealPlanDeliveryStatus
            fitnessPlanDeliveryStatus
            planSelections {
              dietaryRequirement
            }
            availableFitnessPlans {
              name
              title
              description
              dnaPoweredInsight
              geneticProfile
              isCurrentPlan
              isRecommended
              duration
              thumbnailImageUrl
              powerEnduranceRatio
            }
            availableMealPlans {
              name
              title
              description
              dnaPoweredInsight
              isCurrentPlan
              isRecommended
              carbsPct
              carbsGm
              carbsKCal
              proteinPct
              proteinGm
              proteinKCal
              fatPct
              fatGm
              fatKCal
              omega3Pct
              mealPlanImage
            }
            otherPlans {
              name
              title
              description
            }
            otherPlanByName {
              name
              title
              description
            }
            planCategories {
              name
              title
            }
            insightCategory {
              title
              description
              recommendationsTitle
              header
              category
            }
            insightCategories {
              title
              description
              recommendationsTitle
              header
              category
            }
            progress {
              metricName
              date
              value
            }
            profileInfo {
              measurementSystem
              heightInCm
              weightInKg
              weightGoal
              activityAtWork
              trainingFrequency
              nutritionalGoal
              fitnessGoal
            }
          }
          operatingSystem
          brand
          model
          deviceToken
        }
        feed {
          id
          cardType
          name
          title
          description
          articleUrl
          videoUrl
          imageUrl
          callToActionCaption
          callToActionUrl
          callToActionDeepLinkUrl
        }
        mealPlanDeliveryStatus
        fitnessPlanDeliveryStatus
        planSelections {
          currentMealPlan {
            name
            title
            description
            dnaPoweredInsight
            isCurrentPlan
            isRecommended
            carbsPct
            carbsGm
            carbsKCal
            proteinPct
            proteinGm
            proteinKCal
            fatPct
            fatGm
            fatKCal
            omega3Pct
            mealPlanImage
          }
          currentFitnessPlan {
            name
            title
            description
            dnaPoweredInsight
            geneticProfile
            isCurrentPlan
            isRecommended
            duration
            thumbnailImageUrl
            powerEnduranceRatio
          }
          dietaryRequirement
        }
        availableFitnessPlans {
          name
          title
          description
          dnaPoweredInsight
          geneticProfile
          isCurrentPlan
          isRecommended
          duration
          thumbnailImageUrl
          powerEnduranceRatio
        }
        availableMealPlans {
          name
          title
          description
          dnaPoweredInsight
          isCurrentPlan
          isRecommended
          carbsPct
          carbsGm
          carbsKCal
          proteinPct
          proteinGm
          proteinKCal
          fatPct
          fatGm
          fatKCal
          omega3Pct
          mealPlanImage
        }
        otherPlans {
          name
          title
          description
          sections {
            name
            title
            description
            components {
              ... on UserPlanGauge {
                name
                title
                description
                guageType
                value
                componentSequenceId
              }
              ... on UserPlanList {
                name
                title
                description
                componentSequenceId
              }
            }
          }
          options {
            backgroundColor
            highlightDarkestColor
            highlightDarkColor
            highlightColor
            highlightLightColor
            highlightLightestColor
            trackColor
          }
        }
        otherPlanByName {
          name
          title
          description
          sections {
            name
            title
            description
            components {
              ... on UserPlanGauge {
                name
                title
                description
                guageType
                value
                componentSequenceId
              }
              ... on UserPlanList {
                name
                title
                description
                componentSequenceId
              }
            }
          }
          options {
            backgroundColor
            highlightDarkestColor
            highlightDarkColor
            highlightColor
            highlightLightColor
            highlightLightestColor
            trackColor
          }
        }
        planCategories {
          name
          title
        }
        insightCategory {
          title
          description
          recommendationsTitle
          header
          about {
            title
            content
          }
          how {
            title
            content
            reference
            starRatingTable {
              stars
              label
              description
            }
          }
          geneResults {
            title
            content
            results {
              gene
              snp
              result
            }
          }
          category
          insights {
            common {
              title
              imageUrl
            }
            result
            priority
            howToWorkWith {
              title
            }
            evidences {
              title
            }
          }
        }
        insightCategories {
          title
          description
          recommendationsTitle
          header
          about {
            title
            content
          }
          how {
            title
            content
            reference
            starRatingTable {
              stars
              label
              description
            }
          }
          geneResults {
            title
            content
            results {
              gene
              snp
              result
            }
          }
          category
          insights {
            common {
              title
              imageUrl
            }
            result
            priority
            howToWorkWith {
              title
            }
            evidences {
              title
            }
          }
        }
        progress {
          metricName
          date
          value
        }
        profileInfo {
          measurementSystem
          heightInCm
          weightInKg
          weightGoal
          activityAtWork
          trainingFrequency
          nutritionalGoal
          fitnessGoal
        }
      }
      operatingSystem
      brand
      model
      deviceToken
    }
  }
`;
export const pushNotificationsOnLogin = /* GraphQL */ `
  mutation PushNotificationsOnLogin {
    pushNotificationsOnLogin
  }
`;
export const validateKitBarcode = /* GraphQL */ `
  mutation ValidateKitBarcode($barcode: String!) {
    validateKitBarcode(barcode: $barcode) {
      barcodeDetails {
        barcode
        validBarcode
        kitType
        consentText
      }
      success
      errorMessage
    }
  }
`;
export const redeemBarcode = /* GraphQL */ `
  mutation RedeemBarcode($request: UpdateBarcodeStatusRequest!) {
    redeemBarcode(request: $request) {
      message
      section
      statusCode
      ReturnId
    }
  }
`;
export const dismissNotification = /* GraphQL */ `
  mutation DismissNotification($notificationId: ID!) {
    dismissNotification(notificationId: $notificationId)
  }
`;
export const changePlans = /* GraphQL */ `
  mutation ChangePlans($request: ChangePlansRequest) {
    changePlans(request: $request) {
      success
      errorMessage
    }
  }
`;
export const generateAdditionalMeals = /* GraphQL */ `
  mutation GenerateAdditionalMeals {
    generateAdditionalMeals {
      success
      errorMessage
    }
  }
`;
export const restartMealPlan = /* GraphQL */ `
  mutation RestartMealPlan {
    restartMealPlan {
      success
      errorMessage
    }
  }
`;
export const restartFitnessPlan = /* GraphQL */ `
  mutation RestartFitnessPlan {
    restartFitnessPlan {
      success
      errorMessage
    }
  }
`;
export const changeDietaryRequirement = /* GraphQL */ `
  mutation ChangeDietaryRequirement($request: ChangeDietaryRequirementRequest) {
    changeDietaryRequirement(request: $request) {
      success
      errorMessage
    }
  }
`;
export const markPlanWorkoutComplete = /* GraphQL */ `
  mutation MarkPlanWorkoutComplete($request: PlanWorkoutCompleteRequest!) {
    markPlanWorkoutComplete(request: $request) {
      success
      errorMessage
    }
  }
`;
export const markFeedItemAsRead = /* GraphQL */ `
  mutation MarkFeedItemAsRead($feedId: ID!, $read: Boolean) {
    markFeedItemAsRead(feedId: $feedId, read: $read) {
      success
      errorMessage
    }
  }
`;
export const updateUserProgress = /* GraphQL */ `
  mutation UpdateUserProgress($request: UpdateUserProgressMetricValueRequest!) {
    updateUserProgress(request: $request) {
      metricName
      date
      value
    }
  }
`;
export const updateUserProfileInfo = /* GraphQL */ `
  mutation UpdateUserProfileInfo($request: UpdateUserProfileInfoRequest!) {
    updateUserProfileInfo(request: $request) {
      measurementSystem
      heightInCm
      weightInKg
      weightGoal
      activityAtWork
      trainingFrequency
      nutritionalGoal
      fitnessGoal
    }
  }
`;
export const updateRegistrationDetails = /* GraphQL */ `
  mutation UpdateRegistrationDetails($request: RegistrationUpdateRequest!) {
    updateRegistrationDetails(request: $request) {
      message
    }
  }
`;
export const commitRegistrationDetails = /* GraphQL */ `
  mutation CommitRegistrationDetails($request: RegistrationUpdateRequest!) {
    commitRegistrationDetails(request: $request) {
      message
      invoiceId
    }
  }
`;
