import { PrivatePages } from "@modules/Shared/Constants";
import { updateCustomerDetails, verifyPromoCode } from "@appSyncGateway/GraphqlClientAPIHelper";
import { push } from "connected-react-router";
import { CalculateTotal } from '@modules/utils/CalculateTotal';
import {validateDataToken} from '../Services/CustomerDetailsService'
import {systemUI} from "@modules/SystemUI/SystemUI";
import {myDNARouter} from '@modules/utils/myDNARouter';
import { APPCONSTANTS } from "@modules/Shared/Constants";

export const CustomerDetailsActions = {
    SET_USER_ROUTE: "setUserRoute",
    CAPTURE_ADDRESS: 'captureAddress',
    ADDRESS_CAPTURED: 'addressCaptured',
    ADDRESS_CAPTURED_FAILED: 'addressCapturedFailed',
    SET_GOALS: 'setGoals',
    GOALS_CAPTURED: 'goalsCaptured',
    GOALS_CAPTURE_FAILED: 'goalsCaptureFailed',
    PERSONAL_DETAILS: 'personalDetails',
    PERSONAL_DETAILS_CAPTURED: 'personalDetailsCaptured',
    PERSONAL_DETAILS_CAPTURE_FAILED: 'personalDetailsCaptureFailed',
    ORDER_DETAILS: 'orderDetails',
    ORDER_DETAILS_FETCHED: 'orderDetailsFetched',
    ORDER_DETAILS_FETCH_FAILED: 'orderDetailsFetchFailed',
    MEDICATION_DETAILS_CAPTURED: 'medicationDetailsCaptured',
    MEDICATION_DETAILS_FAILED: 'medicationDetailsFailed',
    PAYMENT_DETAILS: 'paymentDetails',
    PAYMENT_DETAILS_CAPTURED: 'paymentDetailsCaptured',
    PAYMENT_DETAILS_CAPTURED_FAILED: 'paymentDetailsCapturedFailed',
    BARCODE_DETAILS: 'barcodeDetails',
    BARCODE_DETAILS_CAPTURED: 'barcodeDetailsCaptured',
    FINALISE_REGISTRATION: 'finaliseRegistration',
    SET_PROMO_CODE: "setPromoCode",
    CLEAR_CUSTOMER_DETAILS: "clearCustomerDetails",
    VALIDATE_JWT_SET_BARCODE: "validateJwtSetBarcode",
    SET_SYSTEM_UI: "setSystemUI",
    SET_SUBSCRIPTION_FREIGHT_OPTIONS: "setSubscriptionFreightOptions"
};

export const setRoute = (props, userRoute) => dispatch => {
    dispatch({
        type: CustomerDetailsActions.SET_USER_ROUTE,
        error: null,
        payload: {
            route: userRoute
        }
    })
};

export const personalDetails = (props) => dispatch => {
    dispatch({
        type: CustomerDetailsActions.PERSONAL_DETAILS,
        error: null,
        payload: {
            auth: props.auth,
            stepNum: 2
        }
    })
};
export const personalDetailsCaptured = (props, userDetails) => dispatch => {
    dispatch({
        type: CustomerDetailsActions.PERSONAL_DETAILS_CAPTURED,
        error: null,
        payload: {
            auth: props.auth,
            userDetails: userDetails,
            stepNum: 2
        }
    });
};
export const barcodeDetails = (props) => dispatch => {
    dispatch({
        type: CustomerDetailsActions.BARCODE_DETAILS,
        error: null,
        payload: {
            auth: props.auth,
            barcode: {},
            stepNum: 3
        }
    })
};
export const barcodeDetailsCaptured = (props, barcodeDetails) => dispatch => {
    dispatch({
        type: CustomerDetailsActions.BARCODE_DETAILS_CAPTURED,
        error: null,
        payload: {
            auth: props?.auth,
            barcode: barcodeDetails
        }
    });
};


export const personalDetailsCaptureFailed = (props, error) => dispatch => {
    dispatch({
        type: CustomerDetailsActions.PERSONAL_DETAILS_CAPTURE_FAILED,
        error: error,
        payload: {
            auth: props?.auth,
            userDetails: {}
        }
    });
};

export const captureAddress = (props) => dispatch => {
    dispatch({
        type: CustomerDetailsActions.CAPTURE_ADDRESS,
        error: null,
        payload: {
            auth: props.auth,
            userDetails: props.userDetails,
            place: {},
            stepNum: 4
        }
    })
};

export const addressCaptured = (props, place) => dispatch => {
    dispatch({
        type: CustomerDetailsActions.ADDRESS_CAPTURED,
        error: null,
        payload: {
            auth: props.auth,
            userDetails: props.userDetails,
            place: place
        }
    })
};

export const addressCaptureFailed = (props, error) => dispatch => {
    dispatch({
        type: CustomerDetailsActions.ADDRESS_CAPTURED_FAILED,
        error: error,
        payload: {
            auth: props.auth,
            userDetails: props.userDetails,
            place: {}
        }
    })
};

export const setGoals = (props) => dispatch => {
    dispatch({
        type: CustomerDetailsActions.SET_GOALS,
        error: null,
        payload: {
            auth: props.auth,
            place: props.place,
            userDetails: props.userDetails,
            goals: {},
            stepNum: 5
        }
    })
};

export const goalsCaptured = (props, goals) => dispatch => {
    dispatch({
        type: CustomerDetailsActions.GOALS_CAPTURED,
        error: null,
        payload: {
            auth: props.auth,
            place: props.place,
            userDetails: props.userDetails,
            goals: goals
        }
    })
};

export const goalsCaptureFailed = (props, error) => dispatch => {
    dispatch({
        type: CustomerDetailsActions.GOALS_CAPTURE_FAILED,
        error: error,
        payload: {
            auth: props.auth,
            place: props.place,
            userDetails: props.userDetails,
            goals: {},
        }
    });
};

export const orderDetails = (props) => dispatch => {
    dispatch({
        type: CustomerDetailsActions.ORDER_DETAILS,
        error: null,
        payload: {
            auth: props.auth,
            place: props.place,
            userDetails: props.userDetails,
            goals: props.goals,
            payment: props.payment,
            orderDetails: null,
            stripePublishableKey: props.stripePublishableKey,
            stepNum: 6
        }
    })
};

export const orderDetailsCaptured = (props, orderDetails) => dispatch => {
    dispatch({
        type: CustomerDetailsActions.ORDER_DETAILS_FETCHED,
        error: null,
        payload: {
            auth: props.auth,
            place: props.place,
            userDetails: props.userDetails,
            goals: props.goals,
            payment: props.payment,
            orderDetails: orderDetails
        }
    })
};

export const medicationDetailsCaptured = (medicationDetails, error) => (dispatch, getState) => {
    const regStore = getState();
    let customerDetails = {
        emailAddress: regStore?.register?.auth?.user?.username,
        marketingOptIn: localStorage.getItem('marketingOptIn')
    };
    updateCustomerDetails(
        customerDetails,
        regStore?.customerDetails?.userDetails,
        regStore?.customerDetails?.barcode,
        regStore?.customerDetails?.place,
        null,
        null,
        null,
        medicationDetails
    ).then((data) => {
        dispatch({
            type: CustomerDetailsActions.MEDICATION_DETAILS_CAPTURED,
            error: error,
            payload: { medicationDetails }
        })
        myDNARouter(medicationDetails.props, APPCONSTANTS.NEXT_PAGE, "medicationDetails");
    })
    .catch((err) => {
        medicationDetailsFailed(err)
    })
};

export const medicationDetailsFailed = (error) => (dispatch, getState)=>{
    dispatch({
        type: CustomerDetailsActions.MEDICATION_DETAILS_FAILED,
        error: error
    })
}


export const orderDetailsCaptureFailed = (props, error) => dispatch => {
    dispatch({
        type: CustomerDetailsActions.ORDER_DETAILS_FETCH_FAILED,
        error: error,
        payload: {
            auth: props.auth,
            place: props.place,
            userDetails: props.userDetails,
            goals: props.goals,
            orderDetails: {}
        }
    })
};

export const paymentDetails = (props) => dispatch => {
    dispatch({
        type: CustomerDetailsActions.PAYMENT_DETAILS,
        error: null,
        payload: {
            auth: props.auth,
            place: props.place,
            userDetails: props.userDetails,
            goals: props.goals,
            orderDetails: props.orderDetails,
            payment: {},
            stepNum: 7
        }
    })
};

export const paymentDetailsCaptured = (props, payment) => dispatch => {
    dispatch({
        type: CustomerDetailsActions.PAYMENT_DETAILS_CAPTURED,
        error: null,
        payload: {
            auth: props.auth,
            place: props.place,
            userDetails: props.userDetails,
            goals: props.goals,
            orderDetails: props.orderDetails,
            payment: payment
        }
    })
};

export const paymentDetailsCaptureFailed = (props, error) => dispatch => {
    dispatch({
        type: CustomerDetailsActions.PAYMENT_DETAILS_CAPTURED_FAILED,
        error: error,
        payload: {
            auth: props.auth,
            place: props.place,
            userDetails: props.userDetails,
            goals: props.goals,
            orderDetails: props.orderDetails,
            payment: props.payment
        }
    })
};


export const finaliseRegistration = () => dispatch => {
    dispatch({
        type: CustomerDetailsActions.FINALISE_REGISTRATION,
        payload: {
            stepNum: PrivatePages.length
        }
    })
};

export const verifyAndSetPromoCode = (promoCodeDetails) => dispatch => {

    const promoCodeText = promoCodeDetails.promoCode;

    if (promoCodeText !== "") {

        verifyPromoCode(promoCodeDetails)
        .then(response => response)
        .then((data) => {
            let promoCodeData = data.data.verifyPromoCode;
            dispatch({
                type: CustomerDetailsActions.SET_PROMO_CODE,
                payload: {
                    promoCodeDetails: {
                        ...promoCodeData,
                        promoCode: promoCodeText
                    }
                }
            })
        })
        .catch((err) => {
            dispatch({
                type: CustomerDetailsActions.SET_PROMO_CODE,
                payload: {
                    promoCodeDetails: {
                        isValid: false
                    }
                }
            })
        })
    } else {
        dispatch({
            type: CustomerDetailsActions.SET_PROMO_CODE,
            payload: {
                promoCodeDetails: {
                    promoCode: "",
                    isValid: false
                }
            }
        })
    }
}

export const sendOrderDetailsToGTM = (subscriptionOption, promoCodeDetails, barcodeDisplayName, response) => dispatch  => {
    // Pass through all up-front costs and products sold to Google Analytics
    // Subscription option must be passed-through but doesn't have any up-front costs so will be passed with price of $0

    const totalRevenue = CalculateTotal(subscriptionOption, promoCodeDetails);
    const promoCodeDnaCollection = promoCodeDetails?.products?.find(x => x.productCode == subscriptionOption.collectionKitProductCode);

    window.dataLayer.push({ ecommerce: null });
    
    const tagData = {
        event: 'mydna.purchase',
        ecommerce: {
            purchase: {
                actionField: {
                    id: response?.invoiceId ?? '',
                    revenue: totalRevenue.toFixed(2)
                },
                products: [{
                    // Subscription costs 
                    name: barcodeDisplayName,
                    id: subscriptionOption.id,
                    price: '0.00',
                    quantity: 1
                }]
            }
        }
    };

    if (!!subscriptionOption.dnaCollectionCharges) {

        var dnaCollectionChargesPrice = !!promoCodeDnaCollection ? promoCodeDnaCollection.amount : subscriptionOption.dnaCollectionCharges;
        dnaCollectionChargesPrice = dnaCollectionChargesPrice.toFixed(2);

        tagData.ecommerce.purchase.products.push({
            name: "DNA Collection Charges",
            id: subscriptionOption.collectionKitProductCode,
            price: dnaCollectionChargesPrice,
            quantity: 1
        });
    }

    window.dataLayer.push({...tagData});
}

export const clearCustomerDetails = () => dispatch => {
    dispatch({
        type: CustomerDetailsActions.CLEAR_CUSTOMER_DETAILS,
        payload: {
            auth: {},
            place: {},
            userDetails: {},
            goals: {},
            orderDetails: {},
            payment: {}
        }
    })
};

export const validateJwtAndSetBarcode = (jwt, data) => async dispatch => {
    if(jwt && data) {
        let result = await validateDataToken(jwt, data.sub, data.aud);
        if (result) {
            dispatch({
                type: CustomerDetailsActions.VALIDATE_JWT_SET_BARCODE,
                payload: {
                    jwtPayload: data
                }
            });
        }
    }
};

export const setSystemUIByKitTypeId = (data) => async (dispatch) => {
  if (data) {
    dispatch({
      type: CustomerDetailsActions.SET_SYSTEM_UI,
      payload: {
        data: systemUI.find((each) => each.kitTypeIds.includes(data.aud)) || systemUI.find((each) => each.kitTypeIds.includes("000000"))
      }
    });
  }
};

export const setProductSubscriptionFreightOptions = (data) => async (dispatch) => {
    if (data) {
      dispatch({
        type: CustomerDetailsActions.SET_SUBSCRIPTION_FREIGHT_OPTIONS,
        payload: data
      });
    }
  };