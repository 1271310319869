import React, { useState } from "react";
import ComponentButtons from "@modules/ComponentButtons";
import { MyDnaDatePicker } from "@modules/MyDnaDatePicker";
import TextInput from "@modules/TextInput";
import { Formik, Form, getIn } from "formik";
import * as yup from "yup";
import { AllowedPatterns,ErrorMessages, APPCONSTANTS } from "@modules/Shared/Constants";
import {personalDetailsCaptured, personalDetailsCaptureFailed, personalDetails} from "@base/Actions/CustomerDetailsActions";
import RadioButtonsGroup from "@modules/Shared/RadioButtonGroup";
import { updateCustomerDetails } from "@appSyncGateway/GraphqlClientAPIHelper";
import { connect } from "react-redux";
import ComponentContainerHeader from "@modules/Header";
import { PrimaryTitles} from "@modules/Shared/TitleTexts";
import { push } from "connected-react-router";
import { GetFormattedDate } from "@utils/DateFormatter";
import { ErrorLabel } from '@modules/ErrorLabel';
import { VerifyAge as VerifyAgeService } from '@modules/utils/VerifyAge';
import { GenderOptions } from '@modules/Models/GenderOptions';
import ErrorMsgHandler from "@utils/ErrorMsgHandler";
import {myDNARouter} from '@modules/utils/myDNARouter';
import { 
    PersonalDetailsWrapper, 
    FieldBlock, 
    UnderAgeWarningTestBlock, 
    UnderAgeWarningHeader, 
    UnderAgeWarningContent,
    FieldWrapper
} from './styles'

// Strongly types input props
// Including any props that are being mapped from state
// Some of these can't be strongly typed until the rest of the application is as well
type PersonalDetailsProps = { 
    userDetails: any,
    dispatch: (props: any) => any,
    nextPage: any,
    auth: any,
    previousPage: any,
    barcode: any
}

// Strongly typed form state
type FormikData = {
    emailAddress?: string,
    firstName?: string,
    lastName?: string,
    dateOfBirth?: string,
    gender?: string,
    barcode?: string
}

const PersonalDetails = (props: PersonalDetailsProps) => {

    var initialFormikData: FormikData = { gender: 'Female'};
    var userEmail = props.auth.user.username; 

    if (props.userDetails && props.userDetails.firstName) {
        initialFormikData = {
            ...initialFormikData,
            ...props.userDetails,
            emailAddress: userEmail
        }
    }

    const [ errorMessage, setErrorMessage ] = useState<string | undefined>(undefined);
    const [ underAgeWarning, setUnderAgeWarning ] = useState<boolean>(false);
    const [ formikData, setFormikData ] = useState<FormikData>(initialFormikData);

    React.useEffect(() => {
        props.dispatch(personalDetails(props));
        if (initialFormikData.dateOfBirth) {
            VerifyAge(new Date(initialFormikData.dateOfBirth));
        }
    }, []);

    const validationSchema = yup.object({
        firstName: yup
            .string()
            .matches(AllowedPatterns.AlphabetNumberDotCommaCheck, ErrorMessages.AlphabetNumberDotCommaCheck)
            .required("First Name is Required")
            .max(40),
        lastName: yup
            .string()
            .matches(AllowedPatterns.AlphabetNumberDotCommaCheck, ErrorMessages.AlphabetNumberDotCommaCheck)
            .required("Last Name is Required")
            .max(40),
        gender: yup
            .string()
            .notRequired(), // This validation is causing problems when the form pre-fills. Due to default input value, it's impossible for this to be empty anyway.
        dateOfBirth: yup
            .string()
            .required("Date of Birth is Required")
            .nullable() //TODO: Should be localised
    });

    const VerifyAge = (value: Date) => {
        setUnderAgeWarning(VerifyAgeService(value));
    }

    const SubmitPersonalDetails = async (data: any) => {
        let customerDetails = {
            emailAddress: data.emailAddress,
            firstName: data.firstName,
            lastName: data.lastName,
            marketingOptIn: localStorage.getItem('marketingOptIn')
        };
        await updateCustomerDetails(customerDetails, data)
        .then(response => {
            props.dispatch(personalDetailsCaptured(props, data));
            myDNARouter(props, APPCONSTANTS.NEXT_PAGE, "personalDetails");
            return response;
        })
        .catch(err => {
            let errMsg = ErrorMsgHandler(err);
            setErrorMessage(errMsg);
            props.dispatch(personalDetailsCaptureFailed(errMsg));
        });
    }

    return (
        <PersonalDetailsWrapper>
            <ComponentContainerHeader
                primaryTitle={PrimaryTitles.PersonalDetails} 
                secondaryTitle={""}  
            />
            <Formik
                validateOnChange={true}
                initialValues={{
                    firstName: formikData.firstName,
                    lastName: formikData.lastName,
                    dateOfBirth: formikData.dateOfBirth,
                    gender: formikData.gender
                }}
                validationSchema={validationSchema}
                onSubmit={(data, { setSubmitting }) => {
                    if (!errorMessage) {
                        setSubmitting(true)
                        formikData.dateOfBirth = GetFormattedDate(data?.dateOfBirth)
                        SubmitPersonalDetails({emailAddress: userEmail, ...data});
                    }
                }}
                >
                {({setFieldValue, setFieldError, errors }) => {

                    const OnBirthdayInputChange = (date: Date) => {
                        if (date > new Date()) {
                            setErrorMessage("Date of Birth must not be after today's date");
                        } else {
                            setErrorMessage("");
                            setFieldValue("dateOfBirth", date);
                            setFormikData({ ...formikData, dateOfBirth: date.toString()});
                            VerifyAge(date);
                        }
                    }
                    
                    const HandleGenderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                        const value = event.target.value;
                        setFieldValue("gender", value);
                        setFormikData({ ...formikData, gender: value });
                    }

                    return (
                    <Form id="personal_details">
                        <>
                            <FieldBlock>
                                <FieldWrapper>
                                    <TextInput
                                        name="firstName"
                                        label="First Name *"
                                        maxLength="40"
                                        setFieldValue = {setFieldValue}
                                    />
                                </FieldWrapper>
                                <FieldWrapper>
                                    <TextInput
                                        name="lastName"
                                        label="Last Name *"
                                        maxLength="40"
                                        setFieldValue = {setFieldValue}
                                    />
                                </FieldWrapper>
                                <FieldWrapper>
                                    <MyDnaDatePicker
                                        name="dateOfBirth"
                                        label="Date of Birth *"
                                        defaultValue={formikData.dateOfBirth}
                                        onChange={OnBirthdayInputChange}
                                    />
                                </FieldWrapper>
                            {underAgeWarning &&
                            <UnderAgeWarningTestBlock>
                                <UnderAgeWarningHeader>It looks like you're under 18 years of age.</UnderAgeWarningHeader>
                                <UnderAgeWarningContent>By continuing you consent to doing so under the supervision of a parent or legal guardian. Read our <a href="https://www.mydna.life/terms-conditions/" target="_blank">terms & conditions</a> for more information</UnderAgeWarningContent>
                            </UnderAgeWarningTestBlock>}
                                <RadioButtonsGroup
                                    name = "gender"
                                    label = "Gender *"
                                    options = {GenderOptions}
                                    value = {formikData.gender}
                                    onChange = {HandleGenderChange}
                                />
                            </FieldBlock>
                        </>
                        {errorMessage && 
                        <>
                            <ErrorLabel>{errorMessage}</ErrorLabel>
                        </>}
                        <ComponentButtons 
                            dispatch = {props.dispatch} 
                            previousPage = {props.previousPage} 
                            currentPage="personalDetails"
                            singleButton = {false}
                            barcode = {props.barcode}
                        />
                    </Form>
                )}}
            </Formik>
        </PersonalDetailsWrapper>
    );
}

const mapStateToProps = (state: any) => {
    return {
        userDetails: state.customerDetails.userDetails,
        customerDetails: state.customerDetails,
        register: state.register,
        router: state.router
    }
};

export default connect(mapStateToProps)(PersonalDetails)
