import LocalizedStrings from 'react-localization';

export const PlanDisplayText = new LocalizedStrings({
    en: {
        Level2Heading:'DAY FREE MEMBERSHIP',
        SubscriptionTitle:'Monthly Membership',
        MonthlySubscriptionTitle:'Month Membership Program',
        MonthlyPlan: 'Monthly Plan',
        MonthMembership: 'Month Membership',
        VIPSubscriptionTitle:'VIP Membership',
        DNACollectionKitText:'DNA Lab Analysis',
        DNACollectionKitTextPartner:'Collection kit purchased',
        FreeDNACollectionKitText:'Free DNA Lab Analysis',
        DNACollectionKitSuppliedText:'DNA Lab Analysis supplied',
        DNACollectionKitNotRequiredText:'DNA Lab Analysis not required',
        FreeMembershipDurationText:'No membership payments',
        MonthlyFeeWaivedText: 'Monthly fee waived'
    }
});
