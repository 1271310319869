export const CountryList={
    "coutryList": [
      {
        "name": "Afghanistan",
        "isO2": "AF",
        "isO3": "AFG",
        "phoneCode": "93"
      },
      {
        "name": "Åland Islands",
        "isO2": "AX",
        "isO3": "ALA",
        "phoneCode": null
      },
      {
        "name": "Albania",
        "isO2": "AL",
        "isO3": "ALB",
        "phoneCode": "355"
      },
      {
        "name": "Algeria",
        "isO2": "DZ",
        "isO3": "DZA",
        "phoneCode": "213"
      },
      {
        "name": "American Samoa",
        "isO2": "AS",
        "isO3": "ASM",
        "phoneCode": "1"
      },
      {
        "name": "Andorra",
        "isO2": "AD",
        "isO3": "AND",
        "phoneCode": "376"
      },
      {
        "name": "Angola",
        "isO2": "AO",
        "isO3": "AGO",
        "phoneCode": "244"
      },
      {
        "name": "Anguilla",
        "isO2": "AI",
        "isO3": "AIA",
        "phoneCode": "1"
      },
      {
        "name": "Antarctica",
        "isO2": "AQ",
        "isO3": "ATA",
        "phoneCode": "672"
      },
      {
        "name": "Antigua and Barbuda",
        "isO2": "AG",
        "isO3": "ATG",
        "phoneCode": "1"
      },
      {
        "name": "Argentina",
        "isO2": "AR",
        "isO3": "ARG",
        "phoneCode": "54"
      },
      {
        "name": "Armenia",
        "isO2": "AM",
        "isO3": "ARM",
        "phoneCode": "374"
      },
      {
        "name": "Aruba",
        "isO2": "AW",
        "isO3": "ABW",
        "phoneCode": "297"
      },
      {
        "name": "Australia",
        "isO2": "AU",
        "isO3": "AUS",
        "phoneCode": "61"
      },
      {
        "name": "Austria",
        "isO2": "AT",
        "isO3": "AUT",
        "phoneCode": "43"
      },
      {
        "name": "Azerbaijan",
        "isO2": "AZ",
        "isO3": "AZE",
        "phoneCode": "994"
      },
      {
        "name": "Bahamas",
        "isO2": "BS",
        "isO3": "BHS",
        "phoneCode": "1"
      },
      {
        "name": "Bahrain",
        "isO2": "BH",
        "isO3": "BHR",
        "phoneCode": "973"
      },
      {
        "name": "Bangladesh",
        "isO2": "BD",
        "isO3": "BGD",
        "phoneCode": "880"
      },
      {
        "name": "Barbados",
        "isO2": "BB",
        "isO3": "BRB",
        "phoneCode": "1"
      },
      {
        "name": "Belarus",
        "isO2": "BY",
        "isO3": "BLR",
        "phoneCode": "375"
      },
      {
        "name": "Belgium",
        "isO2": "BE",
        "isO3": "BEL",
        "phoneCode": "32"
      },
      {
        "name": "Belize",
        "isO2": "BZ",
        "isO3": "BLZ",
        "phoneCode": "501"
      },
      {
        "name": "Benin",
        "isO2": "BJ",
        "isO3": "BEN",
        "phoneCode": "229"
      },
      {
        "name": "Bermuda",
        "isO2": "BM",
        "isO3": "BMU",
        "phoneCode": "1"
      },
      {
        "name": "Bhutan",
        "isO2": "BT",
        "isO3": "BTN",
        "phoneCode": "975"
      },
      {
        "name": "Bolivia (Plurinational State of)",
        "isO2": "BO",
        "isO3": "BOL",
        "phoneCode": "591"
      },
      {
        "name": "Bonaire, Sint Eustatius and Saba",
        "isO2": "BQ",
        "isO3": "BES",
        "phoneCode": null
      },
      {
        "name": "Bosnia and Herzegovina",
        "isO2": "BA",
        "isO3": "BIH",
        "phoneCode": "387"
      },
      {
        "name": "Botswana",
        "isO2": "BW",
        "isO3": "BWA",
        "phoneCode": "267"
      },
      {
        "name": "Bouvet Island",
        "isO2": "BV",
        "isO3": "BVT",
        "phoneCode": null
      },
      {
        "name": "Brazil",
        "isO2": "BR",
        "isO3": "BRA",
        "phoneCode": "55"
      },
      {
        "name": "British Indian Ocean Territory",
        "isO2": "IO",
        "isO3": "IOT",
        "phoneCode": "246"
      },
      {
        "name": "Brunei Darussalam",
        "isO2": "BN",
        "isO3": "BRN",
        "phoneCode": "673"
      },
      {
        "name": "Bulgaria",
        "isO2": "BG",
        "isO3": "BGR",
        "phoneCode": "359"
      },
      {
        "name": "Burkina Faso",
        "isO2": "BF",
        "isO3": "BFA",
        "phoneCode": "226"
      },
      {
        "name": "Burundi",
        "isO2": "BI",
        "isO3": "BDI",
        "phoneCode": "257"
      },
      {
        "name": "Republic of Cabo Verde",
        "isO2": "CV",
        "isO3": "CPV",
        "phoneCode": "238"
      },
      {
        "name": "Cambodia",
        "isO2": "KH",
        "isO3": "KHM",
        "phoneCode": "855"
      },
      {
        "name": "Cameroon",
        "isO2": "CM",
        "isO3": "CMR",
        "phoneCode": "237"
      },
      {
        "name": "Canada",
        "isO2": "CA",
        "isO3": "CAN",
        "phoneCode": "1"
      },
      {
        "name": "Cayman Islands",
        "isO2": "KY",
        "isO3": "CYM",
        "phoneCode": "1"
      },
      {
        "name": "Central African Republic",
        "isO2": "CF",
        "isO3": "CAF",
        "phoneCode": "236"
      },
      {
        "name": "Chad",
        "isO2": "TD",
        "isO3": "TCD",
        "phoneCode": "235"
      },
      {
        "name": "Chile",
        "isO2": "CL",
        "isO3": "CHL",
        "phoneCode": "56"
      },
      {
        "name": "China",
        "isO2": "CN",
        "isO3": "CHN",
        "phoneCode": "86"
      },
      {
        "name": "Christmas Island",
        "isO2": "CX",
        "isO3": "CXR",
        "phoneCode": "61"
      },
      {
        "name": "Cocos (Keeling) Islands",
        "isO2": "CC",
        "isO3": "CCK",
        "phoneCode": "61"
      },
      {
        "name": "Colombia",
        "isO2": "CO",
        "isO3": "COL",
        "phoneCode": "57"
      },
      {
        "name": "Comoros",
        "isO2": "KM",
        "isO3": "COM",
        "phoneCode": "269"
      },
      {
        "name": "Congo",
        "isO2": "CG",
        "isO3": "COG",
        "phoneCode": "242"
      },
      {
        "name": "Congo (Democratic Republic of the)",
        "isO2": "CD",
        "isO3": "COD",
        "phoneCode": "243"
      },
      {
        "name": "Cook Islands",
        "isO2": "CK",
        "isO3": "COK",
        "phoneCode": "682"
      },
      {
        "name": "Costa Rica",
        "isO2": "CR",
        "isO3": "CRI",
        "phoneCode": "506"
      },
      {
        "name": "Côte d'Ivoire",
        "isO2": "CI",
        "isO3": "CIV",
        "phoneCode": "225"
      },
      {
        "name": "Croatia",
        "isO2": "HR",
        "isO3": "HRV",
        "phoneCode": "385"
      },
      {
        "name": "Cuba",
        "isO2": "CU",
        "isO3": "CUB",
        "phoneCode": "53"
      },
      {
        "name": "Curaçao",
        "isO2": "CW",
        "isO3": "CUW",
        "phoneCode": "599"
      },
      {
        "name": "Cyprus",
        "isO2": "CY",
        "isO3": "CYP",
        "phoneCode": "357"
      },
      {
        "name": "Czech Republic",
        "isO2": "CZ",
        "isO3": "CZE",
        "phoneCode": "420"
      },
      {
        "name": "Denmark",
        "isO2": "DK",
        "isO3": "DNK",
        "phoneCode": "45"
      },
      {
        "name": "Djibouti",
        "isO2": "DJ",
        "isO3": "DJI",
        "phoneCode": "253"
      },
      {
        "name": "Dominica",
        "isO2": "DM",
        "isO3": "DMA",
        "phoneCode": "1"
      },
      {
        "name": "Dominican Republic",
        "isO2": "DO",
        "isO3": "DOM",
        "phoneCode": "1"
      },
      {
        "name": "Ecuador",
        "isO2": "EC",
        "isO3": "ECU",
        "phoneCode": "593"
      },
      {
        "name": "Egypt",
        "isO2": "EG",
        "isO3": "EGY",
        "phoneCode": "20"
      },
      {
        "name": "El Salvador",
        "isO2": "SV",
        "isO3": "SLV",
        "phoneCode": "503"
      },
      {
        "name": "England",
        "isO2": "GB",
        "isO3": "ENG",
        "phoneCode": null //TODO:What is the phone number
      },
      {
        "name": "Equatorial Guinea",
        "isO2": "GQ",
        "isO3": "GNQ",
        "phoneCode": "240"
      },
      {
        "name": "Eritrea",
        "isO2": "ER",
        "isO3": "ERI",
        "phoneCode": "291"
      },
      {
        "name": "Estonia",
        "isO2": "EE",
        "isO3": "EST",
        "phoneCode": "372"
      },
      {
        "name": "Ethiopia",
        "isO2": "ET",
        "isO3": "ETH",
        "phoneCode": "251"
      },
      {
        "name": "Falkland Islands (Malvinas)",
        "isO2": "FK",
        "isO3": "FLK",
        "phoneCode": "500"
      },
      {
        "name": "Faroe Islands",
        "isO2": "FO",
        "isO3": "FRO",
        "phoneCode": "298"
      },
      {
        "name": "Fiji",
        "isO2": "FJ",
        "isO3": "FJI",
        "phoneCode": "679"
      },
      {
        "name": "Finland",
        "isO2": "FI",
        "isO3": "FIN",
        "phoneCode": "358"
      },
      {
        "name": "France",
        "isO2": "FR",
        "isO3": "FRA",
        "phoneCode": "33"
      },
      {
        "name": "French Guiana",
        "isO2": "GF",
        "isO3": "GUF",
        "phoneCode": null
      },
      {
        "name": "French Polynesia",
        "isO2": "PF",
        "isO3": "PYF",
        "phoneCode": "689"
      },
      {
        "name": "French Southern Territories",
        "isO2": "TF",
        "isO3": "ATF",
        "phoneCode": null
      },
      {
        "name": "Gabon",
        "isO2": "GA",
        "isO3": "GAB",
        "phoneCode": "241"
      },
      {
        "name": "Gambia",
        "isO2": "GM",
        "isO3": "GMB",
        "phoneCode": "220"
      },
      {
        "name": "Georgia",
        "isO2": "GE",
        "isO3": "GEO",
        "phoneCode": "995"
      },
      {
        "name": "Germany",
        "isO2": "DE",
        "isO3": "DEU",
        "phoneCode": "49"
      },
      {
        "name": "Ghana",
        "isO2": "GH",
        "isO3": "GHA",
        "phoneCode": "233"
      },
      {
        "name": "Gibraltar",
        "isO2": "GI",
        "isO3": "GIB",
        "phoneCode": "350"
      },
      {
        "name": "Greece",
        "isO2": "GR",
        "isO3": "GRC",
        "phoneCode": "30"
      },
      {
        "name": "Greenland",
        "isO2": "GL",
        "isO3": "GRL",
        "phoneCode": "299"
      },
      {
        "name": "Grenada",
        "isO2": "GD",
        "isO3": "GRD",
        "phoneCode": "1"
      },
      {
        "name": "Guadeloupe",
        "isO2": "GP",
        "isO3": "GLP",
        "phoneCode": null
      },
      {
        "name": "Guam",
        "isO2": "GU",
        "isO3": "GUM",
        "phoneCode": "1"
      },
      {
        "name": "Guatemala",
        "isO2": "GT",
        "isO3": "GTM",
        "phoneCode": "502"
      },
      {
        "name": "Guernsey",
        "isO2": "GG",
        "isO3": "GGY",
        "phoneCode": "44"
      },
      {
        "name": "Guinea",
        "isO2": "GN",
        "isO3": "GIN",
        "phoneCode": "224"
      },
      {
        "name": "Guinea-Bissau",
        "isO2": "GW",
        "isO3": "GNB",
        "phoneCode": "245"
      },
      {
        "name": "Guyana",
        "isO2": "GY",
        "isO3": "GUY",
        "phoneCode": "592"
      },
      {
        "name": "Haiti",
        "isO2": "HT",
        "isO3": "HTI",
        "phoneCode": "509"
      },
      {
        "name": "Heard Island and McDonald Islands",
        "isO2": "HM",
        "isO3": "HMD",
        "phoneCode": null
      },
      {
        "name": "Holy See",
        "isO2": "VA",
        "isO3": "VAT",
        "phoneCode": "379"
      },
      {
        "name": "Honduras",
        "isO2": "HN",
        "isO3": "HND",
        "phoneCode": "504"
      },
      {
        "name": "Hong Kong",
        "isO2": "HK",
        "isO3": "HKG",
        "phoneCode": "852"
      },
      {
        "name": "Hungary",
        "isO2": "HU",
        "isO3": "HUN",
        "phoneCode": "36"
      },
      {
        "name": "Iceland",
        "isO2": "IS",
        "isO3": "ISL",
        "phoneCode": "354"
      },
      {
        "name": "India",
        "isO2": "IN",
        "isO3": "IND",
        "phoneCode": "91"
      },
      {
        "name": "Indonesia",
        "isO2": "ID",
        "isO3": "IDN",
        "phoneCode": "62"
      },
      {
        "name": "Iran (Islamic Republic of)",
        "isO2": "IR",
        "isO3": "IRN",
        "phoneCode": "98"
      },
      {
        "name": "Iraq",
        "isO2": "IQ",
        "isO3": "IRQ",
        "phoneCode": "964"
      },
      {
        "name": "Ireland",
        "isO2": "IE",
        "isO3": "IRL",
        "phoneCode": "353"
      },
      {
        "name": "Isle of Man",
        "isO2": "IM",
        "isO3": "IMN",
        "phoneCode": "44"
      },
      {
        "name": "Israel",
        "isO2": "IL",
        "isO3": "ISR",
        "phoneCode": "972"
      },
      {
        "name": "Italy",
        "isO2": "IT",
        "isO3": "ITA",
        "phoneCode": "39"
      },
      {
        "name": "Jamaica",
        "isO2": "JM",
        "isO3": "JAM",
        "phoneCode": "1"
      },
      {
        "name": "Japan",
        "isO2": "JP",
        "isO3": "JPN",
        "phoneCode": "81"
      },
      {
        "name": "Jersey",
        "isO2": "JE",
        "isO3": "JEY",
        "phoneCode": "44"
      },
      {
        "name": "Jordan",
        "isO2": "JO",
        "isO3": "JOR",
        "phoneCode": "962"
      },
      {
        "name": "Kazakhstan",
        "isO2": "KZ",
        "isO3": "KAZ",
        "phoneCode": "7"
      },
      {
        "name": "Kenya",
        "isO2": "KE",
        "isO3": "KEN",
        "phoneCode": "254"
      },
      {
        "name": "Kiribati",
        "isO2": "KI",
        "isO3": "KIR",
        "phoneCode": "686"
      },
      {
        "name": "Korea (Democratic People's Republic of)",
        "isO2": "KP",
        "isO3": "PRK",
        "phoneCode": "850"
      },
      {
        "name": "Korea (Republic of)",
        "isO2": "KR",
        "isO3": "KOR",
        "phoneCode": "82"
      },
      {
        "name": "Kosovo",
        "isO2": "XK",
        "isO3": "XKX",
        "phoneCode": null
      },
      {
        "name": "Kuwait",
        "isO2": "KW",
        "isO3": "KWT",
        "phoneCode": "965"
      },
      {
        "name": "Kyrgyzstan",
        "isO2": "KG",
        "isO3": "KGZ",
        "phoneCode": "996"
      },
      {
        "name": "Lao People's Democratic Republic",
        "isO2": "LA",
        "isO3": "LAO",
        "phoneCode": "856"
      },
      {
        "name": "Latvia",
        "isO2": "LV",
        "isO3": "LVA",
        "phoneCode": "371"
      },
      {
        "name": "Lebanon",
        "isO2": "LB",
        "isO3": "LBN",
        "phoneCode": "961"
      },
      {
        "name": "Lesotho",
        "isO2": "LS",
        "isO3": "LSO",
        "phoneCode": "266"
      },
      {
        "name": "Liberia",
        "isO2": "LR",
        "isO3": "LBR",
        "phoneCode": "231"
      },
      {
        "name": "Libya",
        "isO2": "LY",
        "isO3": "LBY",
        "phoneCode": "218"
      },
      {
        "name": "Liechtenstein",
        "isO2": "LI",
        "isO3": "LIE",
        "phoneCode": "423"
      },
      {
        "name": "Lithuania",
        "isO2": "LT",
        "isO3": "LTU",
        "phoneCode": "370"
      },
      {
        "name": "Luxembourg",
        "isO2": "LU",
        "isO3": "LUX",
        "phoneCode": "352"
      },
      {
        "name": "Macao",
        "isO2": "MO",
        "isO3": "MAC",
        "phoneCode": "853"
      },
      {
        "name": "Macedonia (the former Yugoslav Republic of)",
        "isO2": "MK",
        "isO3": "MKD",
        "phoneCode": "389"
      },
      {
        "name": "Madagascar",
        "isO2": "MG",
        "isO3": "MDG",
        "phoneCode": "261"
      },
      {
        "name": "Malawi",
        "isO2": "MW",
        "isO3": "MWI",
        "phoneCode": "265"
      },
      {
        "name": "Malaysia",
        "isO2": "MY",
        "isO3": "MYS",
        "phoneCode": "60"
      },
      {
        "name": "Maldives",
        "isO2": "MV",
        "isO3": "MDV",
        "phoneCode": "960"
      },
      {
        "name": "Mali",
        "isO2": "ML",
        "isO3": "MLI",
        "phoneCode": "223"
      },
      {
        "name": "Malta",
        "isO2": "MT",
        "isO3": "MLT",
        "phoneCode": "356"
      },
      {
        "name": "Marshall Islands",
        "isO2": "MH",
        "isO3": "MHL",
        "phoneCode": "692"
      },
      {
        "name": "Martinique",
        "isO2": "MQ",
        "isO3": "MTQ",
        "phoneCode": null
      },
      {
        "name": "Mauritania",
        "isO2": "MR",
        "isO3": "MRT",
        "phoneCode": "222"
      },
      {
        "name": "Mauritius",
        "isO2": "MU",
        "isO3": "MUS",
        "phoneCode": "230"
      },
      {
        "name": "Mayotte",
        "isO2": "YT",
        "isO3": "MYT",
        "phoneCode": "262"
      },
      {
        "name": "Mexico",
        "isO2": "MX",
        "isO3": "MEX",
        "phoneCode": "52"
      },
      {
        "name": "Micronesia (Federated States of)",
        "isO2": "FM",
        "isO3": "FSM",
        "phoneCode": "691"
      },
      {
        "name": "Moldova (Republic of)",
        "isO2": "MD",
        "isO3": "MDA",
        "phoneCode": "373"
      },
      {
        "name": "Monaco",
        "isO2": "MC",
        "isO3": "MCO",
        "phoneCode": "377"
      },
      {
        "name": "Mongolia",
        "isO2": "MN",
        "isO3": "MNG",
        "phoneCode": "976"
      },
      {
        "name": "Montenegro",
        "isO2": "ME",
        "isO3": "MNE",
        "phoneCode": "382"
      },
      {
        "name": "Montserrat",
        "isO2": "MS",
        "isO3": "MSR",
        "phoneCode": "1"
      },
      {
        "name": "Morocco",
        "isO2": "MA",
        "isO3": "MAR",
        "phoneCode": "212"
      },
      {
        "name": "Mozambique",
        "isO2": "MZ",
        "isO3": "MOZ",
        "phoneCode": "258"
      },
      {
        "name": "Myanmar",
        "isO2": "MM",
        "isO3": "MMR",
        "phoneCode": "95"
      },
      {
        "name": "Namibia",
        "isO2": "NA",
        "isO3": "NAM",
        "phoneCode": "264"
      },
      {
        "name": "Nauru",
        "isO2": "NR",
        "isO3": "NRU",
        "phoneCode": "674"
      },
      {
        "name": "Nepal",
        "isO2": "NP",
        "isO3": "NPL",
        "phoneCode": "977"
      },
      {
        "name": "Netherlands",
        "isO2": "NL",
        "isO3": "NLD",
        "phoneCode": "31"
      },
      {
        "name": "New Caledonia",
        "isO2": "NC",
        "isO3": "NCL",
        "phoneCode": "687"
      },
      {
        "name": "New Zealand",
        "isO2": "NZ",
        "isO3": "NZL",
        "phoneCode": "64"
      },
      {
        "name": "Nicaragua",
        "isO2": "NI",
        "isO3": "NIC",
        "phoneCode": "505"
      },
      {
        "name": "Niger",
        "isO2": "NE",
        "isO3": "NER",
        "phoneCode": "227"
      },
      {
        "name": "Nigeria",
        "isO2": "NG",
        "isO3": "NGA",
        "phoneCode": "234"
      },
      {
        "name": "Niue",
        "isO2": "NU",
        "isO3": "NIU",
        "phoneCode": "683"
      },
      {
        "name": "Norfolk Island",
        "isO2": "NF",
        "isO3": "NFK",
        "phoneCode": null
      },
      {
        "name": "Northern Ireland",
        "isO2": "GB",
        "isO3": "NIR",
        "phoneCode": null
      },
      {
        "name": "Northern Mariana Islands",
        "isO2": "MP",
        "isO3": "MNP",
        "phoneCode": "1"
      },
      {
        "name": "Norway",
        "isO2": "NO",
        "isO3": "NOR",
        "phoneCode": "47"
      },
      {
        "name": "Oman",
        "isO2": "OM",
        "isO3": "OMN",
        "phoneCode": "968"
      },
      {
        "name": "Pakistan",
        "isO2": "PK",
        "isO3": "PAK",
        "phoneCode": "92"
      },
      {
        "name": "Palau",
        "isO2": "PW",
        "isO3": "PLW",
        "phoneCode": "680"
      },
      {
        "name": "Palestine, State of",
        "isO2": "PS",
        "isO3": "PSE",
        "phoneCode": "970"
      },
      {
        "name": "Panama",
        "isO2": "PA",
        "isO3": "PAN",
        "phoneCode": "507"
      },
      {
        "name": "Papua New Guinea",
        "isO2": "PG",
        "isO3": "PNG",
        "phoneCode": "675"
      },
      {
        "name": "Paraguay",
        "isO2": "PY",
        "isO3": "PRY",
        "phoneCode": "595"
      },
      {
        "name": "Peru",
        "isO2": "PE",
        "isO3": "PER",
        "phoneCode": "51"
      },
      {
        "name": "Philippines",
        "isO2": "PH",
        "isO3": "PHL",
        "phoneCode": "63"
      },
      {
        "name": "Pitcairn",
        "isO2": "PN",
        "isO3": "PCN",
        "phoneCode": "64"
      },
      {
        "name": "Poland",
        "isO2": "PL",
        "isO3": "POL",
        "phoneCode": "48"
      },
      {
        "name": "Portugal",
        "isO2": "PT",
        "isO3": "PRT",
        "phoneCode": "351"
      },
      {
        "name": "Puerto Rico",
        "isO2": "PR",
        "isO3": "PRI",
        "phoneCode": "1"
      },
      {
        "name": "Qatar",
        "isO2": "QA",
        "isO3": "QAT",
        "phoneCode": "974"
      },
      {
        "name": "Réunion",
        "isO2": "RE",
        "isO3": "REU",
        "phoneCode": "262"
      },
      {
        "name": "Romania",
        "isO2": "RO",
        "isO3": "ROU",
        "phoneCode": "40"
      },
      {
        "name": "Russian Federation",
        "isO2": "RU",
        "isO3": "RUS",
        "phoneCode": "7"
      },
      {
        "name": "Rwanda",
        "isO2": "RW",
        "isO3": "RWA",
        "phoneCode": "250"
      },
      {
        "name": "Saint Barthélemy",
        "isO2": "BL",
        "isO3": "BLM",
        "phoneCode": "590"
      },
      {
        "name": "Saint Helena, Ascension and Tristan da Cunha",
        "isO2": "SH",
        "isO3": "SHN",
        "phoneCode": "290"
      },
      {
        "name": "Saint Kitts and Nevis",
        "isO2": "KN",
        "isO3": "KNA",
        "phoneCode": "1"
      },
      {
        "name": "Saint Lucia",
        "isO2": "LC",
        "isO3": "LCA",
        "phoneCode": "1"
      },
      {
        "name": "Saint Martin (French part)",
        "isO2": "MF",
        "isO3": "MAF",
        "phoneCode": "590"
      },
      {
        "name": "Saint Pierre and Miquelon",
        "isO2": "PM",
        "isO3": "SPM",
        "phoneCode": "508"
      },
      {
        "name": "Saint Vincent and the Grenadines",
        "isO2": "VC",
        "isO3": "VCT",
        "phoneCode": "1"
      },
      {
        "name": "Samoa",
        "isO2": "WS",
        "isO3": "WSM",
        "phoneCode": "685"
      },
      {
        "name": "San Marino",
        "isO2": "SM",
        "isO3": "SMR",
        "phoneCode": "378"
      },
      {
        "name": "Sao Tome and Principe",
        "isO2": "ST",
        "isO3": "STP",
        "phoneCode": "239"
      },
      {
        "name": "Saudi Arabia",
        "isO2": "SA",
        "isO3": "SAU",
        "phoneCode": "966"
      },
      {
        "name": "Scotland",
        "isO2": "GB",
        "isO3": "SCT",
        "phoneCode": null
      },
      {
        "name": "Senegal",
        "isO2": "SN",
        "isO3": "SEN",
        "phoneCode": "221"
      },
      {
        "name": "Serbia",
        "isO2": "RS",
        "isO3": "SRB",
        "phoneCode": "381"
      },
      {
        "name": "Seychelles",
        "isO2": "SC",
        "isO3": "SYC",
        "phoneCode": "248"
      },
      {
        "name": "Sierra Leone",
        "isO2": "SL",
        "isO3": "SLE",
        "phoneCode": "232"
      },
      {
        "name": "Singapore",
        "isO2": "SG",
        "isO3": "SGP",
        "phoneCode": "65"
      },
      {
        "name": "Sint Maarten (Dutch part)",
        "isO2": "SX",
        "isO3": "SXM",
        "phoneCode": "1"
      },
      {
        "name": "Slovakia",
        "isO2": "SK",
        "isO3": "SVK",
        "phoneCode": "421"
      },
      {
        "name": "Slovenia",
        "isO2": "SI",
        "isO3": "SVN",
        "phoneCode": "386"
      },
      {
        "name": "Solomon Islands",
        "isO2": "SB",
        "isO3": "SLB",
        "phoneCode": "677"
      },
      {
        "name": "Somalia",
        "isO2": "SO",
        "isO3": "SOM",
        "phoneCode": "252"
      },
      {
        "name": "South Africa",
        "isO2": "ZA",
        "isO3": "ZAF",
        "phoneCode": "27"
      },
      {
        "name": "South Georgia and the South Sandwich Islands",
        "isO2": "GS",
        "isO3": "SGS",
        "phoneCode": null
      },
      {
        "name": "South Sudan",
        "isO2": "SS",
        "isO3": "SSD",
        "phoneCode": "211"
      },
      {
        "name": "Spain",
        "isO2": "ES",
        "isO3": "ESP",
        "phoneCode": "34"
      },
      {
        "name": "Sri Lanka",
        "isO2": "LK",
        "isO3": "LKA",
        "phoneCode": "94"
      },
      {
        "name": "Sudan",
        "isO2": "SD",
        "isO3": "SDN",
        "phoneCode": "249"
      },
      {
        "name": "Suriname",
        "isO2": "SR",
        "isO3": "SUR",
        "phoneCode": "597"
      },
      {
        "name": "Svalbard and Jan Mayen",
        "isO2": "SJ",
        "isO3": "SJM",
        "phoneCode": "47"
      },
      {
        "name": "Swaziland",
        "isO2": "SZ",
        "isO3": "SWZ",
        "phoneCode": "268"
      },
      {
        "name": "Sweden",
        "isO2": "SE",
        "isO3": "SWE",
        "phoneCode": "46"
      },
      {
        "name": "Switzerland",
        "isO2": "CH",
        "isO3": "CHE",
        "phoneCode": "41"
      },
      {
        "name": "Syrian Arab Republic",
        "isO2": "SY",
        "isO3": "SYR",
        "phoneCode": "963"
      },
      {
        "name": "Taiwan, Province of China",
        "isO2": "TW",
        "isO3": "TWN",
        "phoneCode": "886"
      },
      {
        "name": "Tajikistan",
        "isO2": "TJ",
        "isO3": "TJK",
        "phoneCode": "992"
      },
      {
        "name": "Tanzania, United Republic of",
        "isO2": "TZ",
        "isO3": "TZA",
        "phoneCode": "255"
      },
      {
        "name": "Thailand",
        "isO2": "TH",
        "isO3": "THA",
        "phoneCode": "66"
      },
      {
        "name": "Timor-Leste",
        "isO2": "TL",
        "isO3": "TLS",
        "phoneCode": "670"
      },
      {
        "name": "Togo",
        "isO2": "TG",
        "isO3": "TGO",
        "phoneCode": "228"
      },
      {
        "name": "Tokelau",
        "isO2": "TK",
        "isO3": "TKL",
        "phoneCode": "690"
      },
      {
        "name": "Tonga",
        "isO2": "TO",
        "isO3": "TON",
        "phoneCode": "676"
      },
      {
        "name": "Trinidad and Tobago",
        "isO2": "TT",
        "isO3": "TTO",
        "phoneCode": "1"
      },
      {
        "name": "Tunisia",
        "isO2": "TN",
        "isO3": "TUN",
        "phoneCode": "216"
      },
      {
        "name": "Turkey",
        "isO2": "TR",
        "isO3": "TUR",
        "phoneCode": "90"
      },
      {
        "name": "Turkmenistan",
        "isO2": "TM",
        "isO3": "TKM",
        "phoneCode": "993"
      },
      {
        "name": "Turks and Caicos Islands",
        "isO2": "TC",
        "isO3": "TCA",
        "phoneCode": "1"
      },
      {
        "name": "Tuvalu",
        "isO2": "TV",
        "isO3": "TUV",
        "phoneCode": "688"
      },
      {
        "name": "Uganda",
        "isO2": "UG",
        "isO3": "UGA",
        "phoneCode": "256"
      },
      {
        "name": "Ukraine",
        "isO2": "UA",
        "isO3": "UKR",
        "phoneCode": "380"
      },
      {
        "name": "United Arab Emirates",
        "isO2": "AE",
        "isO3": "ARE",
        "phoneCode": "971"
      },
      {
        "name": "United Kingdom",
        "isO2": "GB",
        "isO3": "GBR",
        "phoneCode": "44"
      },
      {
        "name": "United States of America",
        "isO2": "US",
        "isO3": "USA",
        "phoneCode": "1"
      },
      {
        "name": "United States Minor Outlying Islands",
        "isO2": "UM",
        "isO3": "UMI",
        "phoneCode": null
      },
      {
        "name": "Uruguay",
        "isO2": "UY",
        "isO3": "URY",
        "phoneCode": "598"
      },
      {
        "name": "Uzbekistan",
        "isO2": "UZ",
        "isO3": "UZB",
        "phoneCode": "998"
      },
      {
        "name": "Vanuatu",
        "isO2": "VU",
        "isO3": "VUT",
        "phoneCode": "678"
      },
      {
        "name": "Venezuela (Bolivarian Republic of)",
        "isO2": "VE",
        "isO3": "VEN",
        "phoneCode": "58"
      },
      {
        "name": "Vietnam",
        "isO2": "VN",
        "isO3": "VNM",
        "phoneCode": "84"
      },
      {
        "name": "Virgin Islands (British)",
        "isO2": "VG",
        "isO3": "VGB",
        "phoneCode": "1"
      },
      {
        "name": "Virgin Islands (U.S.)",
        "isO2": "VI",
        "isO3": "VIR",
        "phoneCode": "1"
      },
      {
        "name": "Wales",
        "isO2": "GB",
        "isO3": "WLS",
        "phoneCode": null
      },
      {
        "name": "Wallis and Futuna",
        "isO2": "WF",
        "isO3": "WLF",
        "phoneCode": "681"
      },
      {
        "name": "Western Sahara",
        "isO2": "EH",
        "isO3": "ESH",
        "phoneCode": "212"
      },
      {
        "name": "Yemen",
        "isO2": "YE",
        "isO3": "YEM",
        "phoneCode": "967"
      },
      {
        "name": "Zambia",
        "isO2": "ZM",
        "isO3": "ZMB",
        "phoneCode": "260"
      },
      {
        "name": "Zimbabwe",
        "isO2": "ZW",
        "isO3": "ZWE",
        "phoneCode": "263"
      }
    ]
  }