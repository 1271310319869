import React, { useEffect, useState } from "react";
import TextInput from "@modules/TextInput";
import MyDnaCountrySelect from "@modules/Shared/MyDnaCountrySelect";
import { Formik, Form } from "formik";
import * as yup from "yup";
import LocationSearchInputWrapper from "./LocationSearchInputWrapper";
import {addressCaptured, captureAddress, setProductSubscriptionFreightOptions} from "@base/Actions/CustomerDetailsActions";
import { updateCustomerDetails } from "@appSyncGateway/GraphqlClientAPIHelper";
import { connect } from "react-redux";
import { AllowedPatterns, ErrorMessages, APPCONSTANTS } from "@modules/Shared/Constants";
import PhoneNumberInput from "@modules/Shared/PhoneNumberInput";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ComponentContainerHeader from "@modules/Header";
import ComponentButtons from "@modules/ComponentButtons";
import { ExpansionPanelComponent } from "@modules/ExpansionPanel";
import { SecondaryTitles, PrimaryTitles } from "@modules/Shared/TitleTexts";
import { ErrorMessageBlock }  from "@modules/ErrorMessageBlock";
import { CallHistoryMethodAction, push } from "connected-react-router";
import { setSubscriptionFreightOptions, setOrganisationsWithPartnerOptions } from "@base/Reducers/CustomerDetailsReducer";
import { ErrorLabel, ErrorLabelSplitLines } from '@modules/ErrorLabel';
import { FetchSubscriptionFreightOptions } from "@modules/utils/FetchSubscriptionFreightOptions";
import { CountryList } from "@modules/Shared/CountriesList";
import ErrorMsgHandler from "@utils/ErrorMsgHandler";
import {myDNARouter} from '@modules/utils/myDNARouter';

import {
  BottomMarginWrapper, 
  StyledLabel, 
  ExpansionSummaryTitleWrapper, 
  ExpansionSummaryTitleIconWrapper, 
  ExpansionSummaryWrapper, 
  FormContentBlockWrapper, 
  ExpansionPanelItemWrapper, 
  TextBoxTitleWrapper,
  ErrorMessageBlockWrapper,
  AddressCaptureContentWrapper
} from './styles';

type PlaceType = {
  streetAddress: string,
  optionalAddress: string,
  locality: string,
  administrative_area_level_1: string,
  postal_code: string,
  country: string,
  phoneNumber: string,
  timeZone: string,
  countryCode: string
}

type AddressCaptureProps = {
  place: PlaceType; 
  dispatch: { 
    (arg0: CallHistoryMethodAction<[string, unknown?]>): void; 
    (props?: any): any; 
  }; 
  auth: { 
    user: { 
      attributes: { 
        email: any; 
      }; 
    }; 
  }; 
  userDetails: any; 
  barcode: { 
    partner: any; 
    kitTypeId: any; 
  };
  nextPage: string; 
  previousPage: any;
  systemUI: any;
};

const AddressCapture = (props: AddressCaptureProps) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [countryError, setCountryError] = useState<string>("");
  const [countryState, setCountryState] = useState<string>(props.place.country ?? "Australia");
  const [countryCodeState, setCountryCodeState] = useState<string>("AU");
  
  var place = props.place;

  if (!place.streetAddress) {

    let timeZone = "Australia/Sydney";

    if (window.Intl && typeof window.Intl === "object"){
      timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    place = {
      streetAddress: "",
      optionalAddress: "",
      locality: "",
      administrative_area_level_1: "",
      postal_code: "",
      country: "Australia",
      phoneNumber: "",
      timeZone: timeZone,
      countryCode: countryCodeState
    };
  }

  const [formData, setFormData] = useState<PlaceType>(place);

  useEffect(() => {
    if (!props.place) props.dispatch(push('/'))
    props.dispatch(captureAddress(props));
    checkCountryAgainstProduct(countryState, props.barcode.partner, props.barcode.kitTypeId, place.timeZone);
  }, []);

  useEffect(() => {
    if(props.place.countryCode && countryCodeState !== props.place.countryCode) {
      setCountryCodeState(props.place.countryCode);
    }
  }, [props.place.countryCode]);

  const validationSchema = yup.object({
    streetAddress: yup
      .string()
      .required("Street Address is Required")
      .matches(
        AllowedPatterns.CheckForInvalidSqlInjection,
        ErrorMessages.CheckForInvalidSqlInjection
      )
      .max(200, "Address must be under 200 characters"),
    optionalAddress: yup
      .string()
      .notRequired()
      .matches(
        AllowedPatterns.CheckForInvalidSqlInjection,
        ErrorMessages.CheckForInvalidSqlInjection
      )
      .max(100, "Apt, Suite, Building must be under 100 characters"),
    locality: yup //city
      .string()
      .required("City is Required")
      .matches(
        AllowedPatterns.CheckForInvalidSqlInjection,
        ErrorMessages.CheckForInvalidSqlInjection
      )
      .max(100, "City must be under 100 characters"),
    administrative_area_level_1: yup //state
      .string()
      .required("State is Required")
      .matches(
        AllowedPatterns.CheckForInvalidSqlInjection,
        ErrorMessages.CheckForInvalidSqlInjection
      )
      .max(100, "State must be under 100 characters"),
    country: yup
      .string()
      .matches(
        AllowedPatterns.CheckForInvalidSqlInjection,
        ErrorMessages.CheckForInvalidSqlInjection
      )
      .required("Country is Required")
      .max(100, "Country must be under 100 characters"),
    postal_code: yup
      .string()
      .min(3, "Post Code/Zip Code cannot be less than 3 characters")
      .max(10, "Post Code/Zip Code cannot exceed 10 characters"),
    phoneNumber: yup
      .string()
      .matches(AllowedPatterns.NumericCheck, ErrorMessages.NumericCheck)
      .required("Phone Number is Required")
      .max(14, "Phone Number has too many characters")
      .min(6, "Phone Number must be at least 6 characters"),
  });

  const submitAddressDetails = async (data: any) => {

    let customerDetails = {
      emailAddress: props.auth.user.attributes.email,
      marketingOptIn: localStorage.getItem('marketingOptIn')
    };

    await updateCustomerDetails(customerDetails, props.userDetails, props.barcode, data)
      .then((response) => {
        data.countryCode = countryCodeState;
        props.dispatch(addressCaptured(props, data));
        setSubscriptionFreightOptions(countryState, props?.barcode?.partner, props?.barcode?.kitTypeId)
        .then(()=>{
            myDNARouter(props, APPCONSTANTS.NEXT_PAGE, "address");
        }).catch((err)=>{
          setErrorMessage(ErrorMsgHandler(err))
        })
        setOrganisationsWithPartnerOptions(props?.barcode?.partner);
        return response;
      })
      .catch((err) => { setErrorMessage(ErrorMsgHandler(err)) }
      );
  };

  const setCountry = async (country: { name: string; isO2: string; }) => {
    setCountryState(country?.name);
    setCountryCodeState(country?.isO2);

    // Confirm that the product selected is available in the country picked 
    if (country?.name != "") {
      var barcode = props.barcode;
      var place = props.place;
      await checkCountryAgainstProduct(country.name, barcode.partner, barcode.kitTypeId, place.timeZone);
    }
  }

  const checkCountryAgainstProduct = async (countryName: string, partnerName: string, barcodeKitTypeId: string, timeZone: string) => {
    FetchSubscriptionFreightOptions(null, countryName, partnerName, barcodeKitTypeId, timeZone)
      .then((response:any) => {
        setCountryError("")
        props.dispatch(setProductSubscriptionFreightOptions(response.data.getRegisterProductSubscriptionFreightOptions));
      })
      .catch(() => {
        setCountryError("Oops, it looks like we aren't currently ready for customers in your region. \nWe're continually expanding, so please check back with us at a later date.");
      });
  }

  const invalidCountry = countryError != "";

  const isErrorInForm = (errors:object) => Object.keys(errors).length > 0;
  return (
    <AddressCaptureContentWrapper>
      <ComponentContainerHeader
        primaryTitle={PrimaryTitles.AddressCapture }
        secondaryTitle={props?.barcode?.kitTypeId == null ? SecondaryTitles.AddressCapture : ""}
      />
      <Formik
        validateOnChange={true}
        initialValues={formData!}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting }) => {
          setSubmitting(true);
          data.country = countryState;
          submitAddressDetails(data);
          setSubmitting(false);
        }}
      >
        {({ values, errors, isSubmitting, setFieldValue }) => {

        const onAutoSearchCountryChange = (countryName: string) => {
          var country = CountryList.coutryList.find(x => x.name.includes(countryName));
          if (country) setCountry(country);
        }

        return (
          <BottomMarginWrapper>
            <FormContentBlockWrapper>
              <ExpansionSummaryWrapper>
                <ExpansionSummaryTitleWrapper>
                  <StyledLabel>Country</StyledLabel>
                </ExpansionSummaryTitleWrapper>
                <MyDnaCountrySelect
                  label={"Country *"}
                  name={"country"}
                  setFieldValue={setFieldValue}
                  setCountry={(country: any) => setCountry(country)}
                  defaultValue={countryState}
                />
              </ExpansionSummaryWrapper>
            </FormContentBlockWrapper>
            <LocationSearchInputWrapper
              setFieldValue={setFieldValue}
              countryCode={countryCodeState}
              onCountryChange={onAutoSearchCountryChange}
              {...props}
              dispatch={props.dispatch}
            />
            <Form id="address_details">
              <ExpansionPanelComponent
                summary={
                  <FormContentBlockWrapper>
                    <ExpansionSummaryWrapper>
                      <ExpansionSummaryTitleWrapper>
                        <StyledLabel>
                          Or, enter manually
                        </StyledLabel>
                      </ExpansionSummaryTitleWrapper>
                      <ExpansionSummaryTitleIconWrapper>
                        <ExpandMoreIcon />
                      </ExpansionSummaryTitleIconWrapper>
                    </ExpansionSummaryWrapper>
                  </FormContentBlockWrapper>
                }
                content={
                  <React.Fragment>
                    <ExpansionPanelDetails>
                      <ExpansionPanelItemWrapper>
                        <TextInput
                          name="streetAddress"
                          label="Enter Street Address *"
                          maxLength="100"
                          setFieldValue={setFieldValue}
                          //required={true}
                        />
                      </ExpansionPanelItemWrapper>
                    </ExpansionPanelDetails>
                    <ExpansionPanelDetails>
                      <ExpansionPanelItemWrapper>
                        <TextInput
                          name="optionalAddress"
                          label="Apt, Suite, Building."
                          maxLength="100"
                          setFieldValue={setFieldValue}
                        />
                      </ExpansionPanelItemWrapper>
                    </ExpansionPanelDetails>
                    <ExpansionPanelDetails>
                      <ExpansionPanelItemWrapper>
                        <TextInput
                          name="locality"
                          label="City *"
                          maxLength="100"
                          setFieldValue={setFieldValue}
                          //required={true}
                        />
                      </ExpansionPanelItemWrapper>
                    </ExpansionPanelDetails>
                    <ExpansionPanelDetails>
                      <ExpansionPanelItemWrapper>
                        <TextInput
                          name="administrative_area_level_1"
                          label="State *"
                          maxLength="100"
                          setFieldValue={setFieldValue}
                          //required={true}
                        />
                      </ExpansionPanelItemWrapper>
                    </ExpansionPanelDetails>
                    <ExpansionPanelDetails>
                      <ExpansionPanelItemWrapper>
                        <TextInput
                          name="postal_code"
                          label="Post Code/Zip Code"
                          maxLength="10"
                          setFieldValue={setFieldValue}
                          //required={true}
                        />
                      </ExpansionPanelItemWrapper>
                    </ExpansionPanelDetails>
                  </React.Fragment>
                }
              />
              <br />
              <FormContentBlockWrapper>
                <TextBoxTitleWrapper>
                  <StyledLabel>Mobile Phone</StyledLabel>
                </TextBoxTitleWrapper>
                <PhoneNumberInput
                  label="Mobile *"
                  name="phoneNumber"
                  maxLength="14"
                  defaultCountry="au"
                  setFieldValue={setFieldValue}
                />
              </FormContentBlockWrapper>
              <ErrorMessageBlockWrapper>
                {isErrorInForm(errors) && <ErrorLabel>{ErrorMessages.CompleteAllFields}</ErrorLabel>}
              {errorMessage && 
                <>
                  <ErrorLabel>{errorMessage}</ErrorLabel>
                </>}
                {countryError && 
                <>
                  <ErrorLabelSplitLines>{countryError}</ErrorLabelSplitLines>
                </>}
                </ErrorMessageBlockWrapper>
              <ComponentButtons
                isSubmitting={isSubmitting}
                barcode = {props.barcode}
                disableNext={invalidCountry || isErrorInForm(errors)}
                dispatch={props.dispatch}
                previousPage={props.previousPage}
                currentPage="address"
                singleButton={false}
              />
            </Form>
          </BottomMarginWrapper>
        )}}
      </Formik>
    </AddressCaptureContentWrapper>
  );
  
}

const mapStateToProps = (state: any) => {
  return state;
};

export default connect(mapStateToProps)(AddressCapture);
