import styled from 'styled-components';
import {AppBar} from "@material-ui/core";

export const MenuBarWrapper = styled.div`
    flex-grow: 1;
`;

export const StyledAppBar = styled(AppBar)`
    background-color: #081f2d;
`;

export const StyledImg = styled.img`
    margin: auto;
`;