
import { MyDnaTheme } from '@themes/Styles/Default';
import styled from 'styled-components';

export const PaymentContainerWrapper = styled.div`
    margin: auto;
    width: 20rem;
`;

export const StyledConfirmationImage = styled.img`
    width: 6.875rem;
    display: inline-block;
`;

export const NoDecorationTextWrapper = styled.a`
    text-decoration: none;
`;

export const ConfirmationFooterWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: ${MyDnaTheme.ContentWidth};
    height: 5rem;
    padding-top: 1rem;
    padding-bottom: 2rem;
`;

export const LogoWrapper = styled.div`
    display: flex;
    width: 25%;

    height: 5rem;
    object-fit: contain;
`;

export const CtaWrapper = styled.div`
    display: flex;
    width: 75%;
`;

export const CtaContentsWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CtaTextWrapper = styled.div`
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    font-family: 'NowBlack', sans-serif;
    font-size: 13px;
    text-align: left;
`;

export const CtaButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

export const CtaButtonWrapper = styled.div`
    display: flex;
    margin: 0.25rem;
`;

export const StyledImg = styled.img`
    width: 100%;
`;

export const ConfirmationBodyWrapper = styled.div`
    font-size: 14px;
`;

export const ConfirmationTextBlock = styled.div`
    display: flex;
    max-width: ${MyDnaTheme.ContentWidth};
    padding-top: 1rem;
    padding-bottom: 1rem;

    justify-content: center;
`;

export const StyledSpan = styled.span`
    text-align: center;
`;

export const BoldText = styled(StyledSpan)`
    font-weight: 900;
`;

export const SubscriptionPlanTextWrapper = styled(BoldText)`
    color: ${MyDnaTheme.Colours.Primary};
    text-transform: uppercase;
`;

export const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;
`;

export const StyledHorizontalBreak = styled.hr`
    border-top: ${MyDnaTheme.Colours.Input.Heavy};
    border-left: none;
    border-right: none;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
`;