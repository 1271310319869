export const SecondaryTitles =  {
    AddressCapture : "Where should we send your DNA collection kit?",
    PersonalDetails: "SUCCESS! NOW, LET’S GET TO KNOW EACH OTHER",
    Goals:"Our advanced algorithms personalize every calorie and push up to your goals, lifestyle and genetic profile.",
    Create:"Create your myDNA account to unlock more than just the power of your DNA.",
    Verify:"Please check your email (including your Junk Mail) and enter the code below.",
    SignIn:'Your myDNA membership is only a few steps away!',
    MedicationDetails: "Your genetics can influence how you respond to certain medications. \n\
    Our report covers over 80 medications with evidence that your DNA may affect your response to them. \n \
    Providing your current medications will help us to further personalise your medication report."
}
export const PrimaryTitles =  {
    AddressCapture : "JUST A FEW MORE DETAILS",
    PersonalDetails: "SUCCESS! NOW WE'D LIKE TO LEARN A LITTLE ABOUT YOU",
    PaymentDetails: "YOU'RE ONLY ONE STEP AWAY",
    OrderDetails: "MEMBERSHIP SUMMARY",
    Goals:"WHAT WILL YOU ACHIEVE?",
    Create:"DNA ANALYSIS IS JUST THE BEGINNING!",
    Verify:"WE'VE SENT YOU AN EMAIL TO VERIFY YOUR ACCOUNT",
    SignIn:'PLEASE LOG IN TO CONTINUE YOUR REGISTRATION',
    Confirmation:'Congratulations! You\'re all set',
    VIPConfirmation: 'you\'re all set',
    MedicationDetails: 'HELP US PERSONALISE YOUR MEDICATION RESULTS'
}
