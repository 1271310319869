import React, { Component } from "react";
import { CheckboxInput } from "@modules/CheckboxInput";
import { Formik, Form } from "formik";
import {orderDetails, orderDetailsCaptured, orderDetailsCaptureFailed, finaliseRegistration, sendOrderDetailsToGTM } from "@base/Actions/CustomerDetailsActions";
import { push } from "connected-react-router";
import { withStyles } from "@material-ui/styles";
import { updateCustomerDetails, commitCustomerDetails } from "@appSyncGateway/GraphqlClientAPIHelper";
import {connect} from "react-redux";
import { selectTermsAndConditions } from "@base/Reducers/CustomerDetailsReducer";
import { verifyTermsAndConditions, verifySelectedOrganisation } from "./OrderDetailServices";
import { GetDeliveryOptions } from './services/GetDeliveryOptions';
import { FetchFuturePaymentDate } from '@utils/FetchFuturePaymentDate';
import { SubscriptionOption } from "@modules/SubscriptionOption";
import  Insights  from "@modules/Insights/index";
import ComponentButtons from "@modules/ComponentButtons";
import { PrimaryTitles} from "@modules/Shared/TitleTexts";
import ComponentContainerHeader from "@modules/Header";
import { useStyles } from "./OrderDetailsStyle";
import { messages } from "@modules/Shared/Messages";
import OrganisationWithPartnerSelectOptions from "@modules/Shared/OrganisationWithPartnerSelectOptions";
import { ErrorLabel } from '@modules/ErrorLabel';
import  {sortSubscriptionOptions}  from "@modules/utils/SortSubscriptionOptions/SortSubscriptionOptions";
import {
  OrderTotalBlockWrapper, 
  OrderTotalContainerWrapper, 
  OrderTotalWrapper, 
  OrderTotalLeftFloatWrapper, 
  OrderTotalRightFloatWrapper, 
  FormContentWrapper,
  TitleWrapper
} from './styles';
import ErrorMsgHandler from "@utils/ErrorMsgHandler";
import { APPCONSTANTS } from "@modules/Shared/Constants";
import {myDNARouter, getPreviousPagePath} from '@modules/utils/myDNARouter';

class OrderDetail extends Component {

  constructor(props) {
      super(props);
      
      this.state = {
          enableSubscriptionOptions: false,
          subscriptionFreightOptions: props.customerDetails.subscriptionFreightOptions ?? [],
          deliveryOptions: [],
          isSubmitted: false,
          hasError: false,
          termsAndConditionsURL: selectTermsAndConditions(props.barcode?.partner),
          totalPayableAmount:0,
          basePlan:{},
          termsAndConditions: false,
          selectedOrganisation: props.selectedOrganisation,
          stripePublishableKey: props.customerDetails.subscriptionFreightOptions.stripePublishableKey,
          customerDetails: props.customerDetails,
          systemUI: props.initialValue.systemUI
      };
      if(this.state.subscriptionFreightOptions.length > 1){
        this.state.subscriptionFreightOptions = sortSubscriptionOptions(this.state.subscriptionFreightOptions);
      }

      if (props.orderDetails && props.orderDetails.termsAndConditions && props.orderDetails.subscriptionOptionId) {
        this.data = props.orderDetails;
        this.state.deliveryOptions = GetDeliveryOptions(props.subscriptionFreightOptions, props.orderDetails.subscriptionOptionId);
        this.state.termsAndConditions = props.orderDetails.termsAndConditions;

      } 
      else if(this.state.subscriptionFreightOptions.length > 1) {
       this.state.deliveryOptions = [];
       this.data = {
           subscriptionOptionId: '',
           deliveryOptionId: '',
          subscriptionOptionPriceId: '',
           deliveryOptionPriceId: '',
          collectionKitPriceId: '',
           stripePublishableKey:  '',
            bestValue: this.state.subscriptionFreightOptions[0].id
        };
      }
      else {
        this.state.deliveryOptions = this.state.subscriptionFreightOptions.length > 0 ? this.state.subscriptionFreightOptions[0]?.deliveryOptions : [];
        this.data = {
           subscriptionOptionId: this.state.subscriptionFreightOptions.length > 0 ? this.state.subscriptionFreightOptions[0].id : '',
            deliveryOptionId: this.state.deliveryOptions.length > 0 && this.needsDelivery() ? this.state.deliveryOptions[0].id : '',
            subscriptionOptionPriceId: this.state.subscriptionFreightOptions.length > 0 ? this.state.subscriptionFreightOptions[0].priceId : '',
            deliveryOptionPriceId: this.state.deliveryOptions.length > 0 && this.needsDelivery() ? this.state.deliveryOptions[0].priceId : '',
            collectionKitPriceId: this.state.subscriptionFreightOptions.length > 0 ? this.state.subscriptionFreightOptions[0].collectionKitPriceId : '',
            stripePublishableKey:  this.state.subscriptionFreightOptions.length > 0 ? this.state.subscriptionFreightOptions[0].stripePublishableKey : '',
            currency: this.state.subscriptionFreightOptions.length > 0 ? this.state.subscriptionFreightOptions[0].currency : '',
            subscriptionType: "Insights only"
        };
      } 
      
  }
  
  needsDelivery() {
    return this.props.barcode?.partner == null;
  }

  needsDnaCollection() {
    return !this.props.barcode?.isVipKitType && !this.props.barcode?.isStaffKitType && !this.props.barcode?.isLegacyCustomer;
  }

  isValidOrganisationSelection(data) {
    return (!this.props?.barcode?.isOrganisationSelectionRequired) ||
    (this.props?.barcode?.isOrganisationSelectionRequired && verifySelectedOrganisation(data));
  }

  getOrderTotal(values) {
    if (Array.isArray(this.state.subscriptionFreightOptions) && Array.isArray(this.state.deliveryOptions)) {
      let subscriptionPlan =(this.state.subscriptionFreightOptions.find((s) => s.id === values.subscriptionOptionId));
      let deliveryInitialCharge = 0;
      if (this.needsDelivery()){
        deliveryInitialCharge = this.state.deliveryOptions?.find(
          (d) => d.id === values.deliveryOptionId
        )?.initialChargeAmount ?? 0;
      }

      let dnaCollectionCharges = this.needsDnaCollection() ? subscriptionPlan?.dnaCollectionCharges : 0;
      var totalPayableAmount = ((deliveryInitialCharge + dnaCollectionCharges).toFixed(2));      
      //setting state and not refreshing component hence the warning.
      this.state.totalPayableAmount = totalPayableAmount;
      this.state.stripePublishableKey = subscriptionPlan?.stripePublishableKey;

      return {total:totalPayableAmount, nextPayment:subscriptionPlan?.initialChargeAmount};
    } else {
      return 0;
    }
  }


  componentDidMount() {
    if (!this.props.orderDetails) {
      this.props.dispatch(push('/'))
    }
    this.props.dispatch(orderDetails(this.props));

    this.getOrderTotal(this.data);
  }

  submitOrderDetails = async data => {
    if(!data.subscriptionOptionId){
      return false;
    }
    let customerDetails = {
      emailAddress: this.props.auth.user.username,
      marketingOptIn: localStorage.getItem('marketingOptIn')
    };
    let orderAmount= this.state.totalPayableAmount;
    var orderDetails={orderAmount, ...data}
    let medicationDetails = this.props?.customerDetails?.medicationDetails
    const oneTimePaymentPriceIds = [];
    if (data.deliveryOptionPriceId)
      oneTimePaymentPriceIds.push(data.deliveryOptionPriceId);
    if (data.collectionKitPriceId)
      oneTimePaymentPriceIds.push(data.collectionKitPriceId);

    orderDetails.oneTimePaymentPriceIds = oneTimePaymentPriceIds;
    orderDetails.recurringPaymentPriceId = data.subscriptionOptionPriceId;
    orderDetails.stripePublishableKey = data.stripePublishableKey;
    orderDetails.currency = this.data.currency;
    if(Number(this.state.totalPayableAmount) === 0) {
      await this.commitCustomerDetails(customerDetails, this.props, orderDetails)
    }
    else {
      await updateCustomerDetails(
        customerDetails,
        this.props.userDetails,
        this.props.barcode,
        this.props.place,
        this.props.goals,
        orderDetails,
        null,
        medicationDetails
      )
        .then(response => {
          this.props.dispatch(orderDetailsCaptured(this.props, orderDetails));
          myDNARouter(this.props, APPCONSTANTS.NEXT_PAGE, "orderDetails");
          return response;
        })
        .catch(err => {
          this.setState({
            errorMessage: ErrorMsgHandler(err)
          })
        });
    }
  };

  commitCustomerDetails = async (customerDetails, props, orderDetails) => {
    await commitCustomerDetails(customerDetails, props.userDetails, props.place, props.goals, orderDetails, null, props.barcode, this.props?.customerDetails?.medicationDetails)
      .then(response => {
        props.dispatch(orderDetailsCaptured(props, orderDetails));
        props.dispatch(finaliseRegistration());
        const state = this.state;
        const subscriptionOption = state.subscriptionFreightOptions[0];
        const barcodeDisplayName = state.customerDetails.barcode.displayName;
        props.dispatch(sendOrderDetailsToGTM(subscriptionOption, undefined, barcodeDisplayName, response.data.commitRegistrationDetails));
        myDNARouter(props, APPCONSTANTS.NEXT_PAGE, "orderDetails", APPCONSTANTS.ZERO_PAYMENT)

      }).catch((err) => {
        let errMsg = ErrorMsgHandler(err)
        props.dispatch(orderDetailsCaptureFailed(props, errMsg))
        this.setState({
          isSubmitted:false,
          showProgress:false
        });
      });
  }

  getFuturePaymentDate = (values) => {    
    if (Array.isArray(this.state.subscriptionFreightOptions) && Array.isArray(this.state.deliveryOptions)) {
      let subscriptionPlanMonths =Number((this.state.subscriptionFreightOptions?.find((s) => s.id === values.subscriptionOptionId))?.subscriptionPeriodDays);
      return FetchFuturePaymentDate(subscriptionPlanMonths)
    }
  }

  onSelectSubscription = () => {
    this.setState({
      subscriptionErrorMessage: undefined
    });
  }

  render() {
    const setTermsandConditions = (event) => {
      this.state.termsAndConditions = event.target.checked;
      this.data.termsAndConditions = event.target.checked;
      (event.target.checked) && this.setState({
        errorMessage : null
      })
    }

    return (
      <div className={this.props.classes.root}>
      <TitleWrapper>
        <ComponentContainerHeader primaryTitle={PrimaryTitles.OrderDetails} />
      </TitleWrapper>
      <Formik
          validateOnChange={true}
          initialValues={this.data}
          onSubmit={async(data, { setSubmitting }) => {
            if(this.state.enableSubscriptionOptions && (!data.subscriptionOptionId || !data.subscriptionOptionPriceId)){
              setSubmitting(false);
            }
            // Considering when subscriptionOptionId is not available none of the subscription is selected  
            // then error message will prompt to select the subscription
            if(this.state.enableSubscriptionOptions && !data.subscriptionOptionId){
              this.setState({
                subscriptionErrorMessage: "Please select the membership plan."
              });
            }
            if (!verifyTermsAndConditions(data)) {
              this.setState({
                errorMessage:messages.acceptTermsandConditions
              })
            } else if (!this.isValidOrganisationSelection(data)) {
              this.setState({
                errorMessage:messages.organisationSelectionErrorMessage
              })
            } else {
              setSubmitting(true);
              await this.submitOrderDetails(data);
              setSubmitting(false);
            }
            setSubmitting(false);
          }}
        >
          {({ values, errors, isSubmitting, touched, setFieldValue, setDeliveryOption, }) => 
            (
            <Form id="order_details">
              <FormContentWrapper>
                <Insights 
                  subscriptionFreightOptions={this.state.subscriptionFreightOptions[0]} 
                  displayName={this.state.customerDetails.barcode.displayName}
                  listItems={this.state.systemUI.insights.listItem}
                  hideTrialPeriodInfo={this.state.systemUI.orderDetail.hideTrialPeriodInfo}
                />


          {
            this.state.enableSubscriptionOptions && <SubscriptionOption
                              setFieldValue={setFieldValue}
                              getDeliveryOption={GetDeliveryOptions}
                              deliveryOptions={this.state.deliveryOptions}
                              subscriptionOptionId={values.subscriptionOptionId}
                              bestValue={values.bestValue}
                              subscriptionFreightOptions={this.state.subscriptionFreightOptions}
                              basePlan={this.state.basePlan}
                              barcode={this.props.barcode}
                              onSelectSubscription={this.onSelectSubscription}
                              />
          }
                
                {(this.props?.barcode?.isOrganisationSelectionRequired)&&
                  <OrganisationWithPartnerSelectOptions
                    label = "Select the pharmacy where you purchased your kit"
                    partner = {this.props?.barcode?.partner}
                    setFieldValue={setFieldValue}
                    selectedOrganisation = {this.state.selectedOrganisation}
                    organisationOptions = {this.props?.organisationOptions}
                  />
                }
                {
                  this.state.enableSubscriptionOptions &&
                  <OrderTotalBlockWrapper>
                  <OrderTotalContainerWrapper>
                    <OrderTotalWrapper>
                      <OrderTotalLeftFloatWrapper>Your total today </OrderTotalLeftFloatWrapper>
                      <OrderTotalRightFloatWrapper>${(!isNaN(this.getOrderTotal(values).total))?this.getOrderTotal(values).total:'0'}</OrderTotalRightFloatWrapper>
                    </OrderTotalWrapper>
                  </OrderTotalContainerWrapper>
                  </OrderTotalBlockWrapper> }
                <CheckboxInput
                    name={"termsAndConditions"}
                    value={this.state.termsAndConditions}
                    setFieldValue={setFieldValue}
                    onChange={setTermsandConditions}
                />
                <span>
                I agree to myDNA's <a
                    href={this.state.termsAndConditionsURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >Informed Consent</a> and <a
                    href={this.state.termsAndConditionsURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                     General Terms & Conditions
                  </a>
                </span>
              </FormContentWrapper>
              {this.state.errorMessage && 
                <div>
                    <ErrorLabel>{this.state.errorMessage}</ErrorLabel>
                </div>}
                {this.state.subscriptionErrorMessage && 
                <div>
                    <ErrorLabel>{this.state.subscriptionErrorMessage}</ErrorLabel>
                </div>}
                <br/>
              <ComponentButtons
                isSubmitting={isSubmitting}
                dispatch={this.props.dispatch}
                barcode = {this.props.barcode}
                previousPage={
                  getPreviousPagePath(this.props, "orderDetails")
                }
                submitText={Number(this.state.totalPayableAmount) > 0 ? "PROCEED TO PAYMENT" : "COMPLETE REGISTRATION"}
                currentPage="orderDetails"
              />
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(withStyles(useStyles)(OrderDetail));
