import {combineReducers} from 'redux'
import {connectRouter} from 'connected-react-router'
import RegisterReducer from "./Base/Reducers/RegisterReducer";
import {CustomerDetailsReducer} from "./Base/Reducers/CustomerDetailsReducer";
import { CustomerDetailsActions } from '@base/Actions/CustomerDetailsActions';

const rootReducer = (history) => combineReducers({
        router: connectRouter(history),
        register: RegisterReducer,
        customerDetails: CustomerDetailsReducer
    }
);

export default rootReducer;
