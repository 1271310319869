import { MyDnaTheme } from '@themes/Styles/Default';
import styled from 'styled-components';
import Paper from "@material-ui/core/Paper";
import ToggleButton from "@material-ui/lab/ToggleButton";

const CardHeight = "157px";
const CardWidth = "127px";
const CardWidthMobile = "110px";
const CardWidthMobileXS = "100px";

export const StyledToggleButton = styled(ToggleButton)`
    && {
        border: none;
        height: ${CardHeight};
        margin: 5px;
        width: ${CardWidth};
        box-sizing: border-box;
        @media (max-width: ${MyDnaTheme.MediaSizes.Smaller}) {
            width: ${CardWidthMobile};
        }
        @media (max-width: ${MyDnaTheme.MediaSizes.Smallest}) {
            width: ${CardWidthMobileXS};
        }
    }
`;

export const ContentsWrapper = styled.div`
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    height: ${CardHeight};
    width: ${CardWidth};
`;

export const StyledPaper = styled(Paper)<{isSelected: boolean}>`
    && {
        height: ${CardHeight};
        width: ${CardWidth};

        border-radius: 4px;
        border-width: 1px;
        border-style: solid;
        border-color: ${(props) => props.isSelected ? MyDnaTheme.Colours.Primary : MyDnaTheme.Colours.Base.Grey};
        @media (max-width: ${MyDnaTheme.MediaSizes.Smaller}) {
            width: ${CardWidthMobile};
        }
        @media (max-width: ${MyDnaTheme.MediaSizes.Smallest}) {
            width: ${CardWidthMobileXS};
        }
    }
`;

export const ToggleButtonImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ToggleButtonImageSizeLimiter = styled.div`
    display: flex;
    justify-content: center;
    width: 90px;
`;

export const ToggleButtonImg = styled.img`
    height: 100%;
    width: 100%;
    padding: 5px;
    margin: 2.5px;
`;

export const ToggleButtonText = styled.div<{isSelected: boolean}>`
    color: ${ (props) => props.isSelected ? MyDnaTheme.Colours.Primary : MyDnaTheme.Colours.Base.Grey };
    font-size: 16px;
    text-align: center;
    margin: auto;
    padding-right: 5px;
    padding-left: 5px;

    font-family: 'NowBlack', sans-serif;
    text-transform: uppercase;
    line-height: 1rem;
    letter-spacing: 0px;
`;