import React from "react";
import PropTypes from "prop-types";
import { addressCaptured } from "@base/Actions/CustomerDetailsActions";
import { withStyles } from "@material-ui/styles";
import { addressStyles } from "@themes/Styles/materialTheme";

const google = (window.google = window.google ? window.google : {});

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.autocompleteInput = React.createRef();
    this.autocomplete = null;
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);

    this.addressForm = {
      street_number: "short_name",
      route: "long_name",
      locality: "long_name",
      administrative_area_level_1: "short_name",
      country: "long_name",
      postal_code: "short_name",
      subpremise: "long_name"
    };

  }

  componentDidMount(){
    this.autocomplete = new google.maps.places.Autocomplete(
      this.autocompleteInput.current,
      {
        types: [], 
        componentRestrictions:{country: this.props.countryCode}
      },
    );

    // Avoid paying for data that we don't need by restricting the set of
    // place fields that are returned to just the address components and placeId
    this.autocomplete.setFields(["address_component", "place_id", "adr_address", "formatted_address"]);
    this.autocomplete.addListener("place_changed", this.handlePlaceChanged);
  }

  componentDidUpdate(){
    this.autocomplete.setComponentRestrictions({country: this.props.countryCode})
  }

  handlePlaceChanged() {
    const place = this.autocomplete.getPlace();
    const autoComp = this.autocomplete;
    this.fillInAddress(place);
    this.props.dispatch(addressCaptured(this.props, place));
  }

  fillInAddress(place) {
    let fullStreetAddress = "";
    const placeComponents = place.address_components;

    //set the place Id if the address has one
    if (place.place_id) this.props.setFieldValue("placeId", place.place_id);
    else this.props.setFieldValue("placeId", "");


    if (!placeComponents) return;

    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    var unitNumber = "";
    var streetNumber = "";
    var streetName = "";
    var city = "";

    for (let i = 0; i < placeComponents.length; i++) {
      let addressType = placeComponents[i].types[0];
      let val;
      //street address is a the google street number + google route
      switch(addressType) {
        case "subpremise":
          val = placeComponents[i][this.addressForm[addressType]];
          fullStreetAddress += val + "/";
          unitNumber = val;
          break;
        case "street_number":
          val = placeComponents[i][this.addressForm[addressType]];
          fullStreetAddress += val;
          streetNumber = val;
          break;
        case "route":
          val = placeComponents[i][this.addressForm[addressType]];
          fullStreetAddress += " " + val;
          streetName = val;
          break;
        case "postal_town":
          val = placeComponents.find(x => x.types.includes("postal_town")).long_name;
          city = val;
          this.props.setFieldValue("locality", city);
          break;
      }

      if (
        this.addressForm[addressType] &&
        addressType !== "route" &&
        addressType !== "street_number"
      ) {
        val = placeComponents[i][this.addressForm[addressType]];
        this.props.setFieldValue(addressType, val);
      } else if (this.addressForm[addressType] && addressType === "route") {
        val = fullStreetAddress;
        this.props.setFieldValue("streetAddress", val);
      }
    }

    // If a street number wasn't found, we'll have to find it ourselves
    if (unitNumber === "" && (streetNumber === "" || streetName === "")) {
      var addressString = this.autocompleteInput.current.value;

      // Pull the locality out of the data, ie. Cragieburn
      // We can split the string at the locality to get the street number, name and any unit information
      const locality = placeComponents.find(x => x.types.includes("locality")).long_name;
      const localitySubstringIndex = addressString.indexOf(locality);
      var addressStringPreLocality = addressString.slice(0, localitySubstringIndex);
      addressStringPreLocality = addressStringPreLocality.replace(",", '');
      addressStringPreLocality = addressStringPreLocality.trim();      
     
      this.props.setFieldValue("streetAddress", addressStringPreLocality);
    }

    var country = placeComponents.find(x => x.types.includes("country"));
    this.props.onCountryChange(country.long_name);
  }

  render() {

    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className="MuiFormControl-root MuiTextField-root input-control">
          <form autoComplete="off">
            <input autoComplete="false" style={{ display: "none" }} />
            <label className="MuiFormLabel-root MuiInputLabel-root address-search-label address-search-label ">
              <p>Search address</p>
            </label>
            <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl ">
              <input
                id="address-search"
                aria-invalid="true"
                name="enterStreetAddress"
                type="text"
                maxLength="100"
                autoComplete="new-password"
                style={{width:'18rem'}}
                className="MuiInputBase-input MuiOutlinedInput-input search-address"
                ref={this.autocompleteInput}
                placeholder="Enter Your Address"
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

LocationSearchInput.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(addressStyles)(LocationSearchInput);
