import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  InstantSearch,
  Configure,
  Highlight
} from 'react-instantsearch-dom';
import MedicationAutoComplete from './MedicationAutoComplete';
import './MedicationAutoComplete.css';

const algoliasearch = require("algoliasearch");
const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APPID,  //appid
  process.env.REACT_APP_ALGOLIA_KEY  //key
);
const indexName = process.env.REACT_APP_ALGOLIA_INDEX
class MedicationAutoCompleteApp extends Component {
  state = {
    query: '',
  };

  onSuggestionSelected = (_, { suggestion }) => {
    this.setState({
      query: suggestion.name,
    });
    var commonUsage = '';
    var brandName = '';
    let genericMedications = {};
    if (suggestion.components && suggestion.components.length > 0) {
      commonUsage = suggestion.components.map(e => e.commonUsage).join(" . ");
      brandName = `${suggestion.brandName} (${suggestion.components.map(c => c.name).join("/")})`
      //ToDo Generate object for backend
      genericMedications = suggestion.components.map((c) => {return { activeComponent: c?.name }})
    }
    this.props.addSelectedMedications({ "name": brandName, commonUses: commonUsage, data: { brandName:suggestion.brandName , genericMedications }});
  };

  onSuggestionCleared = () => {
    this.setState({
      query: '',
    });
  };

  render() {
    return (
      <div className="container">
        <InstantSearch indexName={indexName} searchClient={searchClient}>
          <Configure hitsPerPage={5} />
          <MedicationAutoComplete
            onSuggestionSelected={this.onSuggestionSelected}
            onSuggestionCleared={this.onSuggestionCleared}
          />
        </InstantSearch>
      </div>
    );
  }
}

function Hit(props) {
  return (
      <Highlight attribute="brandName" hit={props.hit} />
  );
}

Hit.propTypes = {
  hit: PropTypes.object.isRequired,
};

export default MedicationAutoCompleteApp;