import React from "react";
import { StyledToggleButton, ContentsWrapper, StyledPaper,ToggleButtonImageWrapper, ToggleButtonImageSizeLimiter, ToggleButtonImg, ToggleButtonText } from './styles';

export type GoalComponentProps = {
    goal: string,
    key: number,
    imgSelected: any,
    imgNotSelected: any,
    imgAlt: string,
    selected: boolean,
    classes?: any,
    onClick: (goal: string) => void
}

export const GoalComponent = (props: GoalComponentProps) => {

    const isSelected = props.selected;

    return (
        <StyledToggleButton
            value={props.goal}
            key={props.key}
            onClick={() => props.onClick(props.goal)}
        >
            <ContentsWrapper>
                <StyledPaper
                  elevation={1}
                  isSelected = {isSelected}
                >
                    <ToggleButtonImageWrapper>
                        <ToggleButtonImageSizeLimiter>
                            <ToggleButtonImg
                                src={isSelected ? props.imgSelected : props.imgNotSelected}
                                alt={props.imgAlt}
                            />
                        </ToggleButtonImageSizeLimiter>
                    </ToggleButtonImageWrapper>
                    <ToggleButtonText isSelected={isSelected}>
                        {props.imgAlt}
                    </ToggleButtonText>
                </StyledPaper>
              </ContentsWrapper>
        </StyledToggleButton>)
};