import React, { Component } from "react";
import { Formik, Form } from "formik";
import { Partners } from '@modules/Shared/Constants';
import {setGoals,goalsCaptureFailed,goalsCaptured,} from "@base/Actions/CustomerDetailsActions";
import { NutritionGoalOptions, FitnessGoalOptions, APPCONSTANTS} from "@modules/Shared/Constants";
import { push } from "connected-react-router";
import { updateCustomerDetails } from "@appSyncGateway/GraphqlClientAPIHelper";
import { connect } from "react-redux";
import { PrimaryTitles, SecondaryTitles } from "@modules/Shared/TitleTexts";
import ComponentContainerHeader from "@modules/Header";
import ComponentButtons from "@modules/ComponentButtons";
import { GoalGroupComponent } from "@modules/GoalGroupComponent";
import { ErrorLabel } from '@modules/ErrorLabel';
import { ErrorMessageBlock } from '@modules/ErrorMessageBlock';
import { ComponentButtonWrapper, GoalSectionWrapper } from './styles';
import { VerifyAge } from "@modules/utils/VerifyAge";
import ErrorMsgHandler from "@utils/ErrorMsgHandler";
import {myDNARouter} from '@modules/utils/myDNARouter';

type Goals = {
  nutritionalGoal: string,
  fitnessGoal: string
}

export type GoalsLayoutProps = { 
  nutritionalGoal: string; 
  fitnessGoal: string; 
  initialValues: Goals; 
  partner: string;
  userDateOfBirth: Date; 
  errorMessage: string; 
  dispatch: any; 
  previousPage: any; 
  handleSubmit: any; 
  handleNutritionalChange: (value: string) => void; 
  handleFitnessChange: (value: string) => void; 
  barcode?: any;
}

export const GoalsLayout = (props: GoalsLayoutProps) => {

  return (
  <div>
    <ComponentContainerHeader
      primaryTitle={PrimaryTitles.Goals}
      secondaryTitle={SecondaryTitles.Goals}
    />
    <Formik
      validateOnChange={true}
      initialValues={props.initialValues}
      onSubmit={(data, { setSubmitting, setFieldError }) => {
        setSubmitting(true);
        props.handleSubmit(data, setFieldError);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, setFieldValue, getFieldProps }) => {

        const nutritionalFieldValue = getFieldProps('nutritionalGoal').value;
        const fitnessFieldValue = getFieldProps('fitnessGoal').value;
        if (props.initialValues.nutritionalGoal != "" && nutritionalFieldValue == "") setFieldValue('nutritionalGoal', props.initialValues.nutritionalGoal);
        if (props.initialValues.fitnessGoal != "" && fitnessFieldValue == "") setFieldValue('fitnessGoal', props.initialValues.fitnessGoal);
        
        const localHandleNutritionalChange = (newNutritionGoal: string) => {
          if (newNutritionGoal !== null) {
            setFieldValue('nutritionalGoal', newNutritionGoal);
            props.handleNutritionalChange(newNutritionGoal);
          }
        };
      
        const localHandleFitnessChange = (newFitnessGoal: string) => {
          if (newFitnessGoal !== null) {
            setFieldValue('fitnessGoal', newFitnessGoal);
            props.handleFitnessChange(newFitnessGoal);
          }
        };

        
        var LocalNutritionGoalOptions = NutritionGoalOptions;

        // Todo: SV: Refactor to use ProductFeature API call to get correct Goal fields
        if ((props.partner == Partners.DanceRX.name) && VerifyAge(props.userDateOfBirth)) {
          LocalNutritionGoalOptions = LocalNutritionGoalOptions.filter(x => x.goal !== "Lose Weight")
        }
        
        return (
        <Form id="goals">
          <GoalSectionWrapper>
            <GoalGroupComponent  
                heading={'Nutritional Goal'} 
                selectedValue={props.nutritionalGoal} 
                handleChange={localHandleNutritionalChange} 
                GoalOptions={LocalNutritionGoalOptions} 
              />
            <GoalGroupComponent
                heading={'Fitness Goal'} 
                selectedValue={props.fitnessGoal} 
                handleChange={localHandleFitnessChange} 
                GoalOptions={FitnessGoalOptions} 
              />
          </GoalSectionWrapper>
          <div>
            <ErrorMessageBlock name='nutritionalGoal'/>
            <ErrorMessageBlock name='fitnessGoal'/>
          </div>
          {props.errorMessage && <div>
              <ErrorLabel>{props.errorMessage}</ErrorLabel>
          </div>}
          <ComponentButtonWrapper>
            <ComponentButtons
              isSubmitting={isSubmitting}
              dispatch={props.dispatch}
              previousPage={props.previousPage}
              currentPage="goals"
              singleButton={false}
              barcode={props.barcode}
            />
          </ComponentButtonWrapper>
        </Form>
      )}}
    </Formik>
  </div>);
}

type GoalsComponentProps = { 
  goals: { 
    fitnessGoal: string; 
    nutritionalGoal: string; 
  },
  dispatch: any,
  userDetails: any,
  barcode: any,
  alternateExit: any,
  previousPage: any,
  nextPage: any,
  place: any,
  auth: any,
  systemUI: any
}

const Goals = (props: GoalsComponentProps) => {
  const [stateGoals, setStateGoals] = React.useState<Goals>({nutritionalGoal: "", fitnessGoal: ""});
  const [errorMessage, setErrorMessage] = React.useState<string>("");

  // On initialization
  React.useEffect(() => {
    if (props.goals && props.goals.nutritionalGoal && props.goals.fitnessGoal) {
      setStateGoals({
        nutritionalGoal: props.goals.nutritionalGoal,
        fitnessGoal: props.goals.fitnessGoal}
      );
    }

    if (!props.goals) {
      props.dispatch(push('/'))
    }
    props.dispatch(setGoals(props));
  }, []);

  const handleSubmit = (data: Goals, setFieldError: any) => {
    if (!data.nutritionalGoal) setFieldError("nutritionalGoal", "Please Select a Nutritional Goal");
    if (!data.fitnessGoal) setFieldError("fitnessGoal", "Please Select a Fitness Goal");
    if (data.nutritionalGoal && data.fitnessGoal) updateGoals(data);
  };

  const updateGoals = async (data: Goals) => {

    let customerDetails = {
      emailAddress: props.auth.user.username,
      marketingOptIn: localStorage.getItem('marketingOptIn')
    };

    await updateCustomerDetails(
      customerDetails,
      props.userDetails,
      props.barcode, 
      props.place,
      data
    )
      .then((response) => {
        props.dispatch(goalsCaptured(props, data));
        myDNARouter(props, APPCONSTANTS.NEXT_PAGE, "goals")
        return response;
      })
      .catch((err) => {
        const errorMsg = ErrorMsgHandler(err);
        setErrorMessage(errorMsg);
        props.dispatch(goalsCaptureFailed(props, errorMsg));
      });
  };

  const handleNutritionalChange = (newNutritionGoal: string) => {
    setStateGoals({ ...stateGoals, nutritionalGoal: newNutritionGoal});
  };

  const handleFitnessChange = (newFitnessGoal: string) => {
    setStateGoals({ ...stateGoals, fitnessGoal: newFitnessGoal});
  }

  const partner = props.barcode?.partner ?? 'MyDNA';
  const userDateOfBirth = props.userDetails.dateOfBirth;

  return GoalsLayout({
    nutritionalGoal: stateGoals.nutritionalGoal,
    fitnessGoal: stateGoals.fitnessGoal,
    initialValues: {
      nutritionalGoal: stateGoals.nutritionalGoal,
      fitnessGoal: stateGoals.fitnessGoal
    },
    partner: partner,
    userDateOfBirth: userDateOfBirth,
    errorMessage: errorMessage,
    dispatch: props.dispatch,
    previousPage: props.previousPage,
    handleSubmit: handleSubmit,
    handleNutritionalChange: handleNutritionalChange,
    handleFitnessChange: handleFitnessChange,
    barcode: props.barcode
  });
}

const mapStateToProps = (state: any) => {
  return state;
};

export default connect(mapStateToProps)(Goals);
