import React, { useState } from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { Paper, Chip } from "@material-ui/core";
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import { PlanDisplayText } from "../Shared/PlanDisplayText";
import {makeStyles} from "@material-ui/core/styles";

import { ListStyle, WrapperElement, Dollar, DollarSmall, ChipGapFiller, DarkColoredText, StrongDarkText, BlueColoredText, SubscriptionTitle, SubscriptionFrequency, SubscriptionPlan, PriceSecondary } from './styles';

const useStyles = makeStyles(theme => ({
  subscriptionPeriodText: {
    color:'#000' ,
    width:'11rem',
    fontFamily: "'AvenirRoman', sans-serif",
    width: 'auto',
    borderRadius: '4px',
    background: '#f1f1f1',
    fontWeight: 900
  },
  joinBtn: {
    margin: '0 0 10px',
    fontWeight: 800,
    fontSize: 14,
    textTransform: 'none',
    width: '100%'
  },
  selectedBtnStyle:{
    background: "#081F2D !important",
    color: '#fff',
  },
  unSelectedBtnStyle:{
    border: "2px solid #081F2D",
    color: '#081F2D',
  }
}));

// Calculating the monthly price for subscription
const getMonthlyPrice = (price, programDetails) => {
  let cost = price;
  if(programDetails.subscriptionInterval > 1){
    cost = (price/programDetails.subscriptionInterval).toFixed(2);
  }
  return cost;
}

// To differentiate whether it is a monthly subscription or more than monthly
const isMonthlySubscription = (subscriptionData) => (
  subscriptionData.subscriptionInterval === 1
)

// To show the discount details on CTA button
const joinTodayCtaText = (value) => {
  let ctaText = "Join Today";
  if(value.discount > 0) {
    ctaText = ctaText + " " + ' and save ' + value.discount  + '% off';
  }
  return ctaText;
}

// To find the VIP customer
const isItVipCustomer = (value) => {
  return value.subscriptionPeriodDays === 0;
}



const SubscriptionOption = (props) => {
  const classes = useStyles();
  const [deliveryOptions, setDeliveryOptions] = useState(props.deliveryOptions);
  var specialKit = false;
  if((props.barcode?.isVipKitType) || (props.barcode?.isStaffKitType)){
    specialKit = true
  }
  if (Array.isArray(props.subscriptionFreightOptions)) {
    return (
      <SubscriptionPlan className="subscription-plan">
        <ToggleButtonGroup
          value={props.subscriptionOptionId}
          exclusive>
          {props.subscriptionFreightOptions?.map((value) => {
            return (
                <div key={value.id}>
                  <Paper
                    elevation={1}
                    key={value.id}
                    className="subscription-option-card">
                      <SubscriptionTitle>
                        {isItVipCustomer(value) ? PlanDisplayText.VIPSubscriptionTitle : (isMonthlySubscription(value) ? PlanDisplayText.SubscriptionTitle : ( value.subscriptionInterval + " "+ PlanDisplayText.MonthlySubscriptionTitle))}
                      </SubscriptionTitle>                   
                      <WrapperElement>
                      <div>{props.bestValue === value.id ? <Chip label="BEST VALUE" color="secondary" className="value-indicator"/> : <ChipGapFiller></ChipGapFiller>}</div>
                      <div className="main-section-wpr">
                        <div className="main-section">
                          <p>
                            <strong>
                              <BlueColoredText className="subscription-amount">
                                <Dollar>$</Dollar>{getMonthlyPrice((Number(value.subscriptionAmountPerPeriod)), value)}
                              </BlueColoredText>
                            </strong>
                            <SubscriptionFrequency>{' /month'}</SubscriptionFrequency>
                          </p>
                          {value.dnaCollectionCharges != 0 &&
                              <p>
                                <StrongDarkText>+</StrongDarkText>
                                  <BlueColoredText>
                                      <DollarSmall>$</DollarSmall>
                                      <PriceSecondary>{value.dnaCollectionCharges}</PriceSecondary>
                                    </BlueColoredText>
                                <StrongDarkText> DNA Lab Analysis ($129 value)</StrongDarkText>
                              </p>
                          }

                          <ToggleButton
                                          value={value.id}
                                          key={value.id}
                                          className={classes.joinBtn + ' ' + (props.subscriptionOptionId === value.id ? classes.selectedBtnStyle : classes.unSelectedBtnStyle) }
                                          onClick={() => {
                                            props.setFieldValue("subscriptionOptionId", value.id);
                                            props.setFieldValue("subscriptionOptionPriceId", value.priceId);
                                            props.setFieldValue("stripePublishableKey", value.stripePublishableKey);
                                            props.setFieldValue("collectionKitPriceId", value.collectionKitPriceId);
                                            let deliveryOptions = props.getDeliveryOption(props.subscriptionFreightOptions, value.id);
                                            setDeliveryOptions(deliveryOptions);
                                            props.onSelectSubscription();
                                          }}
                                        >
                                          {props.subscriptionOptionId === value.id && <CheckRoundedIcon className="right-gap"/> }
                                          {joinTodayCtaText(value)}
                            </ToggleButton>


                          <ListStyle>
                            {value.discount > 0 && <li>Save {value.discount}% on monthly membership</li>}
                              {isItVipCustomer(value) ? 
                              <li>{PlanDisplayText.FreeMembershipDurationText}</li>
                              : <li>${value.subscriptionAmountPerPeriod} billed every {isMonthlySubscription(value) ? ' month ':  value.subscriptionInterval + " months"}  after free period</li>}
                          </ListStyle>                       
                        </div>
                        <div className="foo-section">
                          {!isItVipCustomer(value) && (specialKit === false) &&<Chip className={classes.subscriptionPeriodText} label={`${value.subscriptionPeriodDays}-${PlanDisplayText.Level2Heading}`} />}
                          <DarkColoredText>Cancel anytime</DarkColoredText>
                        </div>
                      </div>
                      </WrapperElement>
                  </Paper>
                </div>
            );
          })}
        </ToggleButtonGroup>
      </SubscriptionPlan>
    );
  } else {
    return <React.Fragment></React.Fragment>;
  }
};

export default SubscriptionOption;
