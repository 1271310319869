import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router";
import Amplify, { Auth } from "aws-amplify";
import { ThemeProvider } from "@material-ui/core/styles";
import "./App.css";
import MenuAppBar from "@layout/MenuAppBar";
import Authentication from "@layout/Pages/Register/Authentication";
import { signUp } from "@base/Actions/RegisterActions";
import PrivateRoute from "@modules/Shared/PrivateRoute";
import CircularIndeterminate from "@modules/Shared/CircularIndeterminate";
import Notifier from "@modules/Shared/Notifier";
import ErrorBoundary from "@modules/Shared/ErrorBoundary";
import { PrivatePages, RootPage } from "@modules/Shared/Constants";
import { ComponentWrapper } from "@modules/ComponentWrapper";
import { materialTheme } from "@themes/Styles/materialTheme";
import { SendPageView } from "@base/Analytics/SendPageView";
import { JwtExtractPayload } from "./Modules/utils/JwtExtraction";
import {
  validateJwtAndSetBarcode,
  setSystemUIByKitTypeId,
} from "@base/Actions/CustomerDetailsActions";
import { Barcode } from "@layout/Pages/Barcode";
import appSyncConfig from "./aws-appsync-config";
import awsCognitoConfig from "./aws-cognito-config";

const awsConfig = require("./aws-exports.json");

const amplifyConfig = Object.assign({}, awsConfig, awsCognitoConfig);
Amplify.configure(amplifyConfig);
Amplify.configure(appSyncConfig);
require("dotenv").config();

class App extends Component {
  state = {
    isLoaded: false,
    isDev: false,
    appName: null,
  };

  handleDataJwtUrl() {
    const query = new URLSearchParams(this.props.router.location.search);
    if (query.get("data")) {
      localStorage.setItem("urlDataJwt", query.get("data"));
    }
    if (query.get("appName")) {
      this.setState({ appName: query.get("appName") });
    }
    let jwtPersist = localStorage.getItem("urlDataJwt")
      ? localStorage.getItem("urlDataJwt")
      : null;
    let tokenPayload = jwtPersist ? JwtExtractPayload(jwtPersist) : null;
    this.props.dispatch(validateJwtAndSetBarcode(jwtPersist, tokenPayload));
    this.props.dispatch(setSystemUIByKitTypeId(tokenPayload));
  }

  componentDidMount() {
    Auth.signOut()
      .then(() => {
        this.props.dispatch(signUp());
      })
      .finally(() => {
        this.setState({ isLoaded: true });
      });
    this.handleDataJwtUrl();
    const location = this.props.router.location;
    SendPageView(location.pathname);
  }

  // Used to track page views for Google Analytics
  componentDidUpdate(previousProps) {
    const location = this.props.router.location;
    if (location !== previousProps.router.location) {
      SendPageView(location.pathname);
    }
  }

  render() {
    return (
      <div>
        {this.state.isLoaded ? (
          <div className="App">
            <ThemeProvider theme={materialTheme}>
              <ErrorBoundary>
                <MenuAppBar
                  {...this.props.register}
                  dispatch={this.props.dispatch}
                />
                <Switch>
                  {PrivatePages.map((page) => (
                    <PrivateRoute key={page.key} path={page.path}>
                      <ComponentWrapper
                        component={page.component}
                        {...this.props.register}
                        {...this.props.customerDetails}
                        title={page.title}
                        nextPage={page.nextPage}
                        alternateExit={page?.alternateExit}
                        alternatePreviousPage={page?.alternatePreviousPage}
                        previousPage={page.previousPage}
                        dispatch={this.props.dispatch}
                        stepNum={this.props.stepNum}
                      />
                    </PrivateRoute>
                  ))}
                  <Route exact path={RootPage}>
                    <ComponentWrapper
                      component={Barcode}
                      {...this.props.register}
                      {...this.props.customerDetails}
                      appName={this.state.appName}
                      title="BECOME A MEMBER"
                      nextPage="/create-account"
                      dispatch={this.props.dispatch}
                      stepNum={0}
                    />
                  </Route>
                  <Redirect exact from="/" to={RootPage} />
                  <Route exact path="/create-account">
                    <Authentication
                      {...this.props.register}
                      dispatch={this.props.dispatch}
                      previousPage={RootPage}
                    />
                  </Route>
                </Switch>
              </ErrorBoundary>
            </ThemeProvider>
          </div>
        ) : (
          <CircularIndeterminate />
        )}
        <Notifier />
      </div>
    );
  }
}

export default connect(mapStateToProps)(App);

function mapStateToProps(store) {
  return {
    register: store.register,
    customerDetails: store.customerDetails,
    router: store.router,
    stepNum: store.customerDetails.stepNum,
  };
}
