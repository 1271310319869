import React from "react";
import {
  ComponentContainerHeaderWrapper,
  ComponentContainerContentWrapper,
  HorizontalPadding,
  PrimaryTitle,
  SecondaryTitle,
  LinkText,
} from "./styles";

type ComponentContainerHeaderProps = {
  primaryTitle: string;
  secondaryTitle: string;
  activateMessage?: string;
  purchaseMessage?: string;
};

export const ComponentContainerHeader = React.memo(
  (props: ComponentContainerHeaderProps) => {
    const titleRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;

    React.useEffect(() => {
      titleRef.current && titleRef.current.focus();
    });

    return (
      <ComponentContainerHeaderWrapper ref={titleRef}>
        <ComponentContainerContentWrapper>
          <HorizontalPadding>
            <PrimaryTitle>{props.primaryTitle}</PrimaryTitle>
          </HorizontalPadding>
          <HorizontalPadding>
            <SecondaryTitle>{props.secondaryTitle} </SecondaryTitle>
          </HorizontalPadding>
          <SecondaryTitle>
            {props.activateMessage}
            <LinkText href="https://www.mydna.life/shop/" target="_blank">
              {props.purchaseMessage}
            </LinkText>
          </SecondaryTitle>
        </ComponentContainerContentWrapper>
      </ComponentContainerHeaderWrapper>
    );
  }
);
