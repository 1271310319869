import styled from "styled-components";
import { MyDnaTheme } from "@themes/Styles/Default";

export const ComponentContainerHeaderWrapper = styled.div``;

export const ComponentContainerContentWrapper = styled.div`
  margin: auto;
`;

export const PrimaryTitle = styled.p`
  color: ${MyDnaTheme.Colours.Headings.Heavy};
  font-size: ${MyDnaTheme.Header.PrimaryTitle.FontSize};
  line-height: ${MyDnaTheme.Header.PrimaryTitle.LineHeight};
  letter-spacing: ${MyDnaTheme.Header.PrimaryTitle.LetterSpacing};
  font-family: ${MyDnaTheme.Header.PrimaryTitle.FontFamily};

  text-transform: uppercase;
  margin: auto;
  padding-bottom: 20px;
  padding-top: 30px;

  text-align: center;
`;

export const SecondaryTitle = styled.p`
  color: ${MyDnaTheme.Colours.Base.Black};
  letter-spacing: ${MyDnaTheme.Header.SecondaryTitle.LetterSpacing};
  font-family: ${MyDnaTheme.Header.SecondaryTitle.FontFamily};

  text-align: center;
  margin: auto;
  white-space: pre-line;
`;

export const HorizontalPadding = styled.span`
  padding: 0px 20px 0px 20px;
`;

export const LinkText = styled.a`
  color: ${MyDnaTheme.Colours.Links};
  font-family: ${MyDnaTheme.Header.SecondaryTitle.FontFamily};
  font-weight: bold;
  text-decoration: none;
`;
