import React from "react";
import LocationSearchInput from "./LocationSearchInput";
import {GOOGLE_MAPS_URL} from "@modules/Shared/Configs";
import CircularIndeterminate from "@modules/Shared/CircularIndeterminate";

export default class LocationSearchInputWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            googleMapsReady: false
        };

    }
    componentDidMount() {
        this.loadGoogleMaps(() => {
            // Work to do after the library loads.
            this.setState({googleMapsReady: true});
        });
    }

    componentWillUnmount() {
        // unload script when needed to avoid multiple google scripts loaded warning
        this.unloadGoogleMaps();
    }

    loadGoogleMaps = callback => {
        const existingScript = document.getElementById("googlePlacesScript");
        if (!existingScript) {
            const script = document.createElement("script");
            script.id = "googlePlacesScript";
            script.src = GOOGLE_MAPS_URL;
            document.body.appendChild(script);
            //action to do after a script is loaded in our case setState
            script.onload = () => {
                if (callback) callback();
            };
        }
        if (existingScript && callback) callback();
    };

    unloadGoogleMaps = () => {
        let googlePlacesScript = document.getElementById("googlePlacesScript");
        if (googlePlacesScript) {
            googlePlacesScript.remove();
        }
    };

    render() {
        if (!this.state.googleMapsReady) {
            return <CircularIndeterminate />;
        }
        return (
            <LocationSearchInput 
                {...this.props} 
                dispatch={this.props.dispatch} 
                setFieldValue={this.props.setFieldValue} 
                countryCode={this.props.countryCode}
                onCountryChange={this.props.onCountryChange}
            />);
    }
}
