import React from "react";
import {Route, Redirect} from 'react-router';

export default function PrivateRoute({children, ...rest}) {

    const pagesWhereTheUserMayBeUnauthenticated = [
        "/confirmation"
    ];

    return (
        <Route
            {...rest}
            render={ 
                ({location}) => {
                    if (!children.props.auth.isAuthenticated && !pagesWhereTheUserMayBeUnauthenticated.includes(location.pathname) ) return <Redirect to={{pathname: "/", state: {from: location}}} />
                    return children;
                } 
            }
        />
    );
}
