import { Goals } from "@layout/Pages/Goals";
import { StripePayment } from "@layout/Pages/Payment";
import { AddressCapture } from "@layout/Pages/ContactDetails";
import { PersonalDetails } from "@layout/Pages/PersonalDetails";
import { OrderDetail } from "@layout/Pages/OrderDetails";
import { Confirmation } from "@layout/Pages/Confirmation";
import { Barcode } from "@layout/Pages/Barcode";
import { MedicationDetails } from "@layout/Pages/MedicationDetails";
import { GoalOption } from "@modules/Models/GoalOption";
import { PartnerType } from '@modules/Models/PartnerType';
export const RootPage = '/dna-collection';

export const PrivatePages = [
  { key: 1, path: '/personalDetails', component: PersonalDetails, title: 'Personal Details', nextPage: '/address', previousPage: RootPage },
  { key: 2, path: '/address', component: AddressCapture, title: 'Contact Details', nextPage: '/goals', previousPage: '/personalDetails' },
  { key: 3, path: '/goals', component: Goals, title: 'YOUR GOALS', nextPage: '/orderDetails', previousPage: '/address', alternateExit:"/medicationDetails"},
  { key: 4, path: '/orderDetails', component: OrderDetail, title: 'Order Details', nextPage: '/payment', previousPage: '/goals', alternateExit: '/confirmation', alternatePreviousPage: '/medicationDetails' },
  { key: 5, path: '/medicationDetails', component: MedicationDetails, title: 'Medication details', nextPage: '/orderDetails', previousPage: '/goals', alternateExit: '/confirmation' },
  { key: 6, path: '/payment', component: StripePayment, title: 'Payment', nextPage: '/confirmation', previousPage: '/orderDetails' },
  { key: 7, path: '/confirmation', component: Confirmation, title: "let\'s do this", previousPage: '/payment', hideInMenu: true },
];

export const Routes = {
    '20004': ['personalDetails', 'barcode', 'address', 'goals', 'orderDetails', 'medicationDetails', 'payment', 'confirmation'],
    'default':['personalDetails', 'barcode', 'address', 'goals', 'orderDetails', 'payment', 'confirmation']
}

export const Partners = {
    MyDNA: {
        name: "MyDNA"
    },
    DanceRX: {
        name: 'DanceRX'
    }
}

export const AllowedPatterns = {
    AlphabetNumberDotCommaCheck : /^[A-Za-z0-9,.'-]+$/,
    AlphabetNumberDotCommaCheckWithSpace : /^[A-Za-z0-9 ,.'-]+$/,
    CheckForInvalidSqlInjection: /^[^-<>=&%]/,
    StreetAddressCheckWithSpace : /^[A-Za-z0-9 ,.'-/\\]+$/,
    NumericCheck: /^\d+$/,
    AlphaNumericCheck : /^[A-Za-z0-9]+$/
}

export const ErrorMessages={
    AlphabetNumberDotCommaCheck : "Invalid values. The only values permitted are [A-Z 0-9 . , ' - ]",
    NumericCheck: "Invalid values. The only values permitted are [0-9]",
    StreetAddressCheckWithSpace : "Invalid characters entered. The only values permitted at [A-Z 0-9 . , ' - / \\ ]",
    AlphabetNumberDotCommaCheckWithSpace: "Invalid values. The only values permitted are [A-Z 0-9 . , ' - ]. You must have 2 or more words.",
    CheckForInvalidSqlInjection: "Invalid values in the text. Also, you must have 2 or more words.",
    AlphaNumericCheck: "Invalid values. The only values permitted are [A-Z 0-9 ]",
    CompleteAllFields: "Please complete all fields"
}

export const NutritionGoalOptions : GoalOption[] = [
    {
        goal:"Lose Weight",
        imgSelected:require("@assets/Goals/lose-weight-blue.png"),
        imgNotSelected:require("@assets/Goals/lose-weight-grey.png"),
        imgAlt: 'Lose Weight',
        key:2
    },
    {
        goal:"Maintain Weight",
        imgSelected:require("@assets/Goals/maintain-weight-blue.png"),
        imgNotSelected:require("@assets/Goals/maintain-weight-grey.png"),
        imgAlt: 'Maintain Weight',
        key:3
    },
    {
        goal:"Gain Weight",
        imgSelected:require("@assets/Goals/gain-weight-blue.png"),
        imgNotSelected:require("@assets/Goals/gain-weight-grey.png"),
        imgAlt: 'Gain Weight',
        key:1
    }
]

export const FitnessGoalOptions: GoalOption[] = [
    {
        goal:"Build Strength",
        imgSelected:require("@assets/Goals/build-strength-blue.png"),
        imgNotSelected:require("@assets/Goals/build-strength-grey.png"),
        imgAlt: 'Build Strength',
        key:2
    },
    {
        goal:"Get Toned",
        imgSelected:require("@assets/Goals/get-toned-blue.png"),
        imgNotSelected:require("@assets/Goals/get-toned-grey.png"),
        imgAlt: 'Get Toned',
        key:3
    },
    {
        goal:"Improve Fitness",
        imgSelected:require("@assets/Goals/improve-fitness-blue.png"),
        imgNotSelected:require("@assets/Goals/improve-fitness-grey.png"),
        imgAlt: 'Improve Fitness',
        key:1
    }
]

export const PageNames = {
  register : 'Create Account',
  verify: 'Verify Account',
  barcode : 'Bar Code',

}

export const SubscriptionOptionData = {
    "deliveryOptions": [
      {
        "id": "Free Shipping",
        "displayName": "Free Shipping",
        "initialChargeAmount": 0,
        "productCode": null,
        "priceId": null
      }
    ],
    "subscriptionOptionId": "prod_JkjyKZkh6LmmIw",
    "subscriptionFreightOptions": [
      {
        "id": "prod_JkjyKZkh6LmmIw",
        "name": "Test myDNA Subscription 12 months AU",
        "priceId": "price_1J7EUWHOjdeZ3Rxl7YDaNj6Z",
        "collectionKitProductCode": "prod_IEIvmPQgmiOoly",
        "collectionKitPriceId": "price_1HdqJUHOjdeZ3Rxl3vzHxwNA",
        "isDefault": true,
        "initialChargeAmount": 125,
        "dnaCollectionCharges": 69,
        "subscriptionPeriodDays": 60,
        "trialPeriodDays": 60,
        "subscriptionPeriodDaysCalculated": 360,
        "subscriptionInterval": 12,
        "subscriptionIntervalUnit": "Month",
        "subscriptionAmountPerPeriod": 125,
        "deliveryOptions": [
          {
            "id": "Free Shipping",
            "displayName": "Free Shipping",
            "initialChargeAmount": 0,
            "productCode": null,
            "priceId": null
          }
        ]
      },
      {
        "id": "prod_JkjxA8QmYDMCzD",
        "name": "Test myDNA Subscription 6 months AU",
        "priceId": "price_1J7ETlHOjdeZ3RxlOTVsHaLx",
        "collectionKitProductCode": "prod_IEIvmPQgmiOoly",
        "collectionKitPriceId": "price_1HdqJUHOjdeZ3Rxl3vzHxwNA",
        "isDefault": false,
        "initialChargeAmount": 75,
        "dnaCollectionCharges": 69,
        "subscriptionPeriodDays": 60,
        "trialPeriodDays": 60,
        "subscriptionPeriodDaysCalculated": 180,
        "subscriptionInterval": 6,
        "subscriptionIntervalUnit": "Month",
        "subscriptionAmountPerPeriod": 75,
        "deliveryOptions": [
          {
            "id": "Free Shipping",
            "displayName": "Free Shipping",
            "initialChargeAmount": 0,
            "productCode": null,
            "priceId": null
          }
        ]
      },
      {
        "id": "prod_IEIt3mnmXhkl7W",
        "name": "myDNA Monthly Subscription - AU",
        "priceId": "price_1HdqHzHOjdeZ3Rxlrtwbxu4o",
        "collectionKitProductCode": "prod_IEIvmPQgmiOoly",
        "collectionKitPriceId": "price_1HdqJUHOjdeZ3Rxl3vzHxwNA",
        "isDefault": false,
        "initialChargeAmount": 14.95,
        "dnaCollectionCharges": 69,
        "subscriptionPeriodDays": 30,
        "trialPeriodDays": 30,
        "subscriptionPeriodDaysCalculated": 30,
        "subscriptionInterval": 1,
        "subscriptionIntervalUnit": "Month",
        "subscriptionAmountPerPeriod": 14.95,
        "deliveryOptions": [
          {
            "id": "Australia Post",
            "displayName": "Australia Post",
            "initialChargeAmount": 8.95,
            "productCode": "prod_IEIuQzyXJukEon",
            "priceId": "price_1HkHjgHOjdeZ3RxlfCIZRoGq"
          }
        ]
      }
    ],
    "basePlan": {},
    "barcode": {}
}

export const SubscriptionOptionVIPData = {
    "deliveryOptions": [
      {
        "id": "Free Shipping",
        "displayName": "Free Shipping",
        "initialChargeAmount": 0,
        "productCode": null,
        "priceId": null
      }
    ],
    "subscriptionOptionId": "vip_au",
    "subscriptionFreightOptions": [
      {
        "id": "vip_au",
        "name": "myDNA - VIP - AU",
        "priceId": null,
        "collectionKitProductCode": null,
        "collectionKitPriceId": null,
        "isDefault": false,
        "initialChargeAmount": 0.0,
        "dnaCollectionCharges": 0.0,
        "subscriptionPeriodDays": 0,
        "trialPeriodDays": 0,
        "subscriptionPeriodDaysCalculated": 0,
        "subscriptionInterval": 0,
        "subscriptionIntervalUnit": "Day",
        "subscriptionAmountPerPeriod": 0.0,
        "deliveryOptions": []
      }
    ],
    "basePlan": {},
    "barcode": {}
}

export const SubscriptionOptionMegatronArrotexData = {
    "deliveryOptions": [
      {
        "id": "Free Shipping",
        "displayName": "Free Shipping",
        "initialChargeAmount": 0,
        "productCode": null,
        "priceId": null
      }
    ],
    "subscriptionOptionId": "prod_ISWHpxYb3Olvbn",
    "subscriptionFreightOptions": [
        {
          "id": "prod_ISWHpxYb3Olvbn",
          "name": "myDNA Monthly Subscription - Exclusive Arrotex Offer",
          "priceId": "price_1HrbEZHOjdeZ3RxlIhPSDYiZ",
          "collectionKitProductCode": null,
          "collectionKitPriceId": null,
          "isDefault": false,
          "initialChargeAmount": 14.95,
          "dnaCollectionCharges": 0.0,
          "subscriptionPeriodDays": 60,
          "trialPeriodDays": 60,
          "subscriptionPeriodDaysCalculated": 30,
          "subscriptionInterval": 1,
          "subscriptionIntervalUnit": "Month",
          "subscriptionAmountPerPeriod": 14.95,
          "deliveryOptions": []
        }
      ],
    "basePlan": {},
    "barcode": {}
}



export const NotificationSeverity = {
    SUCCESS: 'success',
    INFO: 'info',
    WARNING: 'warning',
    ERROR: 'error',
};


export const AuthResult = {
    SUCCESS: 'SUCCESS',
};

export const SnackbarAutoHideDuration = 2000;

export const CVCPlaceHolder= 'CVV/CVC';

export const AuthenticationTitle={
    SignUp:{title:'CREATE AN ACCOUNT', progress:0.5},
    VerifySignUp: {title:'VERIFY YOUR ACCOUNT', progress:1},
}

export const ConfirmationLinks={
    Account:'https://www.mydna.life/',
    myDNA: 'https://www.mydna.life/'
}

export const MyDnaSupportLinks={
    Barcode:'https://support.mydna.life/',
}

export const PrivacyPolicyUrl = "https://www.mydna.life/privacy-policy/";

export const FAQs = [
  {title: "Who is myDNA and how are we affiliated with FamilyTreeDNA?", 
  content: "myDNA and FamilyTreeDNA are sister companies under the parent business MYDNA Inc. myDNA are pioneers in personalized and precision wellness solutions and have been helping consumers make sense of their genetic information, and what to do with that information, for over a decade."},
  {title: "Why do I need to verify my FamilyTreeDNA kit number?", 
  content: "<p>Despite being part of the same business, both myDNA and FamilyTreeDNA’s commitment to privacy means we won’t share your data without consent.</p><p>myDNA’s wellness platform requires access to your genetic data  in order to analyze the appropriate genes and generate your results and recommendations. We’re only interested in 60 markers of the 1,000s available, so instead of sending you a brand new collection kit, we’ve streamlined this process by linking to your existing FamilyTreeDNA kit.</p>",
  },
  {title: "Incorrect Kit Number?", 
  content: "Please contact <a href='https://www.familytreedna.com/contact'>FamilyTreeDNA support.</a>"},
  {title: "Verification not working?", 
  content: "Please contact <a href='https://support.mydna.life/hc/en-us'>myDNA support.</a>"},
]

export const config = {
  viaCord: {
    appName: "mydna-pregnancy",
    redirectUrl: "https://www.viacord.com/genomic-testing/personalized-pregnancy-wellness/"
  }
}

export const APPCONSTANTS = {
  NEXT_PAGE: "NEXT_PAGE",
  PREVIOUS_PAGE: "PREVIOUS_PAGE",
  ZERO_PAYMENT: "ZERO_PAYMENT"
}