
// Checks if someone is at least 18 years old
// Returns true if underage
export const VerifyAge = (userBirthday: Date) => {
    var todayDate = new Date();
    todayDate.setHours(0,0,0,0)
    todayDate.setFullYear( todayDate.getFullYear() - 18 );

    var valueDate = new Date(userBirthday)
    valueDate.setHours(0,0,0,0) 

    return valueDate > todayDate;
}