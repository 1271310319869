import React from 'react';
import { Toolbar } from "@material-ui/core";
import { MenuBarWrapper, StyledAppBar, StyledImg } from './styles'
import WhiteMyDnaLogo from '@assets/mydna logo-white.png';

export function MenuAppBar() {
    return (
        <MenuBarWrapper>
            <StyledAppBar position="static">
                <Toolbar>
                    <StyledImg src={WhiteMyDnaLogo} alt={'mydna logo-white.png'} />
                </Toolbar>
            </StyledAppBar>
        </MenuBarWrapper>
    );
}
