import React from "react";
import ConfirmSignUp from "aws-amplify-react/lib/Auth/ConfirmSignUp";
import { Auth } from 'aws-amplify';
import { TextField } from "@material-ui/core";
import { confirmSignUp,confirmSignUpFailure, signedIn, RegisterActions} from "@base/Actions/RegisterActions";
import { AuthResult, AuthenticationTitle} from "@modules/Shared/Constants";
import { messages } from "@modules/Shared/Messages";
import { Redirect } from "react-router";
import ComponentButtons from "@modules/ComponentButtons";
import ComponentContainerHeader from "@modules/Header";
import { PrimaryTitles, SecondaryTitles} from "@modules/Shared/TitleTexts";
import AuthenticationStepper from "@modules/Shared/AuthenticationStepper";
import { ErrorLabel } from "@modules/ErrorLabel";
import { ConfirmSignUpWrapper } from './styles';

class MyDnaConfirmSignUp extends ConfirmSignUp {

    constructor(props) {
        super(props);
        this._validAuthStates = [RegisterActions.CONFIRM_SIGN_UP];
        this.state = {
            errorMessage: ""
        }
    }

    confirmSignUp(e) {
        e.preventDefault();
        const { username, password, marketingOptIn } = this.props.authData;

        Auth.confirmSignUp(
            this.props.authData?.username,
            this.inputs.code)
            .then(data => {
                if (data === AuthResult.SUCCESS) {
                    Auth.signIn(username, password).then(data => {

                        // Must store marketing opt-in data in local storage as any refreshes after this page will lose the state
                        localStorage.setItem('marketingOptIn', marketingOptIn)

                        this.props.dispatch(signedIn(data));
                        super.changeState(RegisterActions.SIGNED_IN);
                    }).catch(err=>{
                        this.setState({
                            errorMessage: messages.signUpFailed
                        })                    
                    })
                }
            })
            .catch(err => {
                if ( err.code === "UserNotFoundException") {
                    this.setState({
                        errorMessage: messages.userNotFound
                    })
                }
                else if ( err.code === "CodeMismatchException") {
                    this.setState({
                        errorMessage: messages.CodeMismatchException
                    })
                }
                else if(err.code === "confirmSignUp_failure"){
                    this.setState({
                        errorMessage: messages.signUpFailed
                    })
                }
                else {
                    this.setState({
                        errorMessage: err.message
                    })
                    this.props.dispatch(confirmSignUpFailure(err.message));
                }
            });
    }

    resend() {
        Auth.resendSignUp(this.props.authData?.username)
            .then(() => this.props.dispatch(confirmSignUp(messages.codeResent)))
            .catch(err => {
                this.setState({
                    errorMessage: err.message
                })
                this.props.dispatch(confirmSignUpFailure(err.message))
            });
    }

    showComponent(theme) {
        return (this.props.auth.isAuthenticated ?
            <Redirect to="/personalDetails" /> :
            <ConfirmSignUpWrapper>
                <AuthenticationStepper title={AuthenticationTitle.VerifySignUp.title} progress={AuthenticationTitle.VerifySignUp.progress}/>
                <form onSubmit={(e) => this.confirmSignUp(e)} id="confirm_sign_up">
                <ComponentContainerHeader primaryTitle={PrimaryTitles.Verify} secondaryTitle={SecondaryTitles.Verify}/>
                <div>
                    <TextField
                        id="code"
                        key="code"
                        name="code"
                        onChange={this.handleInputChange}
                        label="Verification Code *"
                        variant="outlined"
                    />
                </div>
                    <div>
                    {this.state.errorMessage && <div>
                        <ErrorLabel>{this.state.errorMessage}</ErrorLabel>
                    </div>}
                    <br/>
                    <ComponentButtons dispatch={this.props.dispatch} 
                        previousPage={this.props.previousPage}
                        singleButton={false} 
                        isSubmitting={false} 
                        backOnClick={()=>this.resend()} 
                        disableArrows={true}
                        submitText={"VERIFY"} 
                        backText={"RESEND CODE"}
                        currentPage="create-account"
                        barcode ={this.props.barcode} />
                    </div>
                </form>
            </ConfirmSignUpWrapper>
        );
    }
}

export default MyDnaConfirmSignUp;
