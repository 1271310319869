export const GenderOptions = [
    { 
        displayName: "Female", 
        id: "Female" 
    },
    { 
        displayName: "Male", 
        id: "Male" 
    },
    { 
        displayName: "Non-binary", 
        id: "Other" 
    }
];