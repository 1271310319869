import styled from 'styled-components';

export const StrikethroughText = styled.span`
    text-decoration: line-through;
    padding-right: 0.5rem;
`;

export const RowWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
`;

export const SubscriptionCostTextWrapper = styled.div<{isVisible: boolean}>`
    opacity : ${props => props.isVisible ? 1 : 0};
    transition: opacity 0.5s ease;
`;

export const DaysFreeText = styled.div`
    font-size: 11px;
`;

export const MonthsFreeText = styled.p`
    text-align: right;
    font-size: 11px;
    float: left;
    margin-top: -30px;
    width: 100%;
`;