
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { connect } from 'react-redux';
import { messages } from '@modules/Shared/Messages';
import TextInput from '@modules/TextInput';
import MyDnaButton from '@modules/MyDnaButton/MyDnaButton';
import { Formik, Form } from 'formik';
import { PromoCodeDetails } from '@modules/Models/PromoCodeDetails';

import { 
    ApplyButtonWrapper, 
    ComponentWrapper, 
    ConfirmationContentsWrapper, 
    ConfirmationIconWrapper, 
    ConfirmationTextWrapper, 
    ConfirmationWrapper, 
    FlexColumn, 
    FlexRow, 
    PromoCodeEntryWrapper, 
    PromoCodeInputWrapper, 
    SubText, 
    TextWrapper } from './styles'

export type PromoCodeProps = {
    store: any;
    promoCodeCallback:(entryText: string) => void;
    promoCodeDetails: PromoCodeDetails;
};  

const PromoConfirmationIcon = (isValid: boolean) => {
    return isValid ? <CheckIcon/> : <CloseIcon/>;
};

export const PromoCodeComponent = (props: PromoCodeProps) => {

    const [promoCodeFormData, setPromoCodeFormData] = React.useState<string>("");
    const promoCodeDetails = props.promoCodeDetails;

    const getConfirmationText = (promoCodeDetails: PromoCodeDetails) => {
        if (promoCodeDetails.isValid) return messages.validCouponCode[0] + promoCodeDetails.promoCode + messages.validCouponCode[1];
        if (promoCodeDetails.promoCode == "") return messages.enterCouponCode;
        return messages.invalidCouponCode;
    }

    return (
        <ComponentWrapper>
            <Formik
                initialValues={{
                    promoCodeDetails: promoCodeFormData
                }}
                onSubmit={(formData, { setSubmitting }) => {
                    props.promoCodeCallback(promoCodeFormData);
                    setSubmitting(false);
                }}>
                {({ setSubmitting, setFieldValue, values }) => { 
                    
                const UpdatePromoCodeText = (text: string) => {
                    const promoCodeText = text.toUpperCase().trim();
                    setFieldValue('promoCodeEntry', promoCodeText);
                    setPromoCodeFormData(promoCodeText);
                }

                return (
                <Form id="promo_code_details" style={{width: "23rem"}}>
                    <FlexRow>
                        <PromoCodeEntryWrapper>
                            <FlexColumn>
                                <PromoCodeInputWrapper>
                                    <TextInput 
                                        name="promoCodeEntry"
                                        label="Enter promo code"
                                        className={"input-control-short"}
                                        value={promoCodeFormData}
                                        setFieldValue = { setFieldValue }
                                        onChange={(event: any) => {
                                            UpdatePromoCodeText(event.target.value);
                                        }}
                                    />
                                </PromoCodeInputWrapper>
                                <TextWrapper>
                                    <SubText>You can only apply one coupon at a time.</SubText>
                                </TextWrapper>
                            </FlexColumn>
                        </PromoCodeEntryWrapper>
                        <ApplyButtonWrapper>
                            <MyDnaButton
                                onClick = {() => {setSubmitting(true)}}
                                buttonType = {'ghost'}
                                type = 'submit'
                                fullwidth
                            >
                                Apply
                            </MyDnaButton>
                        </ApplyButtonWrapper>
                    </FlexRow>
                </Form>)}}
            </Formik>
            <ConfirmationWrapper>
                <ConfirmationContentsWrapper isVisible={promoCodeDetails?.isValid !== undefined}>
                    <ConfirmationIconWrapper>
                        { PromoConfirmationIcon(promoCodeDetails?.isValid) }
                    </ConfirmationIconWrapper>
                    <ConfirmationTextWrapper>
                        { promoCodeDetails && getConfirmationText(promoCodeDetails) }
                    </ConfirmationTextWrapper>
                </ConfirmationContentsWrapper>
            </ConfirmationWrapper>
        </ComponentWrapper>
    );
};

const mapStateToProps = (state: any) => {
    return { 
        promoCodeDetails: state.customerDetails.promoCodeDetails 
    };
};

export default connect(mapStateToProps)(PromoCodeComponent);