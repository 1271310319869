import React from "react";
import { Auth } from 'aws-amplify';
import { SignUp } from "aws-amplify-react";
import { TextField } from "@material-ui/core";
import { confirmSignUp, RegisterActions, signUpFailure } from "@base/Actions/RegisterActions";
import { messages } from "@modules/Shared/Messages";
import { AuthenticationTitle, PrivacyPolicyUrl, PageNames} from "@modules/Shared/Constants";
import { PrimaryTitles, SecondaryTitles} from "@modules/Shared/TitleTexts";
import ComponentContainerHeader from "@modules/Header";
import ComponentButtons from "@modules/ComponentButtons";
import AuthenticationStepper from "@modules/Shared/AuthenticationStepper";
import { ErrorLabel } from '@modules/ErrorLabel';
import { CheckboxInput } from "@modules/CheckboxInput";

import { FlexColumn, SmallerText, CheckboxBlock, CheckboxCheckWrapper, CheckboxTextWrapper } from './styles'; 

export default class MyDnaSignUp extends SignUp {

    constructor(props) {
        super(props);
        this._validAuthStates = [RegisterActions.SIGN_UP];
        this.state={
            privacyPolicy: false,
            marketingOptIn: false
        }
    }

    signUp(e) {
        e.preventDefault();

        const email = this.inputs.email?.trim()
        const password = this.inputs.password?.trim()
        const confirmPassword = this.inputs.confirmPassword?.trim()
        const privacyPolicy = this.state.privacyPolicy;
        const marketingOptIn = this.state.marketingOptIn;

        var errorMessage = "";

        if (password !== confirmPassword) errorMessage = messages.passwordDoesNotMatch;
        if (!privacyPolicy) errorMessage = messages.privacyPolicyError;

        if (errorMessage != "") {
            this.setState({
                errorMessage: errorMessage
            })
            this.props.dispatch(signUpFailure(errorMessage));
            return;
        }

        Auth.signUp({
            username: email,
            password: password,
            attributes: {
                email: email
            },
        })
            .then(data => {
                this.setState({
                    errorMessage: null
                })
                if (!data.userConfirmed) {
                    if (data.codeDeliveryDetails.DeliveryMedium === 'EMAIL') {
                        super.changeState(RegisterActions.CONFIRM_SIGN_UP,
                            { username: email, password: password, marketingOptIn: marketingOptIn });
                        this.props.dispatch(confirmSignUp(messages.pleaseCheckYourEmail));
                    }
                }
            })
            .catch(err => {
                if (err.code === "UsernameExistsException") {
                    this.props.dispatch(signUpFailure(messages.userExistsErrorMessage));
                    this.setState({
                        errorMessage: messages.userExistsErrorMessage
                    })
                }else if(err.code === "InvalidPasswordException"){
                    this.setState({
                        errorMessage: messages.invalidPassword
                    })
                }
                else if(err.code === "InvalidParameterException"){
                    if (err.message.includes("password")) {
                        this.setState({
                            errorMessage: messages.invalidPassword
                        })
                    }else{
                        this.setState({
                            errorMessage: err.message
                        })
                    }
                }
                else{
                    this.setState({
                        errorMessage: "Sign up failed"
                    })
                    this.props.dispatch(signUpFailure(err.message));
                }
            });
    }

    

    showComponent(theme) {

        return (
            <form onSubmit={(e) => this.signUp(e)} id="sign_up">
                <AuthenticationStepper title={AuthenticationTitle.SignUp.title} progress={AuthenticationTitle.SignUp.progress}/>
                <ComponentContainerHeader primaryTitle={PrimaryTitles.Create} secondaryTitle={SecondaryTitles.Create}/>
                <FlexColumn>
                    <TextField
                        id="email"
                        key="email"
                        name="email"
                        onChange={this.handleInputChange}
                        label="Email Address"
                        variant="outlined"
                        required
                        inputProps={{ maxLength: 41 }}
                    />
                    <TextField
                        id="password"
                        key="password"
                        name="password"
                        onChange={this.handleInputChange}
                        type="password"
                        label="Password"
                        variant="outlined"
                        required
                    />
                    <TextField
                        id="confirmPassword"
                        key="confirmPassword"
                        name="confirmPassword"
                        onChange={this.handleInputChange}
                        type="password"
                        label="Confirm Password"
                        variant="outlined"
                        required
                    />
                    <SmallerText>{messages.invalidPassword}</SmallerText>
                    <CheckboxBlock>
                        <CheckboxCheckWrapper>
                            <CheckboxInput
                                id="privacyPolicyTermsAndConditions"
                                key="privacyPolicyTermsAndConditions"
                                name="privacyPolicyTermsAndConditions"
                                required
                                onChange={(event)=>this.setState({
                                    privacyPolicy: !this.state.privacyPolicy,
                                    errorMessage: null
                                })}
                            />
                        </CheckboxCheckWrapper>
                        <CheckboxTextWrapper>
                            <span>
                                {`${messages.privacyPolicy[0]} `}
                                <a
                                    href={PrivacyPolicyUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {messages.privacyPolicy[1]}
                                </a>
                            </span>
                        </CheckboxTextWrapper>
                    </CheckboxBlock>
                    <CheckboxBlock>
                        <CheckboxCheckWrapper>
                            <CheckboxInput
                                id="marketingOptIn"
                                key="marketingOptIn"
                                name="marketingOptIn"
                                required
                                onChange={(event)=>this.setState({
                                    marketingOptIn: !this.state.marketingOptIn,
                                    errorMessage: null
                                    })}
                            />
                        </CheckboxCheckWrapper>
                        <CheckboxTextWrapper>
                            <span>
                                {messages.marketingOptIn}
                            </span>
                        </CheckboxTextWrapper>
                    </CheckboxBlock>
                </FlexColumn>
                {this.state.errorMessage && 
                <ErrorLabel>
                    {this.state.errorMessage}
                </ErrorLabel>
                }
                <ComponentButtons dispatch={this.props.dispatch} previousPage={this.props.previousPage} singleButton={false} isSubmitting={false} submitText={"CREATE ACCOUNT"} currentPage="create-account"/>
            </form>
        );
    }
}
