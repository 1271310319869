import React, {Component} from 'react';
import StepperComponent  from "../Shared/StepperComponent";
import { TitleWrapper } from './styles';

class ComponentWrapper extends Component {

    render() {
        return(
            <>
                <TitleWrapper>
                    <h1>
                        {this.props.title}
                    </h1>
                </TitleWrapper>
                <StepperComponent stepNum ={this.props.stepNum }/>
                {React.createElement(this.props.component, this.props)}
            </>
        );
    }
}

export default ComponentWrapper;
