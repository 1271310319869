import styled from 'styled-components';
import { MyDnaTheme } from '@themes/Styles/Default';

export const BarcodeContentWrapper = styled.div`
    max-width: ${MyDnaTheme.ContentWidth};
    margin: auto;
    input:disabled {
        background: ${MyDnaTheme.Colours.Input.Disabled};
        color: ${MyDnaTheme.Colours.Base.Black};
        font-size: 1.2rem;
        padding-top: 1.03rem;
        padding-bottom: 1.03rem;
    }
    .barcode-disabled {
        label {
            display: none;
        }
    }
    .MuiTextField-root {
        margin-left: 0;
        .MuiFormHelperText-root.Mui-error {
            display: none;
        }
    }
`;

export const FormContentsWrapper = styled.div`
    margin: 1rem;
    width: 21rem;
    margin: auto;
`;

export const ExpandedFormContentsWrapper = styled.div`
    margin-top: -10px;
`;

export const StyledBarcodeText = styled.div`
    font-size: 12px;
    padding: 0.5rem 0 1rem 0;
    color: ${ MyDnaTheme.Colours.Primary };
    cursor: pointer;
`;

export const SideBySideWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

export const SideBySideContentWrapper = styled.div`
    display: flex;
    width: 50%;
    margin: 0.5rem;
`;

export const VerifyButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 1rem;
`;

export const VerifyButtonSizeRestrictor = styled.div`
    height: 3.5rem;
`;

export const AnimationImage =  styled.img`
    max-width: 24.875rem;
    @media (max-width: ${MyDnaTheme.MediaSizes.Smaller}){
        max-width: 20rem;
    }
`;

export const TitleWpr =  styled.div`
    max-width: 40rem;
    margin: auto;
    @media (max-width: ${MyDnaTheme.MediaSizes.Smaller}){
        max-width: 21rem;
    }
`;

export const ErrorMessageWpr =  styled.div`
    max-width: 40rem;
    margin: auto;
    @media (max-width: ${MyDnaTheme.MediaSizes.Smaller}){
        max-width: 21rem;
    }
`;