import { push } from 'connected-react-router';
import pageRoutings from "./routingsList";

export const myDNARouter = (props, nextOrPrevious, currentPage, condition ) => {
    const kitTypeId = getKitId(props);
    const currentRoutesList = getRoutes(kitTypeId);
    let routingPagePath = getRoutingPagePath(nextOrPrevious, currentRoutesList, currentPage, condition);
    props.dispatch(push( routingPagePath));
}

export const getRoutingPagePath = (nextOrPrevious, currentRoutesList, currentPage, condition) => {
    let routingPagePath = "/";
    if(nextOrPrevious === "NEXT_PAGE") {
        if(currentPage === "orderDetails" && condition ===  "ZERO_PAYMENT"){
            routingPagePath = routingPagePath + currentRoutesList[currentRoutesList.length - 1];
        } else {
            const nextPagePath = getNextPageName(currentPage, currentRoutesList);
            routingPagePath = routingPagePath + nextPagePath;
        }
    } else {
        const prevPagePath = getPreviousPageName(currentPage, currentRoutesList);
        routingPagePath = routingPagePath + prevPagePath;
    }
    return routingPagePath;
}

export const getKitId = (props) => {
    const originalKitTypeId = props?.barcode?.kitTypeId || props?.customerDetails?.barcode?.kitTypeId || "000000";
    return String(originalKitTypeId);
}

export const getRoutes = (kitTypeId) => {
    const routesListObj = pageRoutings.KITBASED.find((x) => x.kitTypeIds.includes(kitTypeId)) || pageRoutings.KITBASED.find((x) => x.kitTypeIds.includes("000000"))
    return routesListObj.routings;
}

export const getPreviousPagePath = (props, currentPage) => {
    const kitTypeId = getKitId(props);
    const currentRoutesList = getRoutes(kitTypeId);
    return "/" + getPreviousPageName(currentPage, currentRoutesList);
}

export const getNextPageName = (currentPage, currentRoutesList) => {
    const currentPageIndex = currentRoutesList.indexOf(currentPage);
    return currentRoutesList[currentPageIndex+ 1];
}

export const getPreviousPageName = (currentPage, currentRoutesList) => {
    const currentPageIndex = currentRoutesList.indexOf(currentPage);
    return currentRoutesList[currentPageIndex - 1];
}
