import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CountryList } from "../Shared/CountriesList";
import { useField } from "formik";
import {makeStyles} from "@material-ui/core/styles";

export default function CountrySelect(props) {
  var defaultCountryString = props.defaultValue == "" ? "Australia" : props.defaultValue;
  var defaultValue = CountryList.coutryList.filter(element => element.name === defaultCountryString);

  const [value, setValue] = React.useState(defaultValue[0]);
  const [field, meta] = useField(props.name);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const useStyles = makeStyles(theme => ({
    autoComplete: {
      marginLeft:-15
    },
  }));
  const classes = useStyles();
  return (
      <Autocomplete
        value={value}
        options={CountryList.coutryList}
        autoHighlight
        autoComplete={false}
        getOptionLabel={(option) => option.name}
        onChange={(event, value) => {
          props.setFieldValue(props?.name, value?.name);
          setValue(value)
          props.setCountry(value);
        }}
        className={classes.autoComplete}
        renderOption={(option) => (
          <React.Fragment>{option.name}</React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...field}
            {...params}
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: "off", // disable autocomplete and autofill
            }}
            error={!!errorText}
            helperText={errorText}
          />
        )}
      />
  );
}
