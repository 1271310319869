const ErrorMsgHandler = (err) => {
  let errorMsg = err.errors && err.errors[0];
  if (errorMsg && errorMsg.message) {
    errorMsg = errorMsg.message; // AppSync error message
  } else if (err.message) {
    errorMsg = err.message;
  } else {
    errorMsg = "Something went wrong! Please try later"; //Fallback error message
  }
  return errorMsg;
};

export default ErrorMsgHandler;