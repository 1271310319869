import styled from 'styled-components';
import { MyDnaTheme } from '@themes/Styles/Default';

export const SubscriptionPlan = styled.div`
     .subscription-option-card {
        padding: 0 0 5px !important;
        text-transform: none;
        text-align: center;
        height: 100%;
        display: flex;
        flex-direction: column;
        box-shadow: 0 0 3.36629px rgba(0, 0, 0, 0.2);
        border-radius: 0px 0px 4px 4px;
        min-width: 320px;
        @media (min-width: ${MyDnaTheme.MediaSizes.Small}) {
            width: 360px;
        }
        
        .right-gap {
            margin-right: 6px;
        }

        .value-indicator {
            background: #E72076;
            border-radius: 3px;
            padding: 4px 15px;
            margin-top: 3px;
            height: auto;
            font-weight: 900
          }

        .subscription-amount{
            font-size: 38px;
            font-weight: 900;
            font-family: 'AvenirBlack', sans-serif;
         }
    }
`;

export const DarkText = styled.div`
opacity: 0.54;
line-height: 0.8rem;
padding-bottom: 0.5rem;
`;

export const ListStyle = styled.ul`
color: #707070;
padding-bottom: 0.2rem;
margin-bottom: 6px;
text-align: left;
font-size: 12px;
margin-top: 5px;
& > li {
    margin-bottom: 4px;
}
`;

export const Dollar = styled.sup`
    position: relative;
    top: 4px;
    font-size: 22px;
`;

export const DollarSmall = styled.sup`
    font-size: 12px;
    font-weight: 900;
    margin-left: 4px;
`;

export const PriceSecondary = styled.span`
    font-size: 21px;
    font-weight: 900;
`;

export const ChipGapFiller = styled.span`
    height: 19px;
    margin-top: 3.5px;
    display: inline-block;
`;

export const DarkColoredText = styled.p`
    color: rgba(0, 0, 0, 0.54);
    font-size: 13px;
    line-height: 0.8;
    font-family: 'AvenirRoman', sans-serif;
`;

export const BlueColoredText = styled.span`
    color: #30B5E8;
    line-height: normal;
    font-family: 'AvenirRoman', sans-serif;
`;

export const SubscriptionTitle = styled.h2`
    font-weight: 500;
    font-size: 22px;
    font-family: 'AvenirRoman', sans-serif;
    background: #30B5E8;
    color: #fff;
    display: block;
    padding: 16px 24px;
    border-radius: 4px 4px 0 0;
    margin: 0;
    @media (max-width: ${MyDnaTheme.MediaSizes.Smaller}){
        font-size: 20px;
    }
    @media (max-width: ${MyDnaTheme.MediaSizes.Smallest}){
        font-size: 19px;
    }
`;

export const StrongDarkText = styled.span`
    font-weight: 900;
    font-size: 14px;
    color: #081F2D;
`;

export const SubscriptionFrequency = styled.span`
    font-size: 17px;
    font-weight: 900;
`;

export const WrapperElement = styled.div`
    padding: 3px 30px 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    & .main-section-wpr {
        display: flex;
        height: 100%;
        flex-direction: column;
        flex-grow: 1;
        margin-bottom: auto;
    }
    & .foo-section {
        margin-top: auto;
    }

`;