import styled from "styled-components";
import { MyDnaTheme } from "@themes/Styles/Default";

export const AccordionWrapper = styled.div`
    max-width: 35.5rem; 
    margin: 3rem auto 0;
    @media (max-width: ${MyDnaTheme.MediaSizes.Smaller}){
        max-width: 21rem;
        .MuiExpansionPanelSummary-content {
            margin: 0;
        }
        .MuiPaper-root.MuiExpansionPanel-root {
            margin: 0;
        }
    }
    .expand-icon {
        fill: #707070;
        transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
        transition: 0.25s;
        float: left;
    }
    .MuiExpansionPanel-root {
        margin-bottom: 1.25rem;
        &:before {
            content: none;
        }
        @media (max-width: ${MyDnaTheme.MediaSizes.Smaller}){
            margin-bottom: .8rem;
        }
    }
    .faq-title {
        padding: 0;
    }
    .Mui-expanded {
        .expand-icon {
            transform: rotate(180deg) scaleX(1.2);
            @media (max-width: ${MyDnaTheme.MediaSizes.Smaller}){
                transform: rotate(180deg) scaleX(1);
            }
        }
    }
`;

export const Header = styled.h4`
    font-size: 1.25rem;
    font-family: "AvenirBlack", sans-serif;
    margin: 0 0 1.5rem;
    font-weight: normal;
    @media (max-width: ${MyDnaTheme.MediaSizes.Smaller}){
        font-size: 0.75rem;
        margin-bottom: .6rem;
    }
`;

export const Title = styled.h4`
    font-size: 1rem;
    font-family: "AvenirBlack", sans-serif;
    font-weight: normal;
    margin: 0;
    text-align: left;
    .expand-icon {
        transform: scaleX(1.2);
    }
    @media (max-width: ${MyDnaTheme.MediaSizes.Smaller}){
        font-size: 0.75rem;
        .expand-icon {
            transform: scaleX(1);
            margin-top: -4px;
        }
    }
`;

export const ExpansionPanelContent = styled.div`
    font-size: 1rem;
    font-family: "AvenirRoman", sans-serif;
    text-align: left;
    line-height: 1.25rem;
    margin: 0 1rem;
    @media (max-width: ${MyDnaTheme.MediaSizes.Smaller}){
        font-size: 0.6rem;
        line-height: .75rem;
        margin-bottom: 0.8rem;
    }
`;
