import styled from "styled-components";
import { MyDnaTheme } from "@themes/Styles/Default";

export const InsightsWpr = styled.div`

  @media (max-width: ${MyDnaTheme.MediaSizes.Small}) {
    margin: 0px 11px 0px 12px;
  }
  .insights {
    border-radius: 12px;
    border: 1px solid ${MyDnaTheme.Colours.Headings.Light};
    padding: 18px 28px;
    position: relative;
    text-align: left;
    max-width: 718px;
    margin: 0 auto 36px;
    @media (max-width: ${MyDnaTheme.MediaSizes.Small}) {
        padding: 19px 8px 12px;
    }
  }
  .includes-chip {
    font-family: "AvenirBlack", sans-serif;
    border-radius: 3px;
    color: ${MyDnaTheme.Colours.Headings.Heavy};
    font-weight: 900;
    justify-content: flex-start;
    @media (max-width: ${MyDnaTheme.MediaSizes.Small}) {
        font-size: 11.8px;
        width: 100%;
    }
  }
`;

export const Heading = styled.h3`
  color: ${MyDnaTheme.Colours.Headings.Heavy};
  font-size: 22px;
  font-family: "AvenirMedium", sans-serif;
  font-weight: normal;
  margin: 0;
  @media (max-width: ${MyDnaTheme.MediaSizes.Small}) {
    font-size: 15.8px;
    letter-spacing: -0.17px;
  }
`;

export const Title = styled.h2`
  color: ${MyDnaTheme.Colours.Primary};
  font-size: 24px;
  font-family: "AvenirBlack", sans-serif;
  margin: 8px 0;
  padding-right: 7.25rem;
  font-weight: normal;
  @media (max-width: ${MyDnaTheme.MediaSizes.Small}) {
    font-size: 16px;
    padding-right: 0;
  }
`;

export const InsightsList = styled.ul`
  font-family: "AvenirMedium", sans-serif;
  color: ${MyDnaTheme.Colours.Input.Heavy};
  padding: 0;
  margin: 0 0 10px 18px;
`;

export const ListItem = styled.li`
  font-size: 12.6px;
  line-height: 17.6px;
  @media (max-width: ${MyDnaTheme.MediaSizes.Small}) {
    font-size: 11.8px;
  }
`;

export const Price = styled.div`
  position: absolute;
  top: 51px;
  right: 48px;
  color: ${MyDnaTheme.Colours.Primary};
  font-family: "AvenirBlack", sans-serif;
  font-weight: 900;
  @media (max-width: ${MyDnaTheme.MediaSizes.Small}) {
    top: 12px;
    right: 14px;
  }
`

export const Currency = styled.sup`
    font-size: 21px;
    position: relative;
    top: -6px;
    letter-spacing: -0.25px;
    @media (max-width: ${MyDnaTheme.MediaSizes.Small}) {
        font-size: 14.36px;
        top: -2px;
        letter-spacing: -0.17px;
    }
`
export const Amount = styled.span`
    font-size: 37.8px;
    letter-spacing: -0.59px;
    @media (max-width: ${MyDnaTheme.MediaSizes.Small}) {
        font-size: 25.86px;
        letter-spacing: -0.4px;
    }
`
