import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './Themes/Styles/styles.scss';
import App from './App';
import {ConnectedRouter} from 'connected-react-router'
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux"
import configureStore, {history} from "./store";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

import { gtagDimensions } from '@base/Analytics/SendPageView';
import { GlobalStyle } from '@themes/Styles/Default';

gtagDimensions();
const store = configureStore();

/* Google Analytics */
const gtmArgs = { gtmId: process.env.REACT_APP_ANALYTICS_GTM_ID };
TagManager.initialize(gtmArgs);
ReactGA.initialize(process.env.REACT_APP_ANALYTICS_TRACKING_ID);

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <GlobalStyle />
                <App/>
            </MuiPickersUtilsProvider>
        </ConnectedRouter>
    </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
