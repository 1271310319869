import { barcodeDetailsCaptured, barcodeDetails, setRoute, setSystemUIByKitTypeId} from "@base/Actions/CustomerDetailsActions";
import { messages } from "@modules/Shared/Messages";
import {myDNARouter} from '@modules/utils/myDNARouter';
import { APPCONSTANTS } from "@modules/Shared/Constants";

import {CUSTOMER_MICROSERVICE_UNAUTH_BASE_URL} from "@modules/Shared/Configs";
export const clearBarcodeDetails =async (props)=>{
  props.dispatch(barcodeDetails(props));
}

export const setDefaultBarcodeDetails = async (props) => {
  var barcodeDetail= {
    displayName: 'myDNA Personalized Wellness Collection Kit'
  };

  props.dispatch(barcodeDetailsCaptured(props, barcodeDetail))
}
export const SubmitHandler = async (props, setFieldError, formData, setSubmitting, setErrorMessage, setSuccessMessage, setBarcodeValidation, barCode) => {
    fetchGetBarcodeDetails(formData?.barcode)
        .then((response)=> response.json())
        .then((response)=>{
            if (response?.errorMessage.length>0) {
                setFieldError('barcode', response?.errorMessage.join(','));
                setErrorMessage(response?.errorMessage.join(','));
                setBarcodeValidation(false);
            } else {
                if (response.barcodeStatus === "Active") {
                    var barcodeDetail= {
                      barcode: formData?.barcode,
                      partner: response.partnerName,
                      barcodeStatus: response.barcodeStatus,
                      isStaffKitType: response.isStaffKitType,
                      isVipKitType: response.isVipKitType,
                      isLegacyCustomer: response.isLegacyCustomer,
                      kitTypeName : response.kitType,
                      displayName : response.displayName,
                      kitTypeId : response.kitTypeId,
                      isOrganisationSelectionRequired : response.isOrganisationSelectionRequired,
                      showMedicationsSelection: response?.showMedicationsSelection ?? false
                    };
                    props.dispatch(barcodeDetailsCaptured(props, barcodeDetail));
                    var successMessage = messages.barcodeSuccessMessage;
                    successMessage = successMessage.replace("<Product Name>", response.displayName)
                    setSuccessMessage(successMessage)
                    setBarcodeValidation(false);
                    const data = {aud: String(response.kitTypeId)}
                    props.dispatch(setSystemUIByKitTypeId(data));
                } else {
                    clearBarcodeDetails(props)
                    setSuccessMessage(null);
                    setBarcodeValidation(false);
                    setFieldError('barcode', messages.InactiveBarcode)
                    setErrorMessage(messages.InactiveBarcode);
                }
            }
        }).catch((err)=>{
          setErrorMessage(err?.message)
          setFieldError('barcode', err?.message)
        }).finally(()=> {
          setSubmitting(false);
          setBarcodeValidation(false);
        });
};


export const submitBarcodeDetails = async (props) => {
  myDNARouter(props, APPCONSTANTS.NEXT_PAGE, "dna-collection")
};

const fetchGetBarcodeDetails= async (barcode)=>{
  return fetch(`${CUSTOMER_MICROSERVICE_UNAUTH_BASE_URL}/RegisterMicroService/Register/GetBarcodeDetails?barcode=${barcode}`)
}

export const decideRoute = (props, kitTypeId) =>{
  props.dispatch(setRoute[`${kitTypeId}`] ?? setRoute['default'])
}

