import React from "react";

export const CardTypes = () => {
  return (
    <div>
      <img
        src={require("@assets/Visa.png")}
        style={{ width: 60, height: 40, padding: 5 }}
        alt="Logo"
      />
      <img
        src={require("@assets/mc_symbol.png")}
        style={{ width: 60, height: 40, padding: 5 }}
        alt="Logo"
      />
      <img
        src={require("@assets/AXP_BlueBoxLogo.jpg")}
        style={{ width: 50, height: 40, padding: 5 }}
        alt="Logo"
      />
    </div>
  );
};
