
// todo: Add tests for these services

export const deleteItem = (row, selectedMedications) => {
    selectedMedications = selectedMedications.filter((x) => {
        return x.name !== row.name
    })
    return selectedMedications
}

export const addItem = (medication, selectedMedications) => {
    !selectedMedications.some(item => item.name === medication.name) && selectedMedications.push(medication)
    return new Set(selectedMedications)
}