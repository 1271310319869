import React from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import { useState } from "react";

type ExpansionPanelComponentProps = { 
  expanded?: boolean; 
  summary: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; 
  content: string | number | boolean | {} | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactNodeArray | React.ReactPortal | null | undefined; 
}

const ExpansionPanelComponent = (props: ExpansionPanelComponentProps) => {

  const [expanded, setExpanded] = useState(true);
  React.useEffect(()=>{
    setExpanded(props.expanded ?? true)
  },[props.expanded])

  return (
    <ExpansionPanel
      expanded={expanded}
      className="MuiFormControl-root"
      style={{ boxShadow: "none", width: "21rem" }}
      onChange={() => {
        setExpanded(!expanded);
      }}
    >
      <ExpansionPanelSummary>{props.summary}</ExpansionPanelSummary>
      {props.content}
    </ExpansionPanel>
  );
  
};

ExpansionPanelComponent.propTypes = {};

export default ExpansionPanelComponent;
