import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createFilterOptions }  from "@material-ui/lab/Autocomplete";
import { useField } from "formik";
import { makeStyles } from "@material-ui/core/styles";

export default function OrganisationWithPartnerSelectOptions(props) {
  const [field, meta] = useField("selectedOrganisation");
  const errorText = meta.error && meta.touched ? meta.error : "";
  const useStyles = makeStyles(theme => ({
    autoComplete: {
      marginLeft:-15
    },
  }));
  const classes = useStyles();

  const [options, setOptions] = React.useState(props.organisationOptions);
  const [selectedOrganisation, setSelectedOrganisation] = React.useState(props.selectedOrganisation);

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option.searchKey,
  });

  const renderOptions = (option) => {
    return (
      <div class="multilineOptions">
        {option.name}
        <div class="sub">
          {option.address.addressLine1} {option.address.addressLine2} {option.address.suburbCity}
        </div>
        <div class="sub">
          {option.address.stateProvince} {option.address.postalCode} {option.address.country}
        </div>
      </div>
    )
  };

  return (
    <div>
      <label className="order-details-label body-content">{props.label}</label>
      <Autocomplete
        multiline={true}
        numberOfLines={3}
        filterOptions={filterOptions}
        getOptionSelected={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        options={options}
        onChange={(event, newValue) => {
          props.setFieldValue("selectedOrganisation", newValue);
          setSelectedOrganisation(newValue);
        }}
        className={classes.autoComplete}
        renderInput={(params) => (
          <TextField
            {...field}
            {...params}
            label={"Search and select a store *"}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              autoComplete: "off", // disable autocomplete and autofill
            }}
            error={!!errorText}
            helperText={errorText}
          />
        )}
        renderOption={(option) => renderOptions(option)}
      />
      <p className={'medication-details-guideline horizontal-padding body-content width-meds'}>
        We'll notify you when your comprehensive myDNA medications report is ready to collect from this pharmacy. Your pharmacist has the expertise and medical knowledge to 
        explain your detailed DNA report and will discuss your DNA results with you.
      </p>
    </div>
  );
}
