// Parameters:
// string - the string to have its delimiters replaced
// vars - the list of vars used the replace the delimiters. Must be in the correct order that they appear in the string
// delimiter - {} by default, will be replaced with vars
export function StringReplace(string: string, vars: string[], delimiter = "{}") {
    var replacedString = string;

    for(var i = 0; i < vars.length; i++) {
        replacedString = replacedString.replace(delimiter, vars[i]);
    }

    return replacedString;
};