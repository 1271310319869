/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getConfigItems = /* GraphQL */ `
  query GetConfigItems($locale: String, $configItemName: String!) {
    getConfigItems(locale: $locale, configItemName: $configItemName) {
      locale
      name
      value
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser {
    getUser {
      id
      userName
      firstName
      lastName
      email
      locale
      devices {
        deviceId
        user {
          id
          userName
          firstName
          lastName
          email
          locale
          devices {
            deviceId
            user {
              id
              userName
              firstName
              lastName
              email
              locale
              mealPlanDeliveryStatus
              fitnessPlanDeliveryStatus
            }
            operatingSystem
            brand
            model
            deviceToken
          }
          feed {
            id
            cardType
            name
            title
            description
            articleUrl
            videoUrl
            imageUrl
            callToActionCaption
            callToActionUrl
            callToActionDeepLinkUrl
          }
          mealPlanDeliveryStatus
          fitnessPlanDeliveryStatus
          planSelections {
            currentMealPlan {
              name
              title
              description
              dnaPoweredInsight
              isCurrentPlan
              isRecommended
              carbsPct
              carbsGm
              carbsKCal
              proteinPct
              proteinGm
              proteinKCal
              fatPct
              fatGm
              fatKCal
              omega3Pct
              mealPlanImage
            }
            currentFitnessPlan {
              name
              title
              description
              dnaPoweredInsight
              geneticProfile
              isCurrentPlan
              isRecommended
              duration
              thumbnailImageUrl
              powerEnduranceRatio
            }
            dietaryRequirement
          }
          availableFitnessPlans {
            name
            title
            description
            dnaPoweredInsight
            geneticProfile
            isCurrentPlan
            isRecommended
            duration
            thumbnailImageUrl
            powerEnduranceRatio
          }
          availableMealPlans {
            name
            title
            description
            dnaPoweredInsight
            isCurrentPlan
            isRecommended
            carbsPct
            carbsGm
            carbsKCal
            proteinPct
            proteinGm
            proteinKCal
            fatPct
            fatGm
            fatKCal
            omega3Pct
            mealPlanImage
          }
          otherPlans {
            name
            title
            description
            sections {
              name
              title
              description
            }
            options {
              backgroundColor
              highlightDarkestColor
              highlightDarkColor
              highlightColor
              highlightLightColor
              highlightLightestColor
              trackColor
            }
          }
          otherPlanByName {
            name
            title
            description
            sections {
              name
              title
              description
            }
            options {
              backgroundColor
              highlightDarkestColor
              highlightDarkColor
              highlightColor
              highlightLightColor
              highlightLightestColor
              trackColor
            }
          }
          planCategories {
            name
            title
          }
          insightCategory {
            title
            description
            recommendationsTitle
            header
            about {
              title
              content
            }
            how {
              title
              content
              reference
            }
            geneResults {
              title
              content
            }
            category
            insights {
              result
              priority
            }
          }
          insightCategories {
            title
            description
            recommendationsTitle
            header
            about {
              title
              content
            }
            how {
              title
              content
              reference
            }
            geneResults {
              title
              content
            }
            category
            insights {
              result
              priority
            }
          }
          progress {
            metricName
            date
            value
          }
          profileInfo {
            measurementSystem
            heightInCm
            weightInKg
            weightGoal
            activityAtWork
            trainingFrequency
            nutritionalGoal
            fitnessGoal
          }
        }
        operatingSystem
        brand
        model
        deviceToken
      }
      feed {
        id
        cardType
        name
        title
        description
        articleUrl
        videoUrl
        imageUrl
        callToActionCaption
        callToActionUrl
        callToActionDeepLinkUrl
      }
      mealPlanDeliveryStatus
      fitnessPlanDeliveryStatus
      planSelections {
        currentMealPlan {
          name
          title
          description
          dnaPoweredInsight
          isCurrentPlan
          isRecommended
          carbsPct
          carbsGm
          carbsKCal
          proteinPct
          proteinGm
          proteinKCal
          fatPct
          fatGm
          fatKCal
          omega3Pct
          mealPlanImage
        }
        currentFitnessPlan {
          name
          title
          description
          dnaPoweredInsight
          geneticProfile
          isCurrentPlan
          isRecommended
          duration
          thumbnailImageUrl
          powerEnduranceRatio
        }
        dietaryRequirement
      }
      availableFitnessPlans {
        name
        title
        description
        dnaPoweredInsight
        geneticProfile
        isCurrentPlan
        isRecommended
        duration
        thumbnailImageUrl
        powerEnduranceRatio
      }
      availableMealPlans {
        name
        title
        description
        dnaPoweredInsight
        isCurrentPlan
        isRecommended
        carbsPct
        carbsGm
        carbsKCal
        proteinPct
        proteinGm
        proteinKCal
        fatPct
        fatGm
        fatKCal
        omega3Pct
        mealPlanImage
      }
      otherPlans {
        name
        title
        description
        sections {
          name
          title
          description
          components {
            ... on UserPlanGauge {
              name
              title
              description
              guageType
              value
              options {
                minValue
                maxValue
              }
              componentSequenceId
            }
            ... on UserPlanList {
              name
              title
              description
              data {
                title
                description
                additionalContent
                highlighted
                ctaCaption
                ctaUrl
                backgroundImageUrl
              }
              componentSequenceId
            }
          }
        }
        options {
          backgroundColor
          highlightDarkestColor
          highlightDarkColor
          highlightColor
          highlightLightColor
          highlightLightestColor
          trackColor
        }
      }
      otherPlanByName {
        name
        title
        description
        sections {
          name
          title
          description
          components {
            ... on UserPlanGauge {
              name
              title
              description
              guageType
              value
              options {
                minValue
                maxValue
              }
              componentSequenceId
            }
            ... on UserPlanList {
              name
              title
              description
              data {
                title
                description
                additionalContent
                highlighted
                ctaCaption
                ctaUrl
                backgroundImageUrl
              }
              componentSequenceId
            }
          }
        }
        options {
          backgroundColor
          highlightDarkestColor
          highlightDarkColor
          highlightColor
          highlightLightColor
          highlightLightestColor
          trackColor
        }
      }
      planCategories {
        name
        title
      }
      insightCategory {
        title
        description
        recommendationsTitle
        header
        about {
          title
          content
        }
        how {
          title
          content
          reference
          starRatingTable {
            stars
            label
            description
          }
        }
        geneResults {
          title
          content
          results {
            gene
            snp
            result
          }
        }
        category
        insights {
          common {
            title
            imageUrl
            scientificRating {
              title
              content
              rating
            }
            references {
              title
            }
            geneBehind {
              title
              content
            }
          }
          result
          priority
          howToWorkWith {
            title
            nuggets {
              title
              content
            }
          }
          evidences {
            title
            nuggets {
              title
              content
            }
          }
        }
      }
      insightCategories {
        title
        description
        recommendationsTitle
        header
        about {
          title
          content
        }
        how {
          title
          content
          reference
          starRatingTable {
            stars
            label
            description
          }
        }
        geneResults {
          title
          content
          results {
            gene
            snp
            result
          }
        }
        category
        insights {
          common {
            title
            imageUrl
            scientificRating {
              title
              content
              rating
            }
            references {
              title
            }
            geneBehind {
              title
              content
            }
          }
          result
          priority
          howToWorkWith {
            title
            nuggets {
              title
              content
            }
          }
          evidences {
            title
            nuggets {
              title
              content
            }
          }
        }
      }
      progress {
        metricName
        date
        value
      }
      profileInfo {
        measurementSystem
        heightInCm
        weightInKg
        weightGoal
        activityAtWork
        trainingFrequency
        nutritionalGoal
        fitnessGoal
      }
    }
  }
`;
export const lookupWorkoutTypes = /* GraphQL */ `
  query LookupWorkoutTypes {
    lookupWorkoutTypes {
      id
      Name
    }
  }
`;
export const getRegisterProductSubscriptionFreightOptions = /* GraphQL */ `
query GetRegisterProductSubscriptionFreightOptions(
  $productCode: String
  $country: String!
  $partner: String
  $kitTypeId: String
  $timezone: String
) {
  getRegisterProductSubscriptionFreightOptions(
    productCode: $productCode
    country: $country
    partner: $partner
    kitTypeId: $kitTypeId
    timezone: $timezone
  ) {
    id
    name
    priceId
    collectionKitProductCode
    collectionKitPriceId
    isDefault
    initialChargeAmount
    dnaCollectionCharges
    subscriptionPeriodDays
    trialPeriodDays
    subscriptionPeriodDaysCalculated
    subscriptionInterval
    subscriptionIntervalUnit
    subscriptionAmountPerPeriod
    stripePublishableKey
    currency
    displayName
    subscriptionType
    insightsMultilineList
    deliveryOptions {
      id
      displayName
      initialChargeAmount
      productCode
      priceId
    }
  }
}
`;
export const getRegisterBarcodeDetails = /* GraphQL */ `
  query GetRegisterBarcodeDetails($barcode: String) {
    getRegisterBarcodeDetails(barcode: $barcode) {
      barcode
      partnerName
      barcodeStatus
      kitType
      kitTypeId
      isVipKitType
      isStaffKitType
      errorMessage
    }
  }
`;
export const getAllPlanWorkouts = /* GraphQL */ `
  query GetAllPlanWorkouts($lastName: String!) {
    getAllPlanWorkouts(lastName: $lastName) {
      emailAddress
      lastName
      workouts {
        workoutId
        workoutType
        workoutDate
        dateId
        isCompleted
        contentProviderKey
        title
        description
        imageUrl
        locationType
        programType
        bodyBeforeMarkup
        bodyAfterMarkup
        exercises
      }
    }
  }
`;
export const getWeekPlanWorkouts = /* GraphQL */ `
  query GetWeekPlanWorkouts($lastName: String!, $fromDate: AWSDate) {
    getWeekPlanWorkouts(lastName: $lastName, fromDate: $fromDate) {
      emailAddress
      lastName
      workouts {
        workoutId
        workoutType
        workoutDate
        dateId
        isCompleted
        contentProviderKey
        title
        description
        imageUrl
        locationType
        programType
        bodyBeforeMarkup
        bodyAfterMarkup
        exercises
      }
    }
  }
`;
export const getWorkoutExercises = /* GraphQL */ `
  query GetWorkoutExercises($workoutId: ID!, $workoutType: String!) {
    getWorkoutExercises(workoutId: $workoutId, workoutType: $workoutType) {
      workoutId
      workoutType
      exercises {
        exerciseId
        exerciseType
        contentProviderKey
        title
        description
        equipment
        sets
        repetitions
        rest
        superset
        notes
        videoLink
        thumbnailImageUrl
        instruction
        insightTitle
        insightText
        insightLink
      }
    }
  }
`;
export const searchWorkoutExercises = /* GraphQL */ `
  query SearchWorkoutExercises(
    $resultLimit: Int!
    $searchTerms: String
    $workoutType: String
  ) {
    searchWorkoutExercises(
      resultLimit: $resultLimit
      searchTerms: $searchTerms
      workoutType: $workoutType
    ) {
      exercises {
        exerciseId
        exerciseType
        contentProviderKey
        title
        description
        equipment
        sets
        repetitions
        rest
        superset
        notes
        videoLink
        thumbnailImageUrl
        instruction
        insightTitle
        insightText
        insightLink
      }
    }
  }
`;


export const GetBarcodeDetails = /* GraphQL */ `
query GetBarcodeDetails($barcode: String!) {
  getBarcodeDetails(barcode: $barcode) {
    barcode
    partnerName
    barcodeStatus
    kitType
    displayName
    kitTypeId
    isVipKitType
    isStaffKitType
    isLegacyCustomer
    errorMessage
    isOrganisationSelectionRequired
    showMedicationsSelection
  }
}
`;

export const getOrganisationWithPartner = /* GraphQL */ `
query GetOrganisationWithPartner($partner: String!) {
  getOrganisationWithPartner(partner: $partner) {
    organisations {
      active
      id
      isPartner
      isPathologyProvider
      isPharmacy
      name
      partner
      searchKey
      address {
        addressLine1
        addressLine2
        country
        postalCode
        stateProvince
        suburbCity
      }
    }
  }
}`;

export const VerifyPromoCode = /* GraphQL */ `
query VerifyPromoCode(
  $partnerName: String!
  $promoCode: String!
  $subscriptionCode: String!
) {
  verifyPromoCode(
    partnerName: $partnerName
    promoCode: $promoCode
    subscriptionCode: $subscriptionCode
  ) {
    isValid
    promoCodeId
    products {
      productCode
      productName
      originalAmount
      amount
      nextPaymentDate
      numberOfFreeDays
      percentOff
      amountOff
      couponDurationInMonths
    }
  }
}`;