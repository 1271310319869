// todo: convert to styled-components for consistency

export const useStyles = theme => ({
    root: {
        flexWrap: 'wrap',
        margin: 'auto',
        fontFamily: '"AvenirRoman", sans-serif !important',
        '& .RadioButtonGroup':{ paddingLeft:'60px' },
        '& .MuiFormControl-root': {
            width: '21rem',
        },
        '& .MuiFormLabel-root': {
            textAlign: 'left',
            marginBottom: '1rem'
        },
        '& .MuiToggleButton-root.Mui-selected':{
          backgroundColor:'#FFF',
          padding:5
        },
        '& .MuiFormControlLabel-root':{
            fontFamily: '"AvenirRoman", sans-serif',
            color: '#717073',
            border: '1px solid #E0E0E0',
            borderRadius: '5px',
            height: '3.5rem',
            textAlign:'left',
            width:'18rem',
        },
        '& .shipping-method':{
            width:'18rem'
        },
        '& .value-indicator':{
          height: 23,
          borderRadius: '0px 15px 15px 0px',
          backgroundColor: "rgba(231,32,118,1)",
          margin: "auto",
          opacity: 1,
          marginTop: +5,
          color: "#FFF",
          textAlign:"center",
          marginLeft: -18,
        },
        '& .toggle-button':{
          textAlign: "left",
          marginBottom: 40,
          border:'none',
          height:'100%',
          textTransform: 'none'
        },
        '& .totalValue':{
            width:'17rem',
            margin: 'auto', 
            height:'1rem',
            paddingRight:10
        },
        '& .totalValue-left':{
            float:'left'
        },
        '& .totalValue-right':{
            float:'right', 
            fontSize:17, 
            fontWeight:'bolder'
        },
        '& .subscription-plan': {
            fontFamily: '"AvenirRoman", sans-serif',
            color: '#717073',
            '& .MuiPaper-root': {
                padding: '0.5rem'
            },
            '& .MuiPaper-root:hover': {
                boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)'
            },
            '& .MuiPaper-root:selected': {
                border: '1px Solid blue',
            },
            '& .MuiFormControlLabel-root': {
                minHeight: '3.5rem',
                padding: '0 0 0 1rem',
                textAlign: 'left;',
                width: '20rem',
                fontFamily: '"AvenirRoman", sans-serif',
            }
        }
    }
  });