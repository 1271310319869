 // To get the monthly subscription details from the subscriptions list
  export const getLeastIntervalProgram = (subscriptionFreightOptions) =>{
    return subscriptionFreightOptions?.reduce(function(prev, curr) {
      return prev.subscriptionInterval < curr.subscriptionInterval ? prev : curr;
    })
  }

  //sorting the array for discount value
  export const sortByHighestDiscount = (data) => {
    return data.sort((a,b) => (b.discount > a.discount) ? 1 : ((a.discount > b.discount) ? -1 : 0));
  }

  // Calculating the discount based monthly subscription option
  export const getDiscount = (eachMonthCost, monthlyProgramCost) => {
    return Number((((monthlyProgramCost - eachMonthCost) / monthlyProgramCost) * 100).toFixed());
  }

  // to calculate discount and sort by highest discount
  export const sortSubscriptionOptions = (options) => {
    let sortedOptions = options;
    if(options.length > 1){
      const leastIntervalPlan = getLeastIntervalProgram(options);
      let data = [];
      options.map((each) => {
        each.discount = getDiscount(each.subscriptionAmountPerPeriod/each.subscriptionInterval, leastIntervalPlan.subscriptionAmountPerPeriod);
        data.push(each);
      });
      sortedOptions = sortByHighestDiscount(data);
    }
    return sortedOptions;
  }