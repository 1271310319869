import styled from 'styled-components';
import { MyDnaTheme } from '@themes/Styles/Default';

export const StyledHeading = styled.div`
  font-size: 16px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: left;
  margin: auto;
  width: 100%;
  min-width: 25rem;
  @media (max-width: ${MyDnaTheme.MediaSizes.Small}) {
    min-width: auto;
  }
`;

export const GoalGroupComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
  margin: auto;
  align-items: center;
`;