import styled from 'styled-components';
import { materialTheme } from '@themes/Styles/materialTheme'
import { MyDnaTheme } from '@themes/Styles/Default';

export const FlexBox = styled.div`
    display: flex;
    width: 100%;
`;

export const FlexColumn = styled(FlexBox)`
    flex: 1;
    flex-direction: column;
`;

export const FlexRow = styled(FlexBox)`
    flex: 1;
    flex-direction: row;

    max-width: 23rem;
`;

export const ComponentWrapper = styled(FlexColumn)`
    margin: auto;
    justify-content: center;
    align-items: center;
`;

export const PromoCodeEntryWrapper = styled(FlexBox)`
    width: 66%;
`;

export const PromoCodeInputWrapper = styled(FlexBox)`
    width: 100%;
    max-height: 4.5rem;
`;

export const SubText = styled.span`
    text-align: left;
    font-family: 'AvenirLight', sans-serif;
    font-size: 0.75rem;
    letter-spacing: 0px;
    opacity: 1;
    color: ${materialTheme.palette.primary.light};
`;

export const ApplyButtonWrapper = styled(FlexBox)`
    width: 33%;

    height: 3.5rem;
    margin: 1rem;
    margin-left: 0;
    justify-content: flex-end;
`;

export const ApplyButtonTextWrapper = styled.span`
    font-family: 'NowRegular', sans-serif;
    font-size: 1rem;
    letter-spacing: 0px;
    text-transform: uppercase;
    font-weight: bold;
`;

export const TextWrapper = styled(FlexBox)`
    padding-left: 1rem;
`;

export const ConfirmationWrapper = styled(FlexBox)`
    flex-direction: row;
    max-width: 21rem;

    padding: 1rem;
    height: 2.5rem;
`;

export const ConfirmationContentsWrapper = styled(FlexBox)<{isVisible: boolean}>`
    opacity : ${props => props.isVisible ? 1 : 0};
    transition: opacity 0.5s ease;
`;

export const ConfirmationIconWrapper = styled(FlexBox)`
    width: 10%;
    align-items: center;
    color: ${ MyDnaTheme.Colours.Primary };
`;

export const ConfirmationTextWrapper = styled(FlexBox)`
    width: 90%;

    align-items: center;

    text-align: left;
    font-family: AvenirHeavy;
    letter-spacing: 0px;
    color: ${ MyDnaTheme.Colours.Input.Heavy };
    opacity: 1;
`;