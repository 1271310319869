import React from "react";
import { default as ReactMarkdown, MarkdownToJSX } from "markdown-to-jsx";
import { ListItem, InsightsList } from "./styles";

type MarkdownProps = {
  content: string;
  options?: MarkdownToJSX.Options;
};

const Markdown = (props: MarkdownProps) => {
  return (
    <ReactMarkdown
      options={
        !props.options
          ? {
              overrides: {
                ul: { component: InsightsList },
                li: { component: ListItem },
              },
            }
          : props.options
      }
    >
      {props.content}
    </ReactMarkdown>
  );
};

export default Markdown;
