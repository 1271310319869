import React from "react";
import { ErrorMessage } from "formik";

type ErrorMessaageBlockProps = {
  name: string
}

const ErrorMessageBlock = (props: ErrorMessaageBlockProps) => {
  return (
    <div className={'error'}>
      <ErrorMessage name={props.name} />
    </div>
  );
};

export default ErrorMessageBlock;
