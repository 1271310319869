import { CustomerDetailsActions } from "@base/Actions/CustomerDetailsActions";
import { TermsAndConditionsURLs } from "@modules/Shared/TermsAndConditionsURLS";
import { AppLinkURLS } from "@modules/Shared/AppLinkURLS";
import { FetchSubscriptionFreightOptions } from '@utils/FetchSubscriptionFreightOptions';
import { FetchOrganizationWithPartnerOptions } from '@utils/FetchOrganizationWithPartnerOptions';
import {systemUI} from "@modules/SystemUI/SystemUI";
import systemDefaults from "@modules/SystemUI/systemDefaults"

const initialValue = {
    state: CustomerDetailsActions.PERSONAL_DETAILS,
    error: {},
    barcode:{},
    partner: null,
    userDetails: {},
    place: {},
    goals: {},
    orderDetails: {},
    stepNum : 0,
    subscriptionFreightOptions: {},
    organisationOptions: [],
    route:[],
    promoCodeDetails: {},
    systemUI: systemUI.find((each) => each.kitTypeIds.includes(systemDefaults.kitTypeId)),
};

export const setSubscriptionFreightOptions = async (country, partner, kitTypeId, timeZone)=>{
    // TODO: Replace null productCode when product selection page is available
    FetchSubscriptionFreightOptions(null, country, partner, kitTypeId, timeZone)
        .then((response)=>{
            initialValue.subscriptionFreightOptions = response.data.getRegisterProductSubscriptionFreightOptions;
        })
}

export const setOrganisationsWithPartnerOptions = async (partner)=>{
    if (partner) {
        FetchOrganizationWithPartnerOptions(partner)
            .then((response)=>{
                initialValue.organisationOptions = response.data.getOrganisationWithPartner.organisations;
            })
    }
}

export const setPromoCodeDetails = (promoCodeDetails) => {
    if (promoCodeDetails) {
        initialValue.promoCodeDetails = promoCodeDetails;
    }
}

export const setPartnerDetails = (partner) => {
    if (partner) {
        initialValue.partner = partner;
    }
}

export const selectTermsAndConditions=(partner)=>{
    partner=partner ?? "DEFAULT";
    //We will make use of dropdown value for country selection hence no scope for spelling mistakes and dropdown must have values existing in TermsAndConditionsURLs in constant.js with same spelling.
    var country=initialValue.place?.country?.toUpperCase()??"DEFAULT";
    var url= TermsAndConditionsURLs.find(element=> ((element.Country.toUpperCase()===country.toUpperCase()) && (element.Partner.toUpperCase() === partner.trim().toUpperCase())))?.URL;
    url= url??TermsAndConditionsURLs.find(element=> (element.Country==="DEFAULT" && element.Partner==="DEFAULT")).URL;
    return url;
}

export const selectApplinkURL=(partner="DEFAULT")=>{
    partner=partner ?? "DEFAULT";
    var country=initialValue.place?.country?.toUpperCase()??"DEFAULT";
    var url= AppLinkURLS.find(element=> ((element.Country.toUpperCase()===country.toUpperCase()) && (element.Partner.toUpperCase() === partner.trim().toUpperCase())));
    url= url??AppLinkURLS.find(element=> (element.Country==="DEFAULT" && element.Partner==="DEFAULT"));
    return url;
}

export const getNextPage=(currentPage)=>{
    let currentPageIndex = initialValue.route.findIndex(currentPage)
    return initialValue.route[currentPageIndex+1];
}

export const getPreviousPage=(currentPage)=>{
    let currentPageIndex = initialValue.route.findIndex(currentPage)
    return initialValue.route[currentPageIndex-1];
}

// TODO change to switch case
export function CustomerDetailsReducer(state = {
    initialValue
}, action) {

    if (!Object.values(CustomerDetailsActions).includes(action.type)) return state;
    
    if (action.type == CustomerDetailsActions.CLEAR_CUSTOMER_DETAILS) {
        initialValue.barcode = {};
        initialValue.userDetails = {};
        initialValue.place = {};
        initialValue.goals = {};
        initialValue.orderDetails = {};
        initialValue.subscriptionFreightOptions = {};
        initialValue.organisationOptions = {};
        initialValue.promoCodeDetails = {};
    }

    if(action.payload.userDetails){
        initialValue.userDetails=action.payload.userDetails;
    }
    if(action.payload.place && action.payload.place.streetAddress){
        initialValue.place=action.payload.place;
    }
    if(action.payload.barcode){
        initialValue.barcode=action.payload.barcode;
    }
    if(action.payload.goals &&action.payload.goals.fitnessGoal){
        initialValue.goals=action.payload.goals;
    }
    if(action.payload.orderDetails &&action.payload.orderDetails.subscriptionOptionId){
        initialValue.orderDetails=action.payload.orderDetails;
    }
    if(action.payload?.medicationDetails && action.payload?.medicationDetails?.medications){
        initialValue.medicationDetails=action.payload.medicationDetails;
    }
    if (action.payload.stepNum ) {
        initialValue.stepNum = action.payload.stepNum ;
    }
    if(action.payload.route){
        initialValue.route=action.payload.route;
    }
    
    if(action.payload.promoCodeDetails) {
        initialValue.promoCodeDetails = action.payload.promoCodeDetails;
    }

    if (action.type == CustomerDetailsActions.VALIDATE_JWT_SET_BARCODE) {
        initialValue.barcode.barcode = action.payload.jwtPayload?.sub
        initialValue.barcode.kitTypeId = action.payload.jwtPayload?.aud
    }

    if (action.type == CustomerDetailsActions.SET_SYSTEM_UI) {
        initialValue.systemUI = action.payload.data;
    }
    
    if (action.type == CustomerDetailsActions.SET_SUBSCRIPTION_FREIGHT_OPTIONS) {
        initialValue.subscriptionFreightOptions = action.payload;
    }
    
    return Object.assign({}, state, initialValue);
};
