import React from "react";
import { Paper, Chip } from "@material-ui/core";
import {
  InsightsWpr,
  Title,
  Heading,
  InsightsList,
  ListItem,
  Price,
  Currency,
  Amount,
} from "./styles";
import { messages } from '@modules/Shared/Messages';
import Markdown from "@modules/Markdown";

export type insightsDataRef = { 
  displayName: string;
  dnaCollectionCharges: number;
  currency: string;
  trialPeriodDays: number;
  insightsMultilineList: string;
}

const Insights = (props: any) => {
  const insightsData: insightsDataRef = props.subscriptionFreightOptions;
  const displayName = props.displayName;
  return (
    <InsightsWpr>
      {insightsData && 
        <Paper elevation={0} className="insights">
          <Heading>{messages.insights.title}</Heading>
          <Title>{displayName}</Title>
          {
            insightsData.dnaCollectionCharges > 0 && 
              <Price>
                <Currency>{insightsData.currency}</Currency>
                <Amount>{insightsData.dnaCollectionCharges}</Amount>
              </Price>
          }
          {insightsData.insightsMultilineList && insightsData.insightsMultilineList != "" && <Markdown content={insightsData.insightsMultilineList}></Markdown>}
        </Paper>
      }
    </InsightsWpr>
  );
};

export default Insights;
