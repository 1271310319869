
import { StatusCode } from "@appSyncGateway/HttpConstants";
import { CUSTOMER_MICROSERVICE_UNAUTH_BASE_URL } from "@modules/Shared/Configs";

export const validateDataToken = async (token, barcode, kitTypeId) => {
        let data = {
            barCode: barcode,
            jwtToken: token,
            kitTypeId: kitTypeId
        }
    const result = await fetch(`${CUSTOMER_MICROSERVICE_UNAUTH_BASE_URL}/KitBarcode/ValidateTokenAndBarcode`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      return result.status === StatusCode.STATUS_200 ? true : false
};
