import ReactGA from 'react-ga';

export const SendPageView = (pathName: string) => {

    if (pathName == "/") {
        pathName = "create-account";
    }

    ReactGA.set({ page: pathName });
    ReactGA.pageview(pathName);
    
}

declare global {
    interface Window { gtag: any; ga:any; dataLayer: any;}
}

export const gTagDimensionUpdater = (id:string, value:string)   => {
    let dimension:any = {};
    dimension[id] = value;

    // Partner dimension in Custom Dimensions is configured as index 1 in the analytics portal. Google Analytics can understand partner dimension with the name of dimension1

    // Partner view in Custom Metrics is configured as index 1 in analytics portal. Google Analytics can understand partner metric view with the name of metric1

    window.gtag('event', 'dimension1', dimension);
    window.ga('set', 'dimension1', value);
    window.ga('set', 'metric1', 1);
}

export const gtagDimensions = () => {
    window.gtag('config', 'GTM-T296Q5S', {
        'custom_map': {'dimension1': 'partner'}
    });
}