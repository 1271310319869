import {responsiveFontSizes} from "@material-ui/core";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

const colours = {
    primaryMain: '#30B5E8',
    primaryLight: '#707070',
    secondaryMain: '#00B8FF',
    secondaryLight: '#20e9ff',
    titleDark: '#000',
    border: '#C4C4C4'
};

export const materialTheme = responsiveFontSizes(createMuiTheme({
    palette: {
        primary: {
            main: colours.primaryMain,
            fontFamily: "'AvenirRoman', sans-serif",
            light: colours.primaryLight
        },
        secondary: {
            main: colours.secondaryMain,
            light: colours.secondaryLight
        }
    }
}));


export const addressStyles = theme => ({
    root: {
        "& label": {
            "&.address-search-label": {
                color: colours.titleDark,
                textAlign: 'left',
                marginBottom: '1rem',
                fontFamily: '"AvenirRoman", sans-serif'
            }
        },
        "& input": {
            width: "100%",
            fontFamily: '"AvenirRoman", sans-serif'
        },
        "& .search-address": {
            border: '1px solid',
            borderColor: colours.border,
            borderRadius: '4px',
        },
        "& .manual-address .MuiFormControl-root.MuiTextField-root": {
            margin: 0
        },
        fontFamily: '"AvenirRoman", sans-serif'
    }
});
