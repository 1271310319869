import styled from 'styled-components';
import { MyDnaTheme } from '@themes/Styles/Default';
import {InputLabel, Select, FormHelperText } from "@material-ui/core";

export const SelectWrapper = styled.div`
    padding: 0.5rem;
`;

export const MyDnaDatePickerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: auto;
    width: ${MyDnaTheme.ContentWidth};
`;

export const LabelWrapper = styled.span`
    text-align: left;
`;

export const SelectComponentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
`;

export const StyledSelectComponent = styled(Select)`
height: 3.5rem;
text-align: left;
`;

export const MonthSelectComponent = styled(StyledSelectComponent)`
width: 8rem;
`;

export const DaySelectComponent = styled(StyledSelectComponent)`
width: 5rem;
`;

export const YearSelectComponent = styled(StyledSelectComponent)`
width: 6rem;
`;

export const MonthInputLabelComponent = styled(InputLabel)`
font-size: 8px;
`;

export const InputLabelText = styled.span`
`;

export const ErrorHelperText = styled(FormHelperText)`
color: red;
`;