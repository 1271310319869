import React, { useEffect } from "react";
import ComponentButtons from "@modules/ComponentButtons";
import TextInput from "@modules/TextInput";
import * as yup from "yup";
import {
  AllowedPatterns,
  ErrorMessages,
  FAQs,
  APPCONSTANTS,
} from "@modules/Shared/Constants";
import { messages } from "@modules/Shared/Messages";
import { setPartnerDetails } from "@base/Reducers/CustomerDetailsReducer";
import ComponentContainerHeader from "@modules/Header";
import { Formik, Form } from "formik";
import { useSelector } from "react-redux";
import {
  SubmitHandler,
  submitBarcodeDetails,
  setDefaultBarcodeDetails,
  decideRoute,
} from "./services";
import { push } from "connected-react-router";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MyDnaSupportLinks, config } from "@modules/Shared/Constants";
import MyDnaButton from "@modules/MyDnaButton";
import BarcodeModal from "@modules/Shared/BarcodeModal";
import { ErrorLabel } from "@modules/ErrorLabel";
import { gTagDimensionUpdater } from "@base/Analytics/SendPageView";
import FAQComponent from "@modules/FAQ/FAQComponent";
import {
  BarcodeContentWrapper,
  FormContentsWrapper,
  ExpandedFormContentsWrapper,
  StyledBarcodeText,
  SideBySideWrapper,
  SideBySideContentWrapper,
  VerifyButtonWrapper,
  VerifyButtonSizeRestrictor,
  AnimationImage,
  TitleWpr,
  ErrorMessageWpr,
} from "./styles";
import { myDNARouter } from "@modules/utils/myDNARouter";

const Barcode = (props) => {
  const store = useSelector((state) => state);
  const barCodeUI = props.initialValue.systemUI?.barCode;
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [hasLoaded, setHasLoaded] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState(false);
  const [barcodeValidation, setBarcodeValidation] = React.useState(null);
  const [formData, setFormData] = React.useState({
    barcode: "",
    kitTypeId: "",
  });
  const [barcodeFromUrl, setBarcodeFromUrl] = React.useState("");
  const [kitTypeIdFromUrl, setKitTypeIdFromUrl] = React.useState(null);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const selectableButton1 = "i-have-a-kit";
  const selectableButton2 = "i-need-a-kit";
  const [kitButtonSelected, setKitButtonSelection] = React.useState(
    selectableButton2
  );

  const validationSchema = yup.object({
    barcode: yup
      .string()
      .matches(
        AllowedPatterns.AlphaNumericCheck,
        ErrorMessages.AlphaNumericCheck
      )
      .max(10, "Barcode must be up to 10 digits long"),
  });

  var primaryTitle = barCodeUI.title;

  primaryTitle = primaryTitle.replace(
    "<fn>",
    props.userDetails?.firstName ?? ""
  );

  React.useEffect(() => {
    if (props?.barcode?.partner) {
      setHasLoaded(true);
      gTagDimensionUpdater("partner", props?.barcode?.partner);
    } else {
      setHasLoaded(false);
      gTagDimensionUpdater("partner", "myDNA");
    }

    setDefaultBarcodeDetails(props);
  }, []);

  useEffect(() => {
    if (!barCodeUI.iHaveAKitButton.visible) {
      setHasLoaded(true);
    }
  }, [barCodeUI]);

  useEffect(() => {
    if (store.customerDetails?.barcode?.barcode) {
      let data = store.customerDetails?.barcode
        ? {
            barcode: store.customerDetails?.barcode?.barcode,
            kitTypeId: store.customerDetails?.barcode?.kitTypeId,
          }
        : { barcode: "", kitTypeId: "" };
      setBarcodeFromUrl(data.barcode);
      setKitTypeIdFromUrl(data.kitTypeId);

      // To retain the data on back button click in the next page
      let formDefaultData = formData;
      formDefaultData.barcode = store.customerDetails?.barcode?.barcode;
      setFormData(formDefaultData);
      setKitButtonSelection(selectableButton1);
    }
  }, [store.customerDetails]);

  const redirectBasedOnAppName = () => {
    if (props.appName === config.viaCord.appName) {
      window.location.href = config.viaCord.redirectUrl;
    }
  };

  return (
    <React.Fragment>
      {barCodeUI.displayAnimation && (
        <AnimationImage
          src={require("@assets/ftdna_mydna_transfer_ani.gif")}
          alt="FTDNA MyDNA animation image"
          aria-hidden="true"
        />
      )}

      <TitleWpr>
        <ComponentContainerHeader
          primaryTitle={primaryTitle}
          secondaryTitle={barCodeUI.description}
          activateMessage={barCodeUI.activateMessage}
          purchaseMessage={barCodeUI.purchaseMessage}
        />
      </TitleWpr>
      <Formik
        validateOnChange={true}
        initialValues={formData}
        validationSchema={validationSchema}
        onSubmit={(formData, { setSubmitting, setFieldError }) => {
          setSubmitting(true);
          if (hasLoaded && store.customerDetails?.barcode?.partner) {
            setErrorMessage(null);
            setPartnerDetails(store.customerDetails?.barcode?.partner);
            gTagDimensionUpdater(
              "partner",
              store.customerDetails?.barcode?.partner
            );
            submitBarcodeDetails(
              props,
              store.customerDetails?.barcode,
              setErrorMessage
            );
            decideRoute(props, store.customerDetails?.barcode?.kitTypeId);
          } else if (hasLoaded && !store.customerDetails?.barcode?.partner) {
            setErrorMessage(barCodeUI.verificationMessage);
            setSubmitting(false);
          } else if (hasLoaded === false) {
            setErrorMessage(null);
            setPartnerDetails("myDNA");
            myDNARouter(props, APPCONSTANTS.NEXT_PAGE, "dna-collection");
          } else if (hasLoaded === null) {
            setErrorMessage("Please select I HAVE A KIT or I NEED A KIT.");
            setSubmitting(false);
          } else {
            if (!formData.barcode) {
              setSuccessMessage(null);
              setErrorMessage(barCodeUI.requiredMessage);
              setSubmitting(false);
            } else {
              setErrorMessage(null);
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          values,
          errors,
          isSubmitting,
          setFieldValue,
          setFieldError,
          setSubmitting,
          validateField,
          isValid,
          setValues,
          setFieldTouched,
        }) => {
          const OnHaveAKitButtonClick = () => {
            setKitButtonSelection(selectableButton1);
            setErrorMessage(null);
            setHasLoaded(true);
            store.customerDetails?.barcode?.barcode &&
              !kitTypeIdFromUrl &&
              setFieldValue("barcode", store.customerDetails?.barcode?.barcode);
          };

          const OnNeedAKitButtonClick = () => {
            redirectBasedOnAppName();
            setKitButtonSelection(selectableButton2);
            setErrorMessage(null);
            setHasLoaded(false);
            setSubmitting(false);
            setSuccessMessage(null);
            setDefaultBarcodeDetails(props);
            setFieldValue("barcode", "");
          };

          return (
            <Form id="barcode_details">
              <BarcodeContentWrapper>
                <FormContentsWrapper>
                  {barCodeUI.iHaveAKitButton.visible && (
                    <SideBySideWrapper>
                      <SideBySideContentWrapper>
                        <MyDnaButton
                          id={selectableButton1}
                          onClick={() => OnHaveAKitButtonClick()}
                          buttonType={
                            kitButtonSelected === selectableButton1
                              ? "ghost"
                              : "secondary"
                          }
                        >
                          I have a kit
                        </MyDnaButton>
                      </SideBySideContentWrapper>
                      <SideBySideContentWrapper>
                        <MyDnaButton
                          id={selectableButton2}
                          onClick={() => OnNeedAKitButtonClick()}
                          buttonType={
                            kitButtonSelected === selectableButton2
                              ? "ghost"
                              : "secondary"
                          }
                        >
                          I need a kit
                        </MyDnaButton>
                      </SideBySideContentWrapper>
                    </SideBySideWrapper>
                  )}
                  {hasLoaded === true && <p>{barCodeUI.info}</p>}
                  {hasLoaded === false && (
                    <p className="body-content">
                      {messages.barcodeCollectionKit}
                    </p>
                  )}
                </FormContentsWrapper>
                {hasLoaded && (
                  <React.Fragment>
                    <ExpandedFormContentsWrapper>
                      <SideBySideWrapper
                        className={
                          barCodeUI.disabledBarCodeTexField
                            ? "barcode-disabled"
                            : null
                        }
                      >
                        <TextInput
                          name="barcode"
                          label="Activation Code"
                          maxLength="10"
                          className={"input-control-short"}
                          onChange={(e) =>
                            setFieldValue(
                              "barcode",
                              e.target.value.toUpperCase()
                            )
                          }
                          capitalize
                          disabled={barCodeUI.disabledBarCodeTexField}
                        />
                        <VerifyButtonWrapper>
                          <VerifyButtonSizeRestrictor>
                            <MyDnaButton
                              buttonType={"ghost"}
                              onClick={() => {
                                if (values.barcode?.length > 0) {
                                  validateField("barcode");
                                  if (
                                    isValid ||
                                    barCodeUI.disabledBarCodeTexField
                                  ) {
                                    setSuccessMessage(null);
                                    setErrorMessage(null);
                                    setBarcodeValidation(true);
                                    SubmitHandler(
                                      props,
                                      setFieldError,
                                      values,
                                      setSubmitting,
                                      setErrorMessage,
                                      setSuccessMessage,
                                      setBarcodeValidation,
                                      barcodeFromUrl
                                    );
                                  } else {
                                    setSuccessMessage(null);
                                    if (!barCodeUI.disabledBarCodeTexField) {
                                      setFieldValue("barcode", "");
                                    }
                                  }
                                } else {
                                  setSuccessMessage(null);
                                  setErrorMessage(barCodeUI.requiredMessage);
                                }
                              }}
                            >
                              {barcodeValidation === true ? (
                                <CircularProgress size={25} color="secondary" />
                              ) : !barCodeUI.disabledBarCodeTexField &&
                                !isValid &&
                                values.barcode?.length > 0 ? (
                                "CLEAR"
                              ) : (
                                "VERIFY"
                              )}
                            </MyDnaButton>
                          </VerifyButtonSizeRestrictor>
                        </VerifyButtonWrapper>
                      </SideBySideWrapper>
                    </ExpandedFormContentsWrapper>
                    <BarcodeModal
                      modalIsOpen={modalIsOpen}
                      setIsOpen={setIsOpen}
                    />
                    {barCodeUI.displayWhereIsMyKitCTA && (
                      <StyledBarcodeText
                        onClick={() => {
                          setIsOpen(true);
                        }}
                      >
                        <span>{"Where's my activation code?"}</span>
                      </StyledBarcodeText>
                    )}
                  </React.Fragment>
                )}
              </BarcodeContentWrapper>
              {successMessage && (
                <div>
                  <p>
                    {successMessage} If this doesn't sound right, please{" "}
                    <a
                      href={MyDnaSupportLinks.Barcode}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      contact us
                    </a>
                    .
                  </p>
                </div>
              )}
              {errorMessage && (
                <ErrorMessageWpr>
                  <ErrorLabel>{errorMessage}</ErrorLabel>
                </ErrorMessageWpr>
              )}
              <ComponentButtons
                dispatch={props.dispatch}
                barcode = {props.barcode}
                previousPage={props.previousPage}
                singleButton={true}
                isSubmitting={isSubmitting}
                submitText={"Next"}
                currentPage="dna-collection"
              />
            </Form>
          );
        }}
      </Formik>

      {barCodeUI.showFAQ && (
        <FAQComponent data={FAQs} defaultExpandedItem={0} />
      )}
    </React.Fragment>
  );
};

export default React.memo(Barcode);
